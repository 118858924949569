import axios from 'axios';

export default {
  DownloadBlob({commit}, {response, filename}) {
    return new Promise(resolve => {
      const a = document.createElement('a');
      document.body.appendChild(a);
      a.style = 'display: none';
      const blob = new Blob([response.data], {type: response.headers['content-type']});
      let url = window.URL.createObjectURL(blob);
      a.href = url;
      let name = filename || '_' + Math.random().toString(36).substr(2, 9);
      name = name + '.' + response.config.params.type;
      a.download = name;
      a.click();
      window.URL.revokeObjectURL(url);
      resolve();
    });
  },
  toggleSideBar({commit}) {
    commit('TOGGLE_SIDEBAR');
  },
  closeSideBar({commit}, {withoutAnimation}) {
    commit('CLOSE_SIDEBAR', withoutAnimation);
  },
  toggleDevice({commit}, device) {
    commit('TOGGLE_DEVICE', device);
  },
  toggleColor({commit}, {color}) {
    commit('TOGGLE_COLOR', color);
  },
  getVersion({dispatch}) {
    return new Promise((resolve, reject) => {
      axios.get(`getversion`)
        .then(function (response) {
          if (typeof response.data[0] !== 'undefined') {
            dispatch('addVersionBack', response.data[0]).then(() => {
            });
            dispatch('addVersionFront', process.env.VERSION).then(() => {
            });
          }
          resolve();
        }).catch(function (error) {
        reject(error);
      });
    });
  },
  getSystemSetting({commit}) {
    return new Promise((resolve, reject) => {
      axios.get(`system_settings`)
        .then(function (response) {
          commit('SET_SYSTEM_SETTING', response.data);
          if (response.data.reportTimeEntriesThead) {
            const modules = ['unsettled_reports', 'settled_reports', 'unsettled_reports_stash'];
            modules.forEach($item => {
              commit($item + '/SET_TIME_ENTRIES_THEAD', response.data.reportTimeEntriesThead.all);
              // commit($item + '/SET_SELECTED_TIME_ENTRIES_THEAD', response.data.reportTimeEntriesThead.selected);
            });
          }
          resolve(response.data);
        }).catch(function (error) {
        reject(error);
      });
    });
  },
  getMyConfig({commit}) {
    return new Promise((resolve, reject) => {
      axios.get(`my_config`)
        .then(function (response) {
          commit('SET_uCONFIG', response.data);
          resolve(response.data);
        }).catch(function (error) {
        reject(error);
      });
    });
  },
  getMyWatchers({commit}) {
    return new Promise((resolve, reject) => {
      axios.get(`my_watchers`)
        .then(function (response) {
          commit('SET_WATCHERS', response.data);
          resolve();
        }).catch(function (error) {
        reject(error);
      });
    });
  },
  issuesSetQuickFilter: (context, payload) => {
    return new Promise(resolve => {
      context.commit('ISSUES_SET_QUICK_FILTER_DATA', payload);
      resolve();
    });
  },

  fetchLogo({commit}) {
    return axios.get('logo', {
      responseType: 'blob',
    }).then(response => {
      let reader = new FileReader();
      reader.readAsDataURL(response.data);
      reader.onload = () => {
        commit('SET_LOGO', reader.result);
      };
    });
  },
};
