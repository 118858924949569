<template>
  <el-dialog
      :title="$t('system.vacation_request')"
      :visible="isVisible"
      @close="close"
  >
    <div v-loading="loading">
      <div class="d-flex justify-content-between">
        <div>
          <p>{{ $t('system.username') }}: <b>{{ request.username }}</b></p>
          <p>{{ $t('system.reason') }}: <b>{{ request.reason }}</b></p>
          <p>{{ $t('system.date_range') }}:
            <b>{{ request.from }} - {{ request.to }}
               ({{ request.days }}
               {{ request.days > 1 ? $t('system.days') : $t('system.day') }})
            </b>
          </p>
        </div>
        <div
            style="min-width: 150px; text-align: end"
            v-if="ifHasPermission(['wo-leave-update'])"
        >
          <el-dropdown @command="changeStatus">
            <el-button
                :loading="changeStatusLoading"
                :type="getStatusType(request.status)"
                effect="dark"
                size="mini"
            >
              {{ $t(`system.${request.status}`) }}
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                  :command="status.id"
                  :key="status.id"
                  v-for="status in statuses.filter(item => item.id !== request.status)"
              >
                {{ $t(`system.${status.name}`) }}
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
      <v-sheet
          class="d-flex"
          color="grey lighten-3"
          height="54"
          tile
      >
        <v-btn
            @click="$refs.calendar.prev()"
            class="ma-2"
            icon
        >
          <v-icon>fas fa-chevron-left</v-icon>
        </v-btn>
        <v-spacer />
        <div class="d-flex justify-content-center align-items-center">
          <h5>
            {{ currentMonthAndYear }}
          </h5>
        </div>
        <v-spacer />
        <v-btn
            @click="$refs.calendar.next()"
            class="ma-2"
            icon
        >
          <v-icon>fas fa-chevron-right</v-icon>
        </v-btn>
      </v-sheet>
      <v-calendar
          :events="events"
          :now="now"
          ref="calendar"
          type="month"
          v-model="value"
      />
    </div>
  </el-dialog>
</template>

<script>
import moment from 'moment';
import axios from 'axios';
import notification from '../../notification/notify';

export default {
  name: 'VacationRequest',
  props: {
    id: {
      type: [String, Number, null],
      default: null,
    },
    isVisible: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      loading: true,
      changeStatusLoading: false,
      value: '',
      now: moment().format('YYYY-MM-DD'),
      dialogVisible: true,
      request: {
        status: '',
        username: '',
        start_at: '',
        end_at: '',
        reason: '',
      },
      statuses: [
        {
          id: 'pending',
          name: 'pending',
        },
        {
          id: 'accepted',
          name: 'accepted',
        },
        {
          id: 'rejected',
          name: 'rejected',
        },
      ],
      events: [],
    };
  },
  beforeMount() {
    this.fetchData();
    // this.fetchStatuses()
  },
  watch: {
    id(val) {
      if (val) {
        this.fetchData();
      }
    },
    isVisible(val) {
      if (!val) {
        this.request = {
          status: '',
          username: '',
          from: '',
          to: '',
          reason: '',
        };
        this.events = [];
      }
    },
  },
  computed: {
    currentMonthAndYear() {
      const monthNames = ['january', 'february', 'march', 'april', 'may', 'june',
        'july', 'august', 'september', 'october', 'november', 'december',
      ];
      if (this.value) {
        const date = new Date(this.value);
        const month = monthNames[date.getMonth()];
        return `${this.$t(`system.${month}`)} ${date.getFullYear()}`;
      }
      return '';
    },
  },
  methods: {
    async changeStatus(status) {
      this.changeStatusLoading = true;
      try {
        await axios.put(`workorders/leave/${this.id}/update_status`, {status});
      } catch (e) {
        notification.notify(
            this.$t('notify.error'),
            this.$t('system.can_not_change'),
            'error');
      }
      await this.fetchData(false);
      this.changeStatusLoading = false;
    },
    async fetchData(withLoading = true) {
      if (withLoading) {
        this.loading = true;
      }
      if (this.id) {
        try {
          const {data} = await axios.get(`workorders/leave/${this.id}`);
          this.request = data.data;
          this.events = [{
            name: '',
            start: data.data.from,
            end: data.data.to,
          }];
          this.value = data.data.from === '0000-00-00' ? '' : data.data.from;
        } catch (e) {
          notification.notify(
              this.$t('notify.error'),
              this.$t('system.can_not_load'),
              'error');
        } finally {
          this.loading = false;
        }
      }
    },
    close() {
      this.$emit('close');
    },
    getStatusType(status) {
      switch (status) {
        case 'pending':
          return 'info';
        case 'rejected':
          return 'danger';
        case 'accepted':
          return 'success';
        default:
          return '#909399';
      }
    },
  },
};
</script>

<style scoped>

</style>
