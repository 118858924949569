<template>
  <div
      :class="{'view-container__no-background mb-0' : !wrapperBackground}"
      class="view-container"
  >
    <div class="view">
      <div class="view-body">
        <slot v-if="ifHasPermission(permissions)" />
        <span
            class="no-date-title"
            v-else
        >{{ $t('system.no_permissions') }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ContentWrapper',
  props: {
    permissions: {
      default: null,
    },
    wrapperBackground: {
      default: true,
    },
  },
};
</script>

<style
    lang="scss"
    scoped
>
.view-container__no-background {
  background-color: transparent !important;
  box-shadow: none !important;

  > .view > .view-body {
    padding: 0 !important;
  }
}
</style>
