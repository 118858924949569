import VueI18n from '../config/i18n';
import {andGuard} from '../guards/guards';

export default [
  {
    path: 'dashboard',
    component: () => import('../views/Dashboard'),
    name: 'Dashboard',
    beforeEnter: andGuard,
    meta: {
      description: VueI18n.t('system.my_page'),
      breadcrumb: [{name: 'Dashboard', title: 'system.dashboard'}],
      // permissions: ['dashboard']
    },
  },
];
