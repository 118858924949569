import Vue from 'vue';
import Vuex from 'vuex';
import createMutationsSharer from 'vuex-shared-mutations';
// import createPersistedState from 'vuex-persistedstate'
import getters from './getters';
import state from './state';
import actions from './actions';
import mutations from './mutations';
// import tagsView from './modules/tagsView'
// import time from './modules/time'
// import timeEntry from './modules/timeEntry'
import version from './modules/version';
import issue from './modules/issue';
import auth from './modules/auth';
import project from './modules/project';
import permission from './modules/permission';
import report from './modules/report';
// import generator from './modules/generator'
// import orders from './modules/orders';
// import shop from './modules/shop';
// import cart from './modules/cart';
// import enumeration from './modules/enumeration';
import filters from './modules/filters';
import translate from './modules/translate';
import modal from './modules/modal';
import unsettled_reports from './modules/unsettled_reports';
import unsettled_reports_stash from './modules/unsettled_reports_stash';
import settled_reports from './modules/settled_reports';
import payroll from './modules/payroll';
import favorite from './modules/favorite';
import modules from './modules/modules';
import stopwatches from './modules/stopwatches';
import trackers from './modules/trackers';
import workOrders from './modules/workOrders';
import workOrderPayments from './modules/workOrderPayments';

Vue.use(Vuex);

export default new Vuex.Store({
  state,
  actions,
  mutations,
  getters,
  // plugins: [createPersistedState({storage: window.sessionStorage})],
  modules: {
    auth,
    // time,
    // tagsView,
    // timeEntry,
    version,
    issue,
    project,
    report,
    permission,
    // generator,
    // orders,
    // shop,
    // cart,
    // enumeration,
    filters,
    translate,
    modal,
    unsettled_reports,
    unsettled_reports_stash,
    settled_reports,
    payroll,
    favorite,
    modules,
    stopwatches,
    trackers,
    workOrders,
    workOrderPayments,
  },
  plugins: [createMutationsSharer({
    predicate: [
      'auth/logoutUser',
    ],
  })],
});
