import axios from 'axios';
// import housecall from 'housecall'
import _ from 'lodash';
import moment from 'moment';
import {getLocalStorage, setLocalStorage} from '../../helpers/localStorage';

const unsettled_reports = {
  namespaced: true,
  state: {
    timeEntriesIdColumn: 'time_entries.id',
    currentProjectId: null,
    // Lists
    projects: [],
    timeEntries: [],
    // All ids
    allProjectsIds: [],
    allTimeEntriesIds: [],
    // Lists Params
    projectsListParams: {
      column: 'projects.id',
      direction: 'desc',
      page: 1,
      per_page: 10,
    },
    timeEntriesListParams: {
      column: 'time_entries.id',
      direction: 'desc',
      page: 1,
      per_page: 10,
    },
    // Filters
    timeEtriesFilters: [
      {
        dictionary: {
          name: '',
          isRemote: false,
        },
        label: 'system.issue_status',
        name: 'issueStatuses',
        type: 'multiSelect',
        value: [],
        values: [],
      },
      {
        dictionary: {
          name: '',
          isRemote: false,
        },
        label: 'system.date_range',
        name: 'dateRange',
        type: 'dateRangePicker',
        value: [],
        values: [],
      },
    ],
    // Search strings
    projectsListSearchString: '',
    timeEntriesListSearchString: '',
    // Total counts
    totalProjectsCount: 0,
    totalTimeEntriesCount: 0,
    // Selected IDs
    selectedProjectsIds: [],
    selectedTimeEntriesIds: [],
    // Thead
    timeEntriesThead: [],
    selectedTimeEntriesThead: [
      'time_entries.id',
      'time_entries.comments',
    ],
    projectsThead: [
      {
        key: 'projects.id',
        label: 'projects.id',
      },
      {
        key: 'projects.name',
        label: 'projects.name',
      },
    ],
    selectedProjectsThead: [
      'projects.id', 'projects.name',
    ],
    // Configs
    columnsConfigs: [],
    filtersConfigs: [],
    currentColumnsConfig: null,
    currentFiltersConfig: null,
    // Other data
    timeSumOfAllTimeEntries: 0,
    timeSumOfSelectedTimeEntries: 0,
    isProcessing: false,
  },
  getters: {
    currentProjectId: (state) => state.currentProjectId,
    // Lists
    projects: (state) => state.projects,
    timeEntries: (state) => state.timeEntries,
    // All ids
    allProjectsIds: (state) => state.allProjectsIds,
    allTimeEntriesIds: (state) => state.allTimeEntriesIds,
    // Lists Params
    projectsListParams: (state) => state.projectsListParams,
    timeEntriesListParams: (state) => state.timeEntriesListParams,
    // Filters
    timeEtriesFilters: (state) => state.timeEtriesFilters,
    // Search strings
    projectsListSearchString: (state) => state.projectsListSearchString,
    timeEntriesListSearchString: (state) => state.timeEntriesListSearchString,
    // Total counts
    totalProjectsCount: (state) => state.totalProjectsCount,
    totalTimeEntriesCount: (state) => state.totalTimeEntriesCount,
    // Selected IDs
    selectedProjectsIds: (state) => state.selectedProjectsIds,
    selectedTimeEntriesIds: (state) => state.selectedTimeEntriesIds,
    // Count of selected items
    countOfSelectedProjects: (state) => state.selectedProjectsIds.length,
    countOfSelectedTimeEntries: (state) => state.selectedTimeEntriesIds.length,
    // Thead
    timeEntriesThead: (state) => state.timeEntriesThead,
    selectedProjectsThead: (state) => state.selectedProjectsThead,
    projectsThead: (state) => state.projectsThead,
    selectedTimeEntriesThead: (state) => state.selectedTimeEntriesThead,
    // Checked all rows
    isCheckedAllProjects: (state) => {
      if (state.projects.length === 0) {
        return false;
      } else {
        const diffArray = _.difference(state.allProjectsIds, state.selectedProjectsIds);
        return !(diffArray.length > 0);
      }
    },
    isCheckedAllTimeEntries: (state) => {
      if (state.timeEntries.length === 0) {
        return false;
      } else {
        const diffArray = _.difference(state.allTimeEntriesIds, state.selectedTimeEntriesIds);
        return !(diffArray.length > 0);
      }
    },
    // Other data
    timeSumOfSelectedTimeEntries: (state) => state.timeSumOfSelectedTimeEntries,
    isProcessing: (state) => state.isProcessing,
  },
  mutations: {
    SET_PROJECTS: (state, projects) => {
      state.projects = projects;
    },
    SET_TIME_ENTRIES: (state, timeEntries) => {
      state.timeEntries = timeEntries;
    },
    SET_TIME_ENTRIES_THEAD: (state, thead) => {
      state.timeEntriesThead = thead;
    },
    SET_ALL_PROJECTS_IDS: (state, ids) => {
      state.allProjectsIds = ids;
    },
    SET_ALL_TIME_ENTRIES_IDS: (state, ids) => {
      state.allTimeEntriesIds = ids;
    },
    SET_NEW_SELECTED_PROJECT_ID: (state, id) => {
      state.selectedProjectsIds.push(id);
    },
    REMOVE_SELECTED_PROJECT_ID: (state, id) => {
      const index = state.selectedProjectsIds.indexOf(id);
      state.selectedProjectsIds.splice(index, 1);
    },
    SET_NEW_SELECTED_TIME_ENTRY_ID: (state, id) => {
      state.selectedTimeEntriesIds.push(id);
    },
    REMOVE_SELECTED_TIME_ENTRY_ID: (state, id) => {
      const index = state.selectedTimeEntriesIds.indexOf(id);
      state.selectedTimeEntriesIds.splice(index, 1);
    },
    SUBTRACT_TIME_FROM_TOTAL_TIME_SUM(state, id) {
      const time = _.find(state.timeEntries, {[state.timeEntriesIdColumn]: id});
      if (time['time_entries.hours']) {
        state.timeSumOfSelectedTimeEntries -= moment.duration(time['time_entries.hours']).asSeconds();
      }
    },
    ADD_TIME_TO_TOTAL_TIME_SUM(state, id) {
      const time = _.find(state.timeEntries, {[state.timeEntriesIdColumn]: id});
      if (time['time_entries.hours']) {
        state.timeSumOfSelectedTimeEntries += moment.duration(time['time_entries.hours']).asSeconds();
      }
    },
    SET_TIME_SUM_OF_SELECTED_TIME_ENTRIES(state, seconds) {
      state.timeSumOfSelectedTimeEntries = seconds;
    },
    SET_TIME_SUM_OF_ALL_TIME_ENTRIES(state, seconds) {
      state.timeSumOfAllTimeEntries = seconds;
    },
    SET_CHECKED_ALL_PROJECTS: (state, bool) => {
      if (bool) {
        state.selectedProjectsIds = _.clone(state.allProjectsIds);
      } else {
        state.selectedProjectsIds = [];
      }
    },
    SET_CHECKED_ALL_TIME_ENTRIES: (state, bool) => {
      if (bool) {
        state.selectedTimeEntriesIds = _.clone(state.allTimeEntriesIds);
      } else {
        state.selectedTimeEntriesIds = [];
      }
    },
    SET_SELECTED_TIME_ENTRIES_IDS: (state, ids) => {
      state.selectedTimeEntriesIds = ids;
    },
    SET_SELECTED_PROJECTS_THEAD: (state, columns) => {
      state.selectedProjectsThead = columns;
    },
    SET_SELECTED_TIME_ENTRIES_THEAD: (state, columns) => {
      state.selectedTimeEntriesThead = columns;
    },
    SET_TOTAL_PROJECTS_COUNT: (state, count) => {
      state.totalProjectsCount = count;
    },
    SET_TOTAL_TIME_ENTRIES_COUNT: (state, count) => {
      state.totalTimeEntriesCount = count;
    },
    SET_PROJECTS_LIST_PARAMS: (state, params) => {
      state.projectsListParams = params;
    },
    SET_TIME_ENTRIES_LIST_PARAMS: (state, params) => {
      state.timeEntriesListParams = params;
    },
    SET_PROJECTS_LIST_SEARCH_STRING: (state, str) => {
      state.projectsListSearchString = str;
    },
    SET_TIME_ENTRIES_LIST_SEARCH_STRING: (state, str) => {
      state.timeEntriesListSearchString = str;
    },
    CLEAR_ALL_PROJECTS_SELECTIONS: (state) => {
      state.selectedProjectsIds = [];
    },
    CLEAR_ALL_TIME_ENTRIES_SELECTIONS: (state) => {
      state.selectedTimeEntriesIds = [];
    },
    SET_CURRENT_PROJECT_ID: (state, id) => {
      state.currentProjectId = id;
    },
    SET_ISSUE_STATUSES_OPTIONS_IN_TIME_ENTRIES_FILTERS: (state, data) => {
      const candidateIdx = state.timeEtriesFilters.findIndex(item => item.name === 'issueStatuses');
      if (candidateIdx >= 0) {
        state.timeEtriesFilters.splice(
          candidateIdx,
          1,
          {...state.timeEtriesFilters[candidateIdx], values: data},
        );
      }
    },
    REMOVE_SELECTED_TIME_ENTRIES_IDS: (state, ids) => {
      state.selectedTimeEntriesIds = state.selectedTimeEntriesIds.filter((el) => {
        return !ids.includes(el);
      });
    },
    SET_IS_PROCESSING: (state, bool) => {
      state.isProcessing = bool;
    },
    CLEAR_TIME_ENTRIES_FILTERS: (state) => {
      state.timeEtriesFilters = state.timeEtriesFilters.map(item => ({...item, value: null}));
    },
    SET_FILTERS(state, filters) {
      state.timeEtriesFilters = filters;
    },
  },
  actions: {
    // setTimeEntriesThead(context) {
    //   if (context.rootGetters.systemSetting && context.rootGetters.systemSetting.reportTimeEntriesThead) {
    //     context.commit('SET_TIME_ENTRIES_THEAD', context.rootGetters.systemSetting.reportTimeEntriesThead.all)
    //     context.commit('SET_SELECTED_TIME_ENTRIES_THEAD',
    // context.rootGetters.systemSetting.reportTimeEntriesThead.selected) } },
    setFilters({commit}, {filters}) {
      commit('SET_FILTERS', filters);
    },
    clearTimeEntriesFilters(context) {
      context.commit('CLEAR_TIME_ENTRIES_FILTERS');
    },
    async removeSelectedTimeEntriesIds(context, ids) {
      context.commit('REMOVE_SELECTED_TIME_ENTRIES_IDS', ids);
      await context.dispatch('stashTimeEntries', {
        timeEntriesIds: ids,
        insert: false,
      });
    },
    setCurrentProjectId(context, id) {
      context.commit('SET_CURRENT_PROJECT_ID', id);
    },
    setProjectsListSearchString(context, str) {
      context.commit('SET_PROJECTS_LIST_SEARCH_STRING', str);
    },
    setTimeEntriesListSearchString(context, str) {
      context.commit('SET_TIME_ENTRIES_LIST_SEARCH_STRING', str);
    },
    setProjectsListParams(context, params) {
      context.commit('SET_PROJECTS_LIST_PARAMS', params);
    },
    setTimeEntriesListParams(context, params) {
      context.commit('SET_TIME_ENTRIES_LIST_PARAMS', params);
    },
    async setProjectSelectionChange(context, projectId) {
      if (context.getters.selectedProjectsIds.includes(projectId)) {
        context.commit('REMOVE_SELECTED_PROJECT_ID', projectId);
      } else {
        context.commit('SET_NEW_SELECTED_PROJECT_ID', projectId);
      }
    },
    async setTimeEntrySelectionChange(context, timeEntryId) {
      // let queue = housecall({
      //   concurrency: 2, cooldown: 100
      // });
      // queue.push(() => axios.get('avatar').then((response, body) => {
      //   console.log('response', response)
      // }));
      let insert = false;
      if (context.getters.selectedTimeEntriesIds.includes(timeEntryId)) {
        if (context.rootGetters['unsettled_reports_stash/selectedTimeEntriesIds'].includes(timeEntryId)) {
          context.dispatch('unsettled_reports_stash/setTimeEntrySelectionChange', timeEntryId, {root: true});
        }
        context.commit('REMOVE_SELECTED_TIME_ENTRY_ID', timeEntryId);
        context.commit('SUBTRACT_TIME_FROM_TOTAL_TIME_SUM', timeEntryId);
      } else {
        context.commit('SET_NEW_SELECTED_TIME_ENTRY_ID', timeEntryId);
        context.commit('ADD_TIME_TO_TOTAL_TIME_SUM', timeEntryId);
        insert = true;
      }
      await context.dispatch('stashTimeEntries', {
        timeEntriesIds: [timeEntryId],
        insert: insert,
      });
    },
    async stashTimeEntries(context, data) {
      let interval = setInterval(() => {
        if (!context.getters.isProcessing) {
          context.commit('SET_IS_PROCESSING', true);
          return axios.post('stash_time_entry', {
            project_id: parseInt(context.getters.currentProjectId),
            timeEntriesIds: data.timeEntriesIds,
            insert: data.insert,
          }).finally(() => {
            clearInterval(interval);
            context.commit('SET_IS_PROCESSING', false);
          });
        }
      }, 1);
    },
    async getStashedTimeEntries(context) {
      return axios.get('stashed_time_entries', {
        params: {
          project_id: parseInt(context.getters.currentProjectId),
        },
      }).then((response) => {
        context.commit('SET_SELECTED_TIME_ENTRIES_IDS', response.data.ids);
        context.commit('SET_TIME_SUM_OF_SELECTED_TIME_ENTRIES', response.data.timesSum);
      });
    },
    async selectAllProjects(context, bool) {
      context.commit('SET_CHECKED_ALL_PROJECTS', bool);
    },
    async selectAllTimeEntries(context, bool) {
      context.commit('SET_CHECKED_ALL_TIME_ENTRIES', bool);
      context.dispatch('setTimeSumOfSelectedTimeEntries', bool ? context.state.timeSumOfAllTimeEntries : 0);
      await context.dispatch('stashTimeEntries', {
        timeEntriesIds: context.getters.allTimeEntriesIds,
        insert: bool,
      });
      context.dispatch('unsettled_reports_stash/selectAllTimeEntries', bool, {root: true});
    },
    setTimeSumOfSelectedTimeEntries(context, seconds) {
      context.commit('SET_TIME_SUM_OF_SELECTED_TIME_ENTRIES', seconds);
    },
    clearAllProjectsSelections(context) {
      context.commit('CLEAR_ALL_PROJECTS_SELECTIONS');
    },
    clearAllTimeEntriesSelections(context) {
      context.commit('CLEAR_ALL_TIME_ENTRIES_SELECTIONS');
    },
    clearAllProjects(context) {
      context.commit('SET_PROJECTS', []);
    },
    clearAllTimeEntries(context) {
      context.commit('SET_TIME_ENTRIES', []);
    },
    async updateTimeEntryData(context, data) {
      return axios.put(`time_entry/${data.id}`, {
        value: data.value,
        column: data.col,
      });
    },
    async fetchProjects(context) {
      return axios.get(`subprojects/${context.rootGetters.currentProjectId}`, {
        params: {
          ...context.getters.projectsListParams,
          search: context.getters.projectsListSearchString,
          columns: context.getters.selectedProjectsThead,
        },
      }).then((response) => {
        context.commit('SET_PROJECTS', response.data.data);
        context.commit('SET_ALL_PROJECTS_IDS', response.data.allIds);
        if (response.data.total) {
          context.commit('SET_TOTAL_PROJECTS_COUNT', response.data.total);
        }
      });
    },
    async fetchIssueStatuses(context) {
      return axios.get('issue_statuses')
        .then((response) => {
          context.commit('SET_ISSUE_STATUSES_OPTIONS_IN_TIME_ENTRIES_FILTERS', response.data.map(item => ({
            ...item,
            name: item.label,
          })));
        });
    },
    async fetchTimeEntries(context) {
      const requestFilters = {};
      context.state.timeEtriesFilters.forEach(item => {
        if (item.type === 'checkbox' || item.type === 'switch') {
          requestFilters[item.name] = !!item.value;
        } else {
          requestFilters[item.name] = item.value;
        }
      });
      return axios.post('time_entries', {
        ...context.getters.timeEntriesListParams,
        projects: context.getters.selectedProjectsIds,
        columns: context.getters.selectedTimeEntriesThead,
        search: context.getters.timeEntriesListSearchString,
        filters: requestFilters,
        settled: false,
      }).then((response) => {
        context.commit('SET_TIME_ENTRIES', response.data.data);
        context.commit('SET_ALL_TIME_ENTRIES_IDS', response.data.allIds);
        context.commit('SET_TIME_SUM_OF_ALL_TIME_ENTRIES', response.data.allTimesSum);
        context.commit('SET_TOTAL_TIME_ENTRIES_COUNT', response.data.total);
      });
    },
    setSelectedProjectsThead(context, columns) {
      context.commit('SET_SELECTED_PROJECTS_THEAD', columns);
      const reportColumns = getLocalStorage('reportColumns');
      reportColumns.unsettled.projects = columns;
      setLocalStorage('reportColumns', reportColumns);
    },
    setSelectedTimeEntriesThead(context, columns) {
      context.commit('SET_SELECTED_TIME_ENTRIES_THEAD', columns);
      const reportColumns = getLocalStorage('reportColumns');
      reportColumns.unsettled.timeEntries = columns;
      setLocalStorage('reportColumns', reportColumns);
    },
    setCurrentColumnsConfig(context, config) {
      console.log('config', config);
    },
  },
};

export default unsettled_reports;
