import store from '../store/index';
import notification from '../notification/notify';

const andGuard = function (to, from, next) {
  for (let idx in to.matched) {
    if (Object.hasOwnProperty.call(to.matched, idx)) {
      const route = to.matched[idx];
      const permissions = route.meta.permissions;
      let currentProject = to.params.projectId ? to.params.projectId : null;
      if (permissions && !store.getters['permission/checkPermissions'](permissions, currentProject)) {
        next('/');
      }
    }
  }
  next();
};

const orGuard = function (to, from, next) {
  if (to.meta.permissions && !store.getters['permission/canOr'](to.meta.permissions)) {
    notification.noPermissions('no_permissions', to.meta.permissions, 'warning');
    return false;
  } else {
    next();
  }
};

export {
  andGuard,
  orGuard,
};
