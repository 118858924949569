import store from '../store';

export default {
  methods: {
    ifHasPermission(permission, projectId = null) {
      if (permission) {
        if (permission.length === 0) {
          return false;
        }
        if (permission.length === 1 && !store.getters['permission/checkPermissions'](permission, projectId)) {
          return false;
        } else if (permission.length > 1 && !store.getters['permission/canOr'](permission, projectId)) {
          return false;
        }
      }
      return true;
    },
  },
};
