import Vue from 'vue';
import App from './App.vue';
import router from './router';
import axios from 'axios';
import store from './store';
import {sync} from 'vuex-router-sync';
import VueObserveVisibility from 'vue-observe-visibility';
import DatetimePicker from 'vuetify-datetime-picker'
import 'bootstrap/dist/css/bootstrap.css';
import i18n from './config/i18n';
import validation from './mixin/validation';
import changeLanguage from './mixin/changeLanguage';
import permissionMixin from './mixin/permission';
import './plugins/element.js';
import directive from './directive';
import './static/css/index.scss';
import './static/css/custom12.scss';

// icons
import './config/fa.config';
import './static/icons';
import vuetify from './plugins/vuetify';
import VueMask from 'v-mask';

Vue.use(VueObserveVisibility);
Vue.use(VueMask);
Vue.use(DatetimePicker);

Vue.mixin(permissionMixin);
Vue.mixin(changeLanguage);
Vue.mixin(validation);

sync(store, router);

let lang = localStorage.getItem('token') !== 'null' ? localStorage.getItem('langLocal') : localStorage.getItem('langGlobal');
lang = lang !== null ? lang : 'en';

axios.defaults.baseURL = process.env.VUE_APP_ROOT_API;
// console.log('process.env', process.env)
axios.interceptors.request.use((config) => {
  if (window.localStorage.getItem('token')) {
    config.headers.Authorization = 'Bearer ' + window.localStorage.getItem('token');
  }
  if (config.type && config.type === 'auth') {
    config.baseURL = config.baseURL.slice(0, config.baseURL.length - 4) + '/oauth';
  }
  // Do something before request is sent
  if (!config.params || Object.keys(config.params).length === 0) {
    config.params = {
      projectId: store.getters.currentProjectId,
      issue_id: store.getters.currentIssueId,
      // clientId: localStorage.getItem('clientId'), //to identify db connection in middleware
      locale: lang,
    };
  } else {
    config.params.projectId = store.getters.currentProjectId;
    // config.params.clientId = localStorage.getItem('clientId');
    config.params.locale = lang;
  }
  return config;
}, (error) => {
  // Do something with request error
  return Promise.reject(error);
});

new Vue({
  router,
  i18n: i18n,
  store,

  directives: {
    directive,
  },

  created() {
    this.changeLanguageMixin(lang).then(() => {
      document.title = this.$t(router.currentRoute.meta.description);
    });
  },

  vuetify,
  render: h => h(App),
}).$mount('#app');
