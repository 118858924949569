const workOrders = {
  namespaced: true,
  state: {
    tabs: [
      // id 0 - list
      {
        title: 'system.work_orders',
        id: '0',
        route: {
          name: 'WorkOrdersList',
          fullPath: '/work-orders/list',
        },
      },
    ],
    activeTabId: '0',
  },
  getters: {},
  mutations: {
    SET_ACTIVE_TAB_ID(state, id) {
      state.activeTabId = id.toString();
    },
    ADD_TAB(state, tab) {
      state.tabs = [...state.tabs, tab];
    },
    SET_TABS(state, tabs) {
      state.tabs = [...tabs];
    },
    CLEAR_TABS(state) {
      state.tabs = [{
        title: 'system.work_orders',
        id: '0',
        route: {
          name: 'WorkOrdersList',
          fullPath: '/work-orders/list',
        },
      }];
      state.activeTabId = '0';
    },
  },
  actions: {
    setActiveTab({commit}, {id}) {
      commit('SET_ACTIVE_TAB_ID', id);
    },
    addTab({
             commit,
             state,
           }, {tab}) {
      const candidateIdx = state.tabs.findIndex(item => item.id.toString() === tab.id.toString());
      if (candidateIdx > -1) {
        const tabs = [...state.tabs];
        tabs.splice(candidateIdx, 1, tab);
        // console.log('addTab')
        commit('SET_TABS', tabs);
        commit('SET_ACTIVE_TAB_ID', tab.id);
      } else {
        commit('ADD_TAB', tab);
        commit('SET_ACTIVE_TAB_ID', tab.id);
      }
    },
    removeTab({
                commit,
                state,
              }, {id}) {
      const candidateIdx = state.tabs.findIndex(item => item.id === id);
      if (candidateIdx > -1) {
        const currentTabIdx = state.tabs.findIndex(item => item.id.toString() === state.activeTabId);
        const moveIndex = candidateIdx < currentTabIdx || currentTabIdx === state.tabs.length - 1;
        const tabs = [...state.tabs];
        tabs.splice(candidateIdx, 1);
        // console.log('removeTab')
        commit('SET_TABS', tabs);
        if (moveIndex) {
          commit('SET_ACTIVE_TAB_ID', state.tabs[currentTabIdx - 1].id);
        }
      }
    },
    clearTabs(context) {
      context.commit('CLEAR_TABS');
    },
  },
};

export default workOrders;
