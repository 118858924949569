import {Notification} from 'element-ui';

const notify = function (title, message, type) {
  let durationNotify = type === 'error' ? 0 : 6000;
  Notification({ // eslint-disable-line
    title: title,
    message: message,
    type: type,
    dangerouslyUseHTMLString: true,
    iconClass: '',
    customClass: '',
    duration: durationNotify,
    position: 'top-right',
    showClose: true,
    offset: 0,
  });
};

export default {
  notify,
};
