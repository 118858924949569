import axios from 'axios';
import router from '../../router/index';

const state = {
  user: null,
  entryUrl: '/',
  avatar: null,
};

const getters = {
  user(state) {
    return state.user;
  },
  isAuthenticated() {
    return !!window.localStorage.getItem('token');
  },
  token() {
    return window.localStorage.getItem('token');
  },
  entryUrl(state) {
    return state.entryUrl;
  },
  username: state => {
    if (state.user && Object.keys(state.user)) {
      if (state.user.first_name && state.user.last_name) {
        return state.user.email;
      } else {
        return state.user.first_name + ' ' + state.user.last_name;
      }
    }
    return '';
  },
};
const mutations = {
  SET_AVATAR(state, avatar) {
    state.avatar = avatar;
  },
  SET_USER(state, user) {
    state.user = user;
  },
  SET_ENTRY_URL: (state, value) => {
    state.entryUrl = value;
  },
  // SET_TOKEN (state, token) {
  //   window.localStorage.setItem('token', token)
  //   window.addEventListener('storage', (event) => {
  //     if (event.key === 'token') {
  //       console.log('must be logout')
  //     }
  //   })
  //   // axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
  // }
  // logoutUser (state) {
  //   state.user = null;
  //   state.token = null;
  //   state.clientId = null;
  //   if (window.localStorage) {
  //     var userString = window.localStorage.getItem('user');
  //     if (userString != null) {
  //       var user = JSON.parse(userString);
  //     }
  //     window.localStorage.setItem('user', null);
  //     window.localStorage.setItem('token', null);
  //     window.localStorage.setItem('clientId', null);
  //     //fire logout event
  //     if (user != null) {
  //       window.localStorage.setItem('logout-event', user.email);
  //       window.localStorage.removeItem('logout-event');
  //     }
  //   }
  // }
};

const actions = {
  fetchAvatar({commit}) {
    return axios.get('avatar', {
      responseType: 'blob',
    }).then(response => {
      let reader = new FileReader();
      reader.readAsDataURL(response.data);
      reader.onload = () => {
        commit('SET_AVATAR', reader.result);
      };
    }).catch(() => {
      commit('SET_AVATAR', null);
    });
  },

  setRefreshToken(refreshToken) {
    window.localStorage.setItem('refreshToken', refreshToken);
  },

  setLogoutListner() {
    window.addEventListener('storage', async (event) => {
      if (event.key === 'token') {
        if (window.localStorage.getItem('token')) {
          await router.push('/dashboard');
        } else {
          await router.push('/login');
        }
      }
    });
  },

  async refreshToken({dispatch}) {
    if (localStorage.getItem('refreshToken')) {
      return axios.post('token', {
        grant_type: 'refresh_token',
        client_id: 1,
        client_secret: process.env.CLIENT_SECRET,
        scope: '',
        refresh_token: localStorage.getItem('refreshToken'),
      }, {
        type: 'auth',
      }).then(async response => {
        const data = response.data;
        await dispatch('login', {
          token: data.access_token,
          refreshToken: data.refresh_token,
        });
      }).catch(async () => {
        // await router.push('/login')
      });
    }
  },

  async login({dispatch}, {token, refreshToken}) {
    if (token) {
      localStorage.setItem('token', token);
      await dispatch('setRefreshToken', refreshToken);
      await dispatch('setLogoutListner');
    }
  },

  async logout({commit, dispatch}) {
    // window.localStorage.removeItem('token')
    // await axios.post(`logout`);
    commit('SET_USER', null);
    localStorage.setItem('token', '');
    await dispatch('setRefreshToken', '');
    // commit('logoutUser');
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
