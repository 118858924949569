<template>
  <div>
    <div class="d-flex justify-content-between">
      <h5>{{ $t('system.vacation_requests') }}</h5>
      <DropdownConfigurator
          :filters="filters"
          :searchString.sync="searchPhrase"
          :showFiltersConfigurations="false"
          @clearFilters="clearFilters"
          @submitSearch="setFiltersAndFetchData"
          disableColumns
      />
    </div>
    <el-divider />
    <DataTable
        :headers="headers"
        :idPath="tableIdentifierColumn"
        :loading="loading"
        :model="data"
        :params.sync="params"
        @clickRow="onClickRow"
        @updateData="fetchData"
    />
    <VacationRequest
        :id="currentRequestId"
        :isVisible="!!currentRequestId"
        @close="onCloseCurrentRequest"
    />
  </div>
</template>

<script>
import axios from 'axios';
import DropdownConfigurator from '../DropdownConfigurator/crmDropdownConfigurator.vue';
import DataTable from '../Table/DataTable.vue';
import VacationRequest from './VacationRequest';

export default {
  name: 'UserVacationRequestsList',
  components: {VacationRequest, DataTable, DropdownConfigurator},
  props: {
    userId: {
      type: [String, Number],
    },
  },
  data() {
    return {
      headers: [
        {
          key: 'username',
          label: 'system.username',
        },
        {
          key: 'from',
          label: 'system.start_at',
        },
        {
          key: 'to',
          label: 'system.end_at',
        },
        {
          key: 'reason',
          label: 'system.reason',
        },
        {
          key: 'status',
          label: 'system.status',
        },
      ],
      tableIdentifierColumn: 'id',
      data: [],
      filters: [
        {
          checked: false,
          label: 'system.created_at',
          name: 'created_at',
          type: 'dateRangePicker',
          value: null,
        },
        {
          checked: false,
          label: 'system.start_at',
          type: 'dateRangePicker',
          name: 'start_at',
          value: null,
        },
        {
          checked: false,
          label: 'system.end_at',
          type: 'dateRangePicker',
          name: 'end_at',
          value: null,
        },
        {
          checked: false,
          label: 'system.status',
          type: 'select',
          name: 'status_id',
          value: null,
          dictionary: {
            isRemote: false,
          },
          values: [
            {
              id: 'pending',
              name: 'pending',
            },
            {
              id: 'accepted',
              name: 'accepted',
            },
            {
              id: 'rejected',
              name: 'rejected',
            },
          ],
        },
      ],
      searchPhrase: '',
      loading: false,
      params: {
        column: 'id',
        direction: 'desc',
        per_page: 20,
        page: 1,
        total: 0,
        per_page_sizes: [20, 30, 50, 100],
      },
      currentRequestId: null,
    };
  },
  beforeMount() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      const params = {...this.params};
      if (this.searchPhrase) {
        params.search = this.searchPhrase;
      }
      if (this.filters.length) {
        const anotherFilters = this.filters.filter(item => item.checked && (
                item.name !== 'userType' &&
                (item.type === 'switch' ||
                    item.type === 'checkbox' ||
                    (Array.isArray(item.value) && item.value.length) ||
                    (!Array.isArray(item.value) && item.value)
                )
            ),
        );
        anotherFilters.forEach(item => {
          params[item.name] = item.value;
        });
      }
      const {data} = await axios.get(`workorders/leave/${this.userId}/list`, {params});
      this.data = data.data;
      this.params.total = data.meta.total;
    },
    setFiltersAndFetchData(filters) {
      this.filters = [...filters];
      this.fetchData();
    },
    onCloseCurrentRequest() {
      this.currentRequestId = null;
      this.fetchData();
    },
    onClickRow(id) {
      this.currentRequestId = id;
    },
    clearFilters() {
      this.filters = this.filters.map(item => {
        return {...item, checked: false, value: null};
      });
    },
  },
};
</script>

<style scoped>

</style>
