import VueI18n from '../config/i18n';
// import Profile from '../views/Profile/Index.vue'

export default [
  {
    path: '/profile',
    component: () => import('../views/Profile/Index.vue'),
    name: 'Profile',
    meta: {
      description: VueI18n.t('system.profile'),
      breadcrumb: [{name: 'Profile', title: 'breadcrumb.profile'}],
    },
  },
];
