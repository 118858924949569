import Vue from 'vue';
import store from '../../store';

export default {
  bind: function (el, binding, vnode) {
    if (!store.getters['permission/checkPermissions'](binding.value)) {
      Vue.nextTick(() => {
        vnode.elm.parentElement.removeChild(vnode.elm);
      });
    }
  },
};
