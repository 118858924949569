<template>
  <div class="view-container">
    <div
        class="view"
        v-if="ifHasPermission(['admin'])"
    >
      <div class="view-body d-flex justify-content-between">
        <router-link :to="create">
          <el-button type="primary">
            {{ $t('system.create') }}
          </el-button>
        </router-link>
        <!--          <el-input v-model="keyword" @keyup.enter.native="fetchData" @clear="clearSearch" :placeholder="$t('system.search')" clearable class="search-input"/>-->
        <DropdownConfigurator
            :filters="filters"
            :searchString.sync="keyword"
            :showFiltersConfigurations="false"
            @clearFilters="clearFilters"
            @submitSearch="setFiltersAndFetchData"
            disable-search
            disableColumns
        />
      </div>
    </div>
    <div class="view">
      <div class="view-body">
        <static-table
            :data="model"
            :params="params"
            :thead="thead"
            @sortChange="sortChange"
        />
        <el-card
            class="pagination-container mt-3"
            shadow="never"
        >
          <el-pagination
              :current-page="params.page"
              :page-size="params.per_page"
              :page-sizes="[10, 30, 50, 100, 300]"
              :total="total"
              @current-change="handleCurrentChange"
              @size-change="handleSizeChange"
              background
              layout="total, sizes, prev, pager, next, jumper"
          >
          </el-pagination>
        </el-card>
        <!--<data-viewer :source="source" :thead="thead" :filter="filter" :create="create" :createPermissions="['user-add']"-->
        <!--:title="title">-->
        <!--</data-viewer>-->
      </div>
    </div>
  </div>
</template>

<script>
// import Vue from 'vue'
import axios from 'axios';
// import _ from 'lodash';
import notification from '../../notification/notify';
import StaticTable from '../../components/ABtable/StaticTable.vue';
import DropdownConfigurator from '../../components/DropdownConfigurator/crmDropdownConfigurator.vue';

export default {
  name: 'UserIndex',
  components: {
    DropdownConfigurator,
    StaticTable,
  },
  data() {
    return {
      model: [],
      title: 'Users',
      source: 'user',
      create: '/administration/user/create',
      thead: [
        {
          label: 'users.username',
          key: 'username',
          sort: true,
          link: true,
          url: '/administration/user/',
        },
        {
          label: 'users.first_name',
          key: 'first_name',
          sort: true,
          link: true,
          url: '/administration/user/',
        },
        {
          label: 'users.last_name',
          key: 'last_name',
          sort: true,
          link: true,
          url: '/administration/user/',
        },
        {
          label: 'users.email',
          key: 'email',
          sort: true,
          link: true,
          url: '/administration/user/',
        },
        {
          label: 'users.role',
          key: 'roles_name',
          sort: false,
          link: true,
          align: 'abTable--alignCenter',
          url: '/administration/user/',
        },
        {
          label: 'users.admin',
          type: 'boolean',
          disable: true,
          key: 'admin',
          sort: false,
          link: true,
          url: '/administration/user/',
        },
        {
          label: 'users.created_at',
          key: 'created_at',
          sort: true,
          link: true,
          align: 'abTable--alignCenter',
          url: '/administration/user/',
        },
        {
          label: 'users.is_active',
          key: 'deleted_at',
          type: 'boolean',
          disable: true,
          sort: true,
          link: true,
          url: '/administration/user/',
        },
      ],
      filters: [
        {
          name: 'first_name',
          label: 'user.first_name',
          type: 'input',
          dictionary: {},
          values: null,
          checked: false,
          value: '',
        },
        {
          name: 'last_name',
          label: 'user.last_name',
          type: 'input',
          dictionary: {},
          values: null,
          checked: false,
          value: '',
        },
        {
          name: 'email',
          label: 'user.email',
          type: 'input',
          dictionary: {},
          values: null,
          checked: false,
          value: '',
        },
        {
          name: 'phone_number',
          label: 'user.phone_number',
          type: 'phoneInput',
          dictionary: {},
          values: null,
          checked: false,
          value: '',
        },
        {
          name: 'role',
          label: 'user.role',
          type: 'multiSelect',
          dictionary: {
            isRemote: false,
          },
          values: [
            // roles that were fetched in fetchRoles()
          ],
          checked: false,
          value: [],
        },
        {
          name: 'is_admin',
          label: 'user.isAdmin',
          type: 'switch',
          dictionary: {},
          values: null,
          checked: false,
          value: false,
        },
        {
          name: 'is_subcontractor',
          label: 'user.isSubcontractor',
          type: 'switch',
          dictionary: {},
          values: null,
          checked: false,
          value: false,
        },
      ],
      total: 0,
      params: {
        column: 'created_at',
        direction: 'desc',
        per_page: 10,
        page: 1,
        search_column: 'id',
        search_operator: 'equal_to',
        search_query_1: '',
        search_query_2: '',
      },
      keyword: '',
    };
  },
  beforeMount() {
    this.fetchData();
    this.fetchRoles();
  },
  methods: {
    setFiltersAndFetchData(filters) {
      this.filters = filters;
      // this.setActiveFiltersToQuery();
      // this.setFiltersValuesToQuery();
      this.fetchData();
    },
    clearFilters() {
      this.filters = this.filters.map(item => {
        return {...item, checked: false, value: item.type === 'checkbox' ? false : null};
      });
    },
    handleCurrentChange(val) {
      this.params.page = val;
      this.fetchData();
    },
    handleSizeChange(val) {
      this.params.per_page = val;
      this.fetchData();
    },
    next() {
      if (this.model.next_page_url) {
        this.params.page++;
        this.fetchData();
      }
    },
    prev() {
      if (this.model.prev_page_url) {
        this.params.page--;
        this.fetchData();
      }
    },
    sortChange(column) {
      if (column === this.params.column) {
        if (this.params.direction === 'desc') {
          this.params.direction = 'asc';
        } else {
          this.params.direction = 'desc';
        }
      } else {
        this.params.column = column;
        this.params.direction = 'asc';
      }
      this.fetchData();
    },
    async fetchRoles() {
      try {
        const {data} = await axios.get('role', {
          params: {
            'column': 'id',
            'direction': 'asc',
            'per_page': 9999,
          },
        });
        if (data.data && data.data.length) {
          data.data = data.data.map(item => {
            return {...item, name: `user_role.${item.name.toLowerCase()}`};
          });
          this.filters = this.filters.map(item => {
            const filter = {...item};
            if (filter.name === 'role') {
              filter.values = data.data;
            }
            return filter;
          });
          // const filterIdx = this.filters.findIndex(item => item.name === 'role');
          // this.filters[filterIdx].values = data.data
        }
      } catch (e) {
        //
      }
    },
    async fetchData() {
      const params = {};
      if (this.filters.length) {
        const userTypeFilter = this.filters.find(item => item.name === 'userType');
        if (userTypeFilter && userTypeFilter.checked) {
          const selectedUserTypes = userTypeFilter.value;
          // if (selectedUserTypes) {
          userTypeFilter.values.forEach(item => {
            if (selectedUserTypes && selectedUserTypes.includes(item.id)) {
              params[item.id] = true;
            } else {
              params[item.id] = false;
            }
          });
          // }
        }
        const anotherFilters = this.filters.filter(item => item.checked && (
                item.name !== 'userType' &&
                (item.type === 'switch' ||
                    item.type === 'checkbox' ||
                    (Array.isArray(item.value) && item.value.length) ||
                    (!Array.isArray(item.value) && item.value)
                )
            ),
        );
        anotherFilters.forEach(item => {
          params[item.name] = item.value;
        });
      }
      await axios.get(this.buildURL(), {
        params,
      }).then((response) => {
        let arr = response.data.model.data;
        arr = arr.map(item => {
          return {...item, roles_name: item.roles && item.roles[0] ? item.roles[0].name : ''};
        });
        // _.forEach(arr, function(item, index, theArray) {
        //   item['roles_name'] = item.roles && item.roles[0] ? item.roles[0].name : ''
        // });
        this.total = response.data.model.total;
        this.model = arr;
        // Vue.set(this.$data, 'model', arr)
      })
          .catch((error) => {
            notification.notify(
                this.$t('notify.error'),
                error.response.data.error,
                'error');
          });
    },
    buildURL() {
      let p = this.params;
      return `${this.source}?keyword=${this.keyword}&column=${p.column}&direction=${p.direction}&per_page=${p.per_page}&page=${p.page}&search_column=${p.search_column}&search_operator=${p.search_operator}&search_query_1=${p.search_query_1}&search_query_2=${p.search_query_2}`;
    },
  },
};
</script>

<style
    lang="scss"
    rel="stylesheet/scss"
    scoped
>
.search-input {
  width: 200px;
}
</style>
