import VueI18n from '../config/i18n';

export default [
  {
    path: '/targets/contractors',
    name: 'ContractorsTarget',
    component: () => import('../views/Targets/ContractorsTarget.vue'),
    props: true,
    meta: {
      description: VueI18n.t('system.contractors_target'),
      permissions: ['targets-read'],
    },
  },
  {
    path: '/targets/contractor-groups',
    name: 'ContractorGroupsTarget',
    component: () => import('../views/Targets/ContractorGroupsTarget.vue'),
    props: true,
    meta: {
      description: VueI18n.t('system.contractor_groups_target'),
      permissions: ['targets-read'],
    },
  },
];
