import VueI18n from '../config/i18n';
// import PayrollIndex from '../views/Payroll/Index.vue';
import {orGuard} from '../guards/guards';

export default [
  {
    path: '/payroll',
    component: () => import('../views/Payroll/ReportEmployee.vue'),
    name: 'Payroll',
    beforeEnter: orGuard,
    meta: {
      description: VueI18n.t('system.payroll'),
      breadcrumb: [{name: VueI18n.t('system.payroll'), title: 'breadcrumb.worked_time'}],
      permissions: ['payroll-all', 'payroll-own'],
      module: 'spain',
    },
  },
];
