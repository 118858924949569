import axios from 'axios';

const state = {
  projects: [],
  issues: [],
};
const getters = {};
const mutations = {
  SET_PROJECTS(state, projects) {
    state.projects = projects;
  },
  SET_ISSUES(state, issues) {
    state.issues = issues;
  },
};
const actions = {
  fetchFavorite({commit}) {
    return axios.get('favorite_projects')
      .then((response) => {
        commit('SET_PROJECTS', response.data.data);
      });
  },
  setFavoriteProject({dispatch}, projectId) {
    return axios.put('favorite_projects', {project_id: projectId})
      .then(() => dispatch('fetchFavorite'));
  },
  unsetFavoriteProject({dispatch}, projectId) {
    return axios.delete('favorite_projects/' + projectId)
      .then(() => dispatch('fetchFavorite'));
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
