<template>
  <div class="mt-3">
    <div
        v-bind:key="group.id"
        v-for="(permissionsArray, group) in initData"
    >
      <hr>
      <h5>{{ $t(group) }}</h5>
      <el-checkbox
          :disabled="isDisabled"
          :key="permission.name"
          @change="setPermission($event, permission.name)"
          class="col-6 col-md-4 col-lg-3"
          style="margin-left: 0"
          v-bind:label="$t('system.'+permission.name)"
          v-for="permission in permissionsArray"
          v-model="checkedPermissions[permission.name]"
      >
      </el-checkbox>
    </div>
  </div>
</template>
<script>
import Vue from 'vue';

export default {
  name: 'Permissions',
  data() {
    return {
      checkedPermissions: {},
    };
  },
  computed: {
    isDisabled: function () {
      if (this.editable === 'true') {
        return false;
      }
      return true;
    },
  },
  props: ['editable', 'initData', 'checkedFields'],
  watch: {
    checkedFields: function (val) {
      for (var key in val) {
        if (val.hasOwnProperty(key)) {
          Vue.set(this.checkedPermissions, key, true);
        }
      }
    },
  },
  methods: {
    isChecked(name) {
      if (this.checkedFields.hasOwnProperty(name)) {
        return true;
      }
      return false;
    },
    setPermission(event, newVal) {
      this.$emit('permissionsUpdated', {check: event, name: newVal});
    },
  },
};
</script>
