<template>
  <el-dialog
      :title="$t(`system.${data.type}`)"
      :visible="isVisible"
      @close="close"
      destroy-on-close
  >
    <p>{{ $t('system.name') }}: <b>{{ data.name }}</b></p>
    <p>{{ $t('system.username') }}: <b>{{ data.user }}</b></p>
    <p>{{ $t('system.date_from') }}: <b>{{ data.from }}</b></p>
    <p>{{ $t('system.date_to') }}: <b>{{ data.to ? data.to : $t('system.timeless_agreement') }}</b></p>
    <p v-if="data.type === 'insurance'">{{ $t('system.insurance_type') }}: <b>{{ data.insurance_type }}</b></p>

    <span
        class="dialog-footer"
        slot="footer"
    >
      <el-button
          :size="fieldsSize"
          @click="edit"
          type="primary"
      >
        {{ $t('system.edit') }}
      </el-button>
      <el-button
          :size="fieldsSize"
          @click="remove"
          type="danger"
      >
        {{ $t('system.delete') }}
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
import swal from 'sweetalert2';

export default {
  name: 'ContractAndInsuranceShow',
  props: {
    isVisible: {
      type: Boolean,
      default: true,
    },
    data: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    fieldsSize: 'small',
  }),
  methods: {
    close() {
      this.$emit('close');
    },
    edit() {
      this.$emit('edit', this.data.id);
    },
    async remove() {
      const result = await swal.fire({
        title: this.$t('system.remove'),
        text: this.$t('system.if_you_sure'),
        type: 'info',
        showCancelButton: true,
        focusCancel: true,
        confirmButtonText: this.$t('system.yes'),
        cancelButtonText: this.$t('system.no'),
      });
      if (result.value) {
        this.$emit('remove', this.data.id);
      }
    },
  },
};
</script>

<style scoped>

</style>
