import Vue from 'vue';

export default {
  SET_CURRENT_PROJECT_ID(state, projectId) {
    state.currentProjectId = projectId;
  },
  SET_ACTIVE(state, active) {
    state.active = active;
  },
  SET_STICKY_ARRAY(state, sticky) {
    state.stickyArray = sticky.array;
  },
  ADD_ISSUE_FILTER(state, filter) {
    state.issueFilter.groups.push(filter);
  },
  ISSUES_SET_QUICK_FILTER_DATA(state, payload) {
    state.issueFilter.checkedFilters.status = true;
    state.issueFilter.selectedValues.status = payload.data;
    state.issueFilter.selectedValues.statusOperator = payload.statusOperator;
  },
  // CLEAR_ISSUE_FILTER (state) {
  //   for (let filter in state.issueFilter.selectedValues) {
  //     if (filter === 'status' || filter === 'assign_to' || filter === 'author' || filter === 'project_ids') {
  //       state.issueFilter.selectedValues[filter] = []
  //     } else {
  //       state.issueFilter.selectedValues[filter] = null
  //     }
  //   }
  //   state.filters = {};
  //   for (let checkbox in state.issueFilter.checkedFilters) {
  //      state.issueFilter.checkedFilters[checkbox] = false
  //   }
  //   state.checkboxes = []
  // },
  SET_ISSUE_FILTER_VALUE(state, payload) {
    if (state.issueFilter.selectedValues.hasOwnProperty(payload.filter)) {
      state.issueFilter.selectedValues[payload.filter] = payload.value;
    }
  },
  SET_ISSUE_FILTER_TOOGLE(state, payload) {
    if (state.issueFilter.checkedFilters.hasOwnProperty(payload.filter)) {
      state.issueFilter.checkedFilters[payload.filter] = payload.value;
    }
  },
  SET_SYSTEM_SETTING(state, settings) {
    state.systemSetting = settings;
  },
  SET_TIME_LOGGER(state, getTimeLogger) {
    state.getTimeLogger = getTimeLogger;
  },
  SET_REFRESH_ACTIVITY(state, getRefreshActivity) {
    state.getRefreshActivity = getRefreshActivity;
  },
  SET_SEARCHING(state, searching) {
    state.searching = searching;
  },
  SET_SEARCH_CLICKED(state, value) {
    state.searchClicked = state.searchClicked + value;
  },
  SET_INVOICE_ITEMS(state, invoice_items) {
    state.invoice_items = invoice_items;
  },
  CLEAR_GROUP_PROJECTS(state) {
    state.group_projects = {
      projects: [],
      roles: [],
    };
  },
  SET_GANTT_ISSUE(state, payload) {
    state.ganttIssues = payload;
  },
  SET_GANTT_ISSUE_UPDATE(state, payload) {
    let issue = state.ganttIssues[payload.userId].issues.find(function (element) {
      return element.id === payload.issueId;
    });
    issue.offset = payload.offset;
    issue.duration = payload.duration;
    issue.isChanged = true;
  },
  SET_GANTT_ISSUE_ESTIMATED_UPDATE(state, payload) {
    let issue = state.ganttIssues[payload.userId].issues.find(function (element) {
      return element.id === payload.issueId;
    });
    Vue.set(issue, 'estimatedOffset', payload.offset);
    Vue.set(issue, 'estimatedDuration', payload.duration);
    Vue.set(issue, 'isChanged', true);
    // issue.estimatedOffset = payload.offset;
    // issue.estimatedDuration = payload.duration;
    // issue.isChanged = true
  },
  SET_GANTT_ISSUE_DETAILS_SHOW(state, payload) {
    state.ganttIssueDetailsShow = payload;
  },
  SET_GANTT_ISSUE_EDIT_SHOW(state, payload) {
    state.ganttIssueEditShow = payload;
  },
  SET_GANTT_ISSUE_DETAILS(state, payload) {
    state.ganttIssueDetailsId = payload.id;
    state.ganttIssueDetailsProjectId = payload.projectId;
  },
  SET_GANTT_CELL_WIDTH(state, payload) {
    state.ganttCellWidth = payload;
  },
  SET_GANTT_CELL_WIDTH_INCREMENT(state) {
    state.ganttCellWidth += 50;
  },
  SET_GANTT_CELL_WIDTH_DECREMENT(state) {
    state.ganttCellWidth -= 50;
  },
  SET_GANTT_IS_DATA_CHANGED_TRUE(state) {
    state.ganttIsDataChanged = true;
  },
  SET_GANTT_IS_DATA_CHANGED_FALSE(state) {
    state.ganttIsDataChanged = false;
  },
  REMOVE_GANTT_ISSUE(state, payload) {
    for (let userIdx in state.ganttIssues) {
      if (!state.ganttIssues.hasOwnProperty(userIdx)) continue;
      let user = state.ganttIssues[userIdx];
      let issuesCount = user.issues.length;
      for (let issueIdx in user.issues) {
        if (!user.issues.hasOwnProperty(issueIdx)) continue;
        let issue = user.issues[issueIdx];
        if (issue.id === payload) {
          user.issues.splice(issueIdx, 1);
          if (issuesCount === 1) {
            delete state.ganttIssues[userIdx];
          }
        }
      }
    }
  },
  SET_WATCHERS(state, watchers) {
    state.watchers = watchers;
  },
  SET_uCONFIG(state, uConfig) {
    state.uConfig = uConfig;
  },
  SET_uCONFIG_SELECTTHEAD(state, uConfig) {
    state.uConfig.selectThead = uConfig;
  },
  SET_CYCLIC_FORM(state, form) {
    state.cyclicForm = form;
  },
  REMOVE_GANTT_ESTIMATED_ISSUE_DATA(state, payload) {
    for (let issueIdx in state.ganttEstimatedData) {
      if (!state.ganttEstimatedData.hasOwnProperty(issueIdx)) continue;
      let issue = state.ganttEstimatedData[issueIdx];
      if (issue.issueId === payload) {
        state.ganttEstimatedData.splice(issueIdx, 1);
        break;
      }
    }
  },
  SET_GANTT_SHOW_TIME_LINES(state, payload) {
    state.ganttShowTimeLines = payload;
  },
  SET_GANTT_ESTIMATED_DATA(state, payload) {
    state.ganttEstimatedData = payload;
  },
  SET_GANTT_ESTIMATED_DATA_UPDATE(state, payload) {
    let issue = state.ganttEstimatedData.find(function (element) {
      return element.issueId === payload.issueId;
    });
    issue.estimatedDuration = payload.estimatedDuration;
    issue.estimatedOffset = payload.estimatedOffset;
  },
  TOGGLE_SIDEBAR: state => {
    state.app.sidebar.opened = !state.app.sidebar.opened;
    state.app.sidebar.withoutAnimation = false;
    if (state.app.sidebar.opened) {
      localStorage.setItem('sidebarStatus', 'true');
    } else {
      localStorage.setItem('sidebarStatus', 'false');
    }
  },
  CLOSE_SIDEBAR: (state, withoutAnimation) => {
    localStorage.setItem('sidebarStatus', 'true');
    state.app.sidebar.opened = false;
    state.app.sidebar.withoutAnimation = withoutAnimation;
  },
  TOGGLE_DEVICE: (state, device) => {
    state.app.device = device;
  },
  TOGGLE_COLOR: (state, color) => {
    if (state.app.color) {
      localStorage.setItem('appColor', color);
    } else {
      localStorage.setItem('appColor', '#343D48');
    }
    state.app.color = localStorage.getItem('appColor') || '#343D48';
  },
  SET_PINNED_PROJECTS(state, payload) {
    state.pinnedProjects = payload;
  },
  SET_REFRESH_PINNED_PROJECTS(state, payload) {
    state.refreshPinnedProjects = payload;
  },
  SET_MAIN_LOADING(state, payload) {
    state.mainLoading = payload;
  },
  SET_LOGO(state, payload) {
    state.logo = payload;
  },
  SET_SELECTED_COLUMNS(state, payload) {
    state.selectedColumns = payload;
  },
};
