export default {
  searching: '',
  searchClicked: 0,
  currentProjectId: null,
  issueStatusList: [],
  active: {},
  stickyArray: '',
  selectedColumns: [],
  issueFilter: {
    groups: [
      {
        fields: [{
          model: 'toHide',
          type: 'filterStatus',
        }],
      }, {
        fields: [{
          type: 'filterPriority',
          model: 'toHide',
        }],
      }, {
        fields: [{
          type: 'filterAssignTo',
          model: 'toHide',
        }],
      }, {
        fields: [{
          type: 'filterAuthor',
          model: 'toHide',
        }],
      }, {
        fields: [{
          type: 'filterDates',
          model: 'toHide',
        }],
      }, {
        fields: [{
          type: 'filterIssueType',
          model: 'toHide',
        }],
      }, {
        fields: [{
          type: 'filterProject',
          model: 'toHide',
        }],
      },
    ],
    filters: { //disables filter from picking it many times
      'issue_status': false,
      'priority': false,
      'assign_to': false,
      'author': false,
      'dates': false,
      'issue_type': false,
      'project_ids': false,
      // 'subject': false
    },
    selectedValues: { //filter input values
      status: [],
      statusOperator: null,
      priority: null,
      assign_to: null,
      author: null,
      dates: null,
      dateRange: null,
      issue_type: null,
      dateType: null,
      project_ids: null,
      // subject: null
    },
    checkedFilters: { //is filter active
      status: false,
      priority: false,
      assign_to: false,
      author: false,
      dates: false,
      issue_type: false,
      project_ids: false,
      // subject: true
    },
  },
  ganttIssueFilter: {
    groups: [],
    filters: { //disables filter from picking it many times
      'issue_status': false,
      'priority': false,
      'assign_to': false,
      'author': false,
      'dates': false,
      'issue_type': false,
      'project_ids': false,
    },
    selectedValues: { //filter input values
      status: [],
      statusOperator: 'open',
      priority: null,
      assign_to: null,
      author: null,
      dates: null,
      dateRange: null,
      issue_type: null,
      dateType: null,
      dateOperator: null,
      project_ids: null,
    },
    checkedFilters: { //is filter active
      status: true,
      priority: true,
      assign_to: true,
      author: true,
      dates: true,
      issue_type: true,
      project_ids: true,
    },
  },

  systemSetting: {},
  issueId: {},
  getTime: {},
  getRefreshActivity: false,
  // modules: {},
  invoice_items: [],
  group_projects: {
    projects: [],
    roles: [],
  },
  // language: window.$cookies.get('language') || 'pl',
  // locales: window.$cookies.get('language') || 'pl',
  alerts: {},
  filePath: null,

  ganttIssues: {},
  ganttStartDateOfCalendar: '',

  ganttIssueDetailsId: '',
  ganttIssueDetailsProjectId: '',
  ganttIssueDetailsShow: false,
  ganttIssueEditShow: false,

  ganttChangedDate: false,
  arrayMessage: [],
  ganttCellWidth: 150,
  ganttIsDataChanged: false,
  watchers: [],
  uConfig: [],
  cyclicForm: {},
  ganttCalendarStartDate: null,
  ganttCalendarDueDate: null,
  ganttShowTimeLines: true,
  app: {
    sidebar: {
      opened: localStorage.getItem('sidebarStatus') === 'true',
      withoutAnimation: false,
    },
    device: 'desktop',
    color: localStorage.getItem('appColor') || '#343D48',
  },
  pinnedProjects: [],
  refreshPinnedProjects: false,
  mainLoading: false,
  logo: null,
};
