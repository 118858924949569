import VueI18n from '../config/i18n';
import {andGuard} from '../guards/guards';

export default [
  {
    path: '/list-tracker/:idTracker',
    component: () => import('../views/ListTracker/index.vue'),
    name: 'List Tracker',
    props: true,
    beforeEnter: andGuard,
    meta: {
      description: VueI18n.t('system.list-tracker'),
      breadcrumb: [{name: VueI18n.t('system.list-tracker'), title: 'breadcrumb.list-tracker'}],
      permissions: ['issue-list'],
    },
  },
];
