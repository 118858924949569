import i18n from '../config/i18n';

export default {
  methods: {
    changeLanguageMixin(lang) {
      return new Promise((resolve, reject) => {
        this.$store.dispatch('translate/loadLocaleMessage', lang).then((response) => {
          i18n.setLocaleMessage(lang, response);
          i18n.locale = lang;
          this.$vuetify.lang.current = lang;
          // document.title = this.$t('system.login')
          resolve();
        }).catch(function (e) {
          reject(e);
        });
      });
    },
  },
};
