<template>
  <div>
    <div
        class="table-responsive"
        v-if="headers.length >= minHeadersLength && model.length"
    >
      <table
          class="table mb-0"
          v-loading="loading || downloading"
      >
        <thead>
        <tr>
          <transition-group
              name="rowtrans"
              style="display: contents"
              tag="div"
          >
            <th
                :key="header.key"
                :width="header.width"
                @click="checkSort(header) ? sortBy(header.key) : false"
                class="table--th-view"
                v-for="header in headers"
            >
              <slot
                  :header="header"
                  :name="`header.${header.key}`"
              >
                <div
                    :style="[{'cursor': checkSort(header) ? 'pointer' : 'default'}]"
                    class="d-flex th-content"
                >
                  {{ $t(header.label) }}
                  <div
                      class="d-flex flex-column table-sort-icons"
                      v-if="checkSort(header)"
                  >
                    <span v-if="params && header.key == params.column">
                      <font-awesome-icon
                          :class="params.direction == 'asc' ? 'active' : ''"
                          class="caret-sort caret-sort-up"
                          icon="caret-up"
                      />
                      <font-awesome-icon
                          :class="params.direction == 'desc' ? 'active' : ''"
                          class="caret-sort caret-sort-down"
                          icon="caret-down"
                      />
                    </span>
                    <span v-else>
                      <font-awesome-icon
                          class="caret-sort caret-sort-up"
                          icon="caret-up"
                      />
                      <font-awesome-icon
                          class="caret-sort caret-sort-down"
                          icon="caret-down"
                      />
                    </span>
                  </div>
                </div>
              </slot>
            </th>
          </transition-group>
        </tr>
        </thead>
        <tbody
            is="transition-group"
            name="el-fade-in-linear"
        >
        <tr
            :key="idx"
            @click="onRowClick(row)"
            v-for="(row, idx) in model"
        >
          <template v-for="header in headers">
            <td
                :key="header.key"
                :style="{'max-width': header.width || '600px'}"
                class="cursor-pointer table--tbody"
            >
              <!--            <router-link :to="getIssueLink(row['issues.id'])" event="" style="display: contents">-->
              <slot
                  :idx="idx"
                  :name="`item.${header.key}`"
                  :row="row"
              >
                <div
                    :class="`justify-content-${header.justify ? header.justify : 'start'} ${header.bold ? 'font-weight-bold' : ''}`"
                    class="d-flex"
                >
                  <div style="text-overflow: ellipsis; overflow: hidden;">
                    <template v-if="header.key === 'options'">
                      <el-button
                          @click.prevent.stop="unpin(row[idPath])"
                          size="mini"
                          type="danger"
                      >
                        {{ $t('system.unpin') }}
                      </el-button>
                    </template>
                    <template v-else-if="header.type === 'string' || !header.type">
                      <template v-if="row[header.key]">
                        {{ row[header.key] }}
                      </template>
                      <span
                          class="no-data"
                          v-else
                      >{{ $t('system.no_data') }}</span>
                    </template>
                    <template v-else-if="header.type === 'checkbox'">
                      <template v-if="row[header.key]">
                        <el-tag
                            :type="row[header.key] ? 'success' : 'danger'"
                            effect="dark"
                            size="small"
                            style="margin-top: 7px; border-radius: 15px;"
                        >
                          {{ row[header.key] ? $t('system.yes') : $t('system.no') }}
                        </el-tag>
                      </template>
                      <span
                          class="no-data"
                          v-else
                      >{{ $t('system.no_data') }}</span>
                    </template>
                    <template v-else-if="header.type === 'checkboxNot'">
                      <template v-if="row[header.key]">
                        <el-tag
                            :type="!row[header.key] ? 'success' : 'danger'"
                            effect="dark"
                            size="small"
                            style="margin-top: 7px; border-radius: 15px;"
                        >
                          {{ row[header.key] ? $t('system.yes') : $t('system.no') }}
                        </el-tag>
                      </template>
                      <span
                          class="no-data"
                          v-else
                      >{{ $t('system.no_data') }}</span>
                    </template>
                    <template v-else-if="header.type === 'stringWithImage'">
                      <div class="d-flex align-items-center">
                        <div
                            :style="`background-image: url('${getImageFromImagesCollections(header.imgRef.collection, row[header.imgRef.identifier])}');`"
                            class="image-rounded"
                            v-if="getImageFromImagesCollections(header.imgRef.collection, row[header.imgRef.identifier])"
                        />
                        <span
                            class="avatar--ifNotImage"
                            v-else
                        >
                              {{ abbreviation(row[header.key]) }}
                            </span>
                        <span class="ml-2">
                          <template v-if="row[header.key]">
                            {{ row[header.key] }}
                          </template>
                          <span
                              class="no-data"
                              v-else
                          >{{ $t('system.no_data') }}</span>
                        </span>
                      </div>
                    </template>
                    <template v-else-if="header.type === 'timeLogger'">
                      <font-awesome-icon
                          icon="play"
                          v-if="row[header.key].currentState === 'start'"
                      />
                      <font-awesome-icon
                          icon="pause"
                          v-else-if="row[header.key].currentState === 'pause'"
                      />
                      {{ row[header.key].time }}
                    </template>
                    <template v-else-if="header.type === 'files'">
                      <div
                          :key="idx"
                          v-for="(file, idx) in row[header.key]"
                      >
                        <template v-if="file && file.file_name">
                            <span
                                @click.prevent.stop="downloadFile(file, header.key)"
                                class="link-type"
                            >
                              {{ nameWithSize(file.file_name, file.file_size) }}
                            </span>
                        </template>
                        <span
                            class="no-data"
                            v-else
                        >{{ $t('system.no_data') }}</span>
                      </div>
                    </template>
                  </div>
                </div>
              </slot>
              <!--            </router-link>-->
            </td>
          </template>
        </tr>
        </tbody>
      </table>
    </div>
    <div
        v-else
        v-loading="loading"
    >
      <div v-if="headers.length < minHeadersLength">
        <span class="no-date-title">{{ $t('system.no_selected_column') }}</span>
      </div>
      <div v-else-if="!model.length">
        <span class="no-date-title mt-3">{{ $t('system.no_data') }}</span>
      </div>
    </div>
    <div
        class="view mt-3"
        v-if="pagination"
    >
      <div class="pt-0">
        <el-card
            class="pagination-container mt-0"
            shadow="never"
        >
          <el-pagination
              :current-page="params.page"
              :page-size="params.per_page"
              :page-sizes="params.per_page_sizes"
              :total="params.total"
              @current-change="handleCurrentChange"
              @size-change="handleSizeChange"
              background
              layout="total, sizes, prev, pager, next, jumper"
          >
          </el-pagination>
        </el-card>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import {abbreviation, getObjectValueByPath} from '../../helpers/functions';
import notification from '../../notification/notify';

export default {
  name: 'DataTable',
  props: {
    headers: {
      type: Array,
      required: true,
    },
    model: {
      type: Array,
      required: true,
    },
    minHeadersLength: {
      type: Number,
      default: 1,
    },
    idPath: {
      type: String,
      default: 'id',
    },
    params: {
      type: Object,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    imagesCollections: {
      type: Object,
      default: () => {
      },
    },
    pagination: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      downloading: false,
    };
  },
  methods: {
    abbreviation(str) {
      return abbreviation(str);
    },
    getId(row) {
      return getObjectValueByPath(this.idPath, row, false);
    },
    getImageFromImagesCollections(collection, itemId) {
      if (this.imagesCollections[collection]) {
        const candidate = this.imagesCollections[collection].find(item => item.id === itemId);
        if (candidate) {
          return candidate.img;
        }
      }
      return null;
    },
    downloadFile(file, headerKey) {
      if (file.id) {
        this.downloading = true;
        axios.get(`${this.$route.params.moduleName || 'crm'}/attachment/${file.id}/${headerKey.split('.')[headerKey.split('.').length - 1]}/download`, {
          responseType: 'blob',
          onDownloadProgress: (progressEvent) => {
            this.downloadProgressPercent = Math.round((progressEvent.loaded * 100) / file.filesize);
          },
        }).then((response) => {
          const link = document.createElement('a');
          document.body.appendChild(link);
          link.style = 'display: none';
          const url = window.URL.createObjectURL(new Blob([response.data]));
          link.href = url;
          link.download = file.name;
          // window.URL.revokeObjectURL(url);
          setTimeout(() => {
            link.click();
            this.downloading = false;
            this.downloadProgressPercent = 0;
          }, 500);
        })
            .catch((error) => {
              setTimeout(() => {
                this.downloading = false;
                this.downloadProgressPercent = 0;
              }, 500);
              notification.notify(
                  this.$t('notify.error'),
                  this.$t(error.response.data.message),
                  'error');
            });
      }
    },
    nameWithSize(fileName, fileSize) {
      return fileName + ' (' + this.formatBytes(fileSize) + ')';
    },
    formatBytes(bytes, decimals = 2) {
      if (bytes === 0) return '0 Bytes';

      const k = 1024;
      const dm = decimals < 0 ? 0 : decimals;
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

      const i = Math.floor(Math.log(bytes) / Math.log(k));

      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    },
    unpin(id) {
      this.$emit('unpin', id);
    },
    onRowClick(row) {
      this.$emit('clickRow', this.getId(row), row);
    },
    handleSizeChange(val) {
      this.params.per_page = val;
      this.getFreshData();
    },
    handleCurrentChange(val) {
      this.params.page = val;
      this.getFreshData();
    },
    checkSort(header) {
      return header.sort !== false;
    },
    getFreshData() {
      this.$emit('updateData');
    },
    sortBy(column) {
      if (column === this.params.column) {
        if (this.params.direction === 'desc') {
          this.params.direction = 'asc';
        } else {
          this.params.direction = 'desc';
        }
      } else {
        this.params.column = column;
        this.params.direction = 'asc';
      }
      this.getFreshData();
    },
  },
};
</script>

<style
    lang="scss"
    rel="stylesheet/scss"
    scoped
>
@import '../../static/css/table.scss';

.table {
  th {
    padding: 0;

    .th-content {
      padding: 0.75rem;
    }
  }
}

.table--threeDots-forOptions:after {
  content: '\2807';
  font-size: 20px;
}

.image-rounded {
  border-radius: 50%;
  width: 25px;
  height: 25px;
  background-size: cover;
  background-position: top center;
}

</style>
