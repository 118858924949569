export default {
  // data() {
  // return {
  //   inputRequiredEmpty: []
  // }
  // },
  methods: {
    checkValidation(requiredInputs = []) {
      return new Promise((resolve, reject) => {
        // this.backend_errors = {}
        this.inputRequiredEmpty = requiredInputs;
        this.getinputRequired();
        if (this.inputRequiredEmpty.length !== 0) {
          reject(new Error('Required field is empty'));
        } else if (this.checkInputPatternValidation() !== 0) {
          reject(new Error('Field value is invalid'));
        } else {
          resolve();
        }
      });
    },
    checkinputRequired(name) {
      return this.inputRequiredEmpty.includes(name);
    },
    getinputRequired() {
      const inputs = document.querySelectorAll('.is-required .el-input__inner, .is-required .el-textarea__inner');
      inputs.forEach((item) => {
        if (item.value.trim().length === 0) {
          // if (!item.parentNode.parentNode.getAttribute('value')) {
          this.inputRequiredEmpty.push(item.name);
          // }
        }
      });
    },
    checkInputPatternValidation() {
      const inputs = document.querySelectorAll('.pattern-validation .el-input__inner, .pattern-validation .el-textarea__inner');
      let errors = 0;
      inputs.forEach((item) => {
        if (item.pattern) {
          const pattern = new RegExp(item.pattern, 'igm');
          if (!pattern.test(item.value)) errors++;
        }
      });
      return errors;
    },
    validationTranslate(str) {
      let string = '';
      const arr = str.match(/(?!\{)(.*?)(?=\})|(\w+)/g);
      arr.forEach(val => {
        string += this.$t(val) + ' ';
      });
      return string.trim();
    },
  },
};
