import VueI18n from '../config/i18n';
// import ActiveClients from '../views/ActiveClients/Index.vue'
import {andGuard} from '../guards/guards';

export default [
  {
    path: '/active_clients',
    component: () => import('../views/ActiveClients/Index.vue'),
    beforeEnter: andGuard,
    name: 'ActiveClients',
    meta: {
      description: VueI18n.t('system.activeclients'),
      breadcrumb: [{name: 'ActiveClients', title: 'breadcrumb.active_clients'}],
      permissions: ['active-clients-read'],
      module: 'spain',
    },
  },
];
