<template>
  <div class="row">
    <div class="col-sm-5">
      <DataTable
          :headers="headers"
          :loading="freezeTable"
          :model="data"
          :params="params"
          @clickRow="setCurrentActivity"
      />
      <el-button
          @click="allActivities"
          class="mt-3"
          size="mini"
          type="primary"
      >
        {{ $t('system.all_activities') }}
      </el-button>
    </div>
    <div class="col-sm-7">
      <l-map
          :center.sync="currentActivity.coordinates"
          :min-zoom="2"
          :zoom.sync="zoom"
          ref="map"
          style="min-height: 600px"
      >
        <l-tile-layer url="https://api.maptiler.com/maps/basic/{z}/{x}/{y}.png?key=cSJ7m9qsgRjbKQInLyYn" />
        <v-marker-cluster>
          <l-marker
              :key="`start_${activity.id}`"
              :lat-lng="activity.coordinatesStart"
              v-for="activity in currentData"
          >
            <l-icon
                :icon-anchor="[0, 24]"
                :icon-url="''"
                :label-anchor="[-6, 0]"
                :popup-anchor="[0, -36]"
            >
              <span class='green-map-icon' />
            </l-icon>
          </l-marker>
          <l-marker
              :key="`end_${activity.id}`"
              :lat-lng="activity.coordinatesEnd"
              v-for="activity in currentData"
          >
            <l-icon
                :icon-anchor="[0, 24]"
                :icon-url="''"
                :label-anchor="[-6, 0]"
                :popup-anchor="[0, -36]"
            >
              <span class='red-map-icon' />
            </l-icon>
          </l-marker>
        </v-marker-cluster>
      </l-map>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import {LIcon, LMap, LMarker, LTileLayer} from 'vue2-leaflet';
import VMarkerCluster from 'vue2-leaflet-markercluster';
import 'leaflet/dist/leaflet.css';
import DataTable from '../Table/DataTable.vue';
import notify from '../../notification/notify';

export default {
  name: 'MapActivities',
  components: {
    DataTable,
    LMap,
    LTileLayer,
    LMarker,
    LIcon,
    VMarkerCluster,
  },
  props: {
    userId: {
      type: [Number, String],
      required: true,
    },
  },
  data: () => ({
    headers: [
      {
        key: 'id',
        label: 'system.id',
      },
      {
        key: 'start_at',
        label: 'system.start_at',
      },
      {
        key: 'end_at',
        label: 'system.end_at',
      },
    ],
    data: [],
    params: {
      column: 'id',
      direction: 'desc',
      per_page: 10,
      page: 1,
      total: 0,
    },
    currentActivity: {
      coordinates: {
        lat: 50,
        lng: 10,
      },
    },
    currentData: [],
    freezeTable: false,
    zoom: 2,
  }),
  mounted() {
    this.fetchData();
  },
  watch: {
    params: {
      handler() {
        this.fetchData();
      },
    },
  },
  methods: {
    async fetchData() {
      try {
        const {data} = await axios.get(`workorders/employee/${this.userId}/logs`, {params: this.params});
        this.data = data.data.map(item => ({
          ...item,
          coordinatesStart: {
            lat: item.start_lat,
            lng: item.start_long,
          },
          coordinatesEnd: {
            lat: item.end_lat,
            lng: item.end_long,
          },
        }));
        this.currentData = this.data;
        const intr = setInterval(() => {
          this.calcCenterLocation(this.data);
          this.calcCenterLocation(this.data);
          clearInterval(intr);
        }, 100);
        this.params.total = data.total;
      } catch (e) {
        notify(
            this.$t('notify.error'),
            this.$t('system.can_not_load'),
            'error');
      }
    },
    setCurrentActivity(id) {
      if (!this.freezeTable) {
        const activity = {...this.data.find(item => item.id === id)};
        this.freezeTable = true;
        const intr = setInterval(() => {
          this.calcCenterLocation([activity]);
          this.calcCenterLocation([activity]);
          this.currentData = [activity];
          this.freezeTable = false;
          clearInterval(intr);
        }, 100);
      }
    },
    calcCenterLocation(data) {
      // TODO: don't judge me :) this is a temporary solution. We should refactor, or better, use methods from the map library.
      if (!data.length) {
        return;
      }
      let lng = 0, lat = 0, i = 1,
          minLng = data[0].coordinatesStart.lng, maxLng = data[0].coordinatesStart.lng,
          minLat = data[0].coordinatesStart.lat, maxLat = data[0].coordinatesStart.lat;
      for (i; i <= data.length; i++) {

        if (!data[i - 1].coordinatesStart.lng) data[i - 1].coordinatesStart.lng = 0;
        if (!data[i - 1].coordinatesStart.lat) data[i - 1].coordinatesStart.lat = 0;
        if (!data[i - 1].coordinatesEnd.lng) data[i - 1].coordinatesEnd.lng = 0;
        if (!data[i - 1].coordinatesEnd.lat) data[i - 1].coordinatesEnd.lat = 0;

        if (minLng > data[i - 1].coordinatesStart.lng) minLng = data[i - 1].coordinatesStart.lng;
        if (minLng > data[i - 1].coordinatesEnd.lng) minLng = data[i - 1].coordinatesEnd.lng;
        if (maxLng < data[i - 1].coordinatesStart.lng) maxLng = data[i - 1].coordinatesStart.lng;
        if (maxLng < data[i - 1].coordinatesEnd.lng) maxLng = data[i - 1].coordinatesEnd.lng;
        if (minLat > data[i - 1].coordinatesStart.lat) minLat = data[i - 1].coordinatesStart.lat;
        if (minLat > data[i - 1].coordinatesEnd.lat) minLat = data[i - 1].coordinatesEnd.lat;
        if (maxLat < data[i - 1].coordinatesStart.lat) maxLat = data[i - 1].coordinatesStart.lat;
        if (maxLat < data[i - 1].coordinatesEnd.lat) maxLat = data[i - 1].coordinatesEnd.lat;

        this.calcZoomLevel(parseFloat(minLng) - parseFloat(maxLng), parseFloat(minLat) - parseFloat(maxLat));

        lng += parseFloat(data[i - 1].coordinatesStart.lng) + parseFloat(data[i - 1].coordinatesEnd.lng);
        lat += parseFloat(data[i - 1].coordinatesStart.lat) + parseFloat(data[i - 1].coordinatesEnd.lat);
      }
      lng = lng / i;
      lat = lat / i;

      this.currentActivity.coordinates = {
        lng: Math.round(lng * 1000) / 1000,
        lat: Math.round(lat * 1000) / 1000,
      };

      const intr = setInterval(() => {
        this.$refs.map.setCenter({
          lng: Math.round(lng * 1000) / 1000,
          lat: Math.round(lat * 1000) / 1000,
        });
        clearInterval(intr);
      }, 500);
    },
    calcZoomLevel(lngDistance, latDistance) {
      let lngDistancePercent, latDistancePercent, maxDistance;

      // eslint-disable-next-line no-param-reassign
      if (lngDistance < 0) lngDistance *= -1;
      // eslint-disable-next-line no-param-reassign
      if (latDistance < 0) latDistance *= -1;

      lngDistancePercent = Math.round((lngDistance * 100 / 360) * 1000) / 1000;
      latDistancePercent = Math.round((latDistance * 100 / 180) * 1000) / 1000;

      if (lngDistancePercent > latDistancePercent) maxDistance = lngDistancePercent;
      else maxDistance = latDistancePercent;

      switch (true) {
        case (maxDistance < 0.01):
          this.zoom = 16;
          break;
        case (maxDistance < 0.5):
          this.zoom = 14;
          break;
        case (maxDistance < 1):
          this.zoom = 12;
          break;
        case (maxDistance < 1.5):
          this.zoom = 10;
          break;
        case (maxDistance < 4):
          this.zoom = 8;
          break;
        case (maxDistance < 10):
          this.zoom = 7;
          break;
        case (maxDistance < 16):
          this.zoom = 6;
          break;
        case (maxDistance < 20):
          this.zoom = 5;
          break;
        case (maxDistance < 24):
          this.zoom = 3;
          break;
        case (maxDistance < 40):
          this.zoom = 2;
          break;
        case (maxDistance < 60):
          this.zoom = 1;
          break;
      }
    },
    allActivities() {
      this.currentData = this.data;
      const intr = setInterval(() => {
        this.$refs.map.setCenter(this.calcCenterLocation(this.data));
        this.currentActivity.coordinates = this.calcCenterLocation(this.data);
        clearInterval(intr);
      }, 350);
    },
  },
};
</script>

<style
    lang="scss"
    scoped
>
@import '~leaflet.markercluster/dist/MarkerCluster.css';
@import '~leaflet.markercluster/dist/MarkerCluster.Default.css';
</style>
