<template>
  <el-input
      :value="value"
      @blur="$emit('blur')"
      @focus="$emit('focus')"
      @input="onSearchChange"
      @keyup.enter.native="emit"
      v-bind="$attrs"
  />
</template>

<script>
export default {
  name: 'DebounceInput',
  props: {
    delay: {
      type: Number,
      default: 350,
    },
    value: {
      type: [String, Number],
    },
  },
  data() {
    return {
      debounce: null,
    };
  },
  methods: {
    emit() {
      this.$emit('change');
    },
    onSearchChange(value) {
      this.$emit('input', value);
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.emit();
      }, this.delay);
    },
  },
};
</script>

<style scoped>

</style>
