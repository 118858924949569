const version = {
  state: {
    back: '',
    front: '',
  },
  mutations: {
    ADD_VERSION_BACK: (state, value) => {
      state.back = value;
    },
    ADD_VERSION_FRONT: (state, value) => {
      state.front = value;
    },
  },
  actions: {
    addVersionBack({commit}, value) {
      commit('ADD_VERSION_BACK', value);
    },
    addVersionFront({commit}, value) {
      commit('ADD_VERSION_FRONT', value);
    },
  },
};

export default version;
