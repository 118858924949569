export default {
  namespaced: true,
  state: {
    dateRange: [new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1), new Date()],
  },
  getters: {
    //
  },
  mutations: {
    SET_DATE_RANGE(state, payload) {
      state.dateRange = payload;
    },
  },
  actions: {
    setDateRange({commit}, payload) {
      commit('SET_DATE_RANGE', payload);
    },
  },
};
