<template>
  <div class="view-container">
    <div class="view">
      <div class="view-body">
        <router-link
            :to="create"
            v-if="ifHasPermission(['add-role'])"
        >
          <el-button type="primary">{{ $t('system.create') }}</el-button>
        </router-link>
      </div>
    </div>
    <div class="view">
      <div class="view-body">
        <static-table
            :data="model"
            :options="options"
            :params="params"
            :thead="thead"
            @sortChange="sortChange"
        ></static-table>
        <el-card
            class="pagination-container mt-3"
            shadow="never"
        >
          <el-pagination
              :current-page="params.page"
              :page-size="params.per_page"
              :page-sizes="[10, 30, 50, 100, 300]"
              :total="total"
              @current-change="handleCurrentChange"
              @size-change="handleSizeChange"
              background
              layout="total, sizes, prev, pager, next, jumper"
          >
          </el-pagination>
        </el-card>
        <!--<ab-table :source="source" :thead="thead" :options="options"></ab-table>-->
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
// import ABTable from '../../components/ABtable/index.vue';
import StaticTable from '../../components/ABtable/StaticTable.vue';

export default {
  name: 'RoleIndex',
  components: {
    // 'ab-table': ABTable
    StaticTable,
  },
  data() {
    return {
      create: '/administration/role/create',
      source: '/role',
      thead: [
        {
          label: this.$t('system.number'),
          key: 'id',
          sort: true,
          link: true,
          align: 'abTable--alignCenter',
          url: '/administration/role/',
        },
        {
          label: this.$t('system.name'),
          key: 'name',
          sort: true,
          link: true,
          url: '/administration/role/',
        },
        // {
        //   label: this.$t('system.created_at'),
        //   key: 'created_at',
        //   sort: true
        // }
      ],
      options: {
        paginate: true,
        // row_click: true,
        // row_link: '/administration/role'
      },
      params: {
        current_page: '',
        from: '',
        to: '',
        last_page: '',
        column: 'id',
        direction: 'desc',
        per_page: 10,
        page: 1,
      },
      model: [],
      total: 0,
    };
  },
  beforeMount() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      var vm = this;
      vm.url = this.buildURL();
      await axios.get(vm.url)
          .then(function (response) {
            vm.model = response.data.data;
            if (typeof response.data.total !== 'undefined') {
              vm.total = response.data.total;
            } else {
              if (vm.options.paginate) {
                vm.params.current_page = parseInt(response.data.meta.current_page);
                vm.params.from = parseInt(response.data.meta.from);
                vm.params.last_page = parseInt(response.data.meta.last_page);
                vm.params.per_page = parseInt(response.data.meta.per_page);
                vm.params.to = parseInt(response.data.meta.to);
                vm.total = parseInt(response.data.meta.total);
              }
            }
            vm.newList = vm.model.slice();
          })
          .catch(function () {
          });
    },
    buildURL() {
      const p = this.params;
      if (this.options.paginate) {
        return `${this.source}?column=${p.column}&direction=${p.direction}&per_page=${p.per_page}&page=${p.page}`;
      } else {
        return `${this.source}?column=${p.column}&direction=${p.direction}`;
      }
    },
    handleCurrentChange(val) {
      this.params.page = val;
      this.fetchData();
    },
    handleSizeChange(val) {
      this.params.per_page = val;
      this.fetchData();
    },
    next() {
      if (this.model.next_page_url) {
        this.params.page++;
        this.fetchData();
      }
    },
    prev() {
      if (this.model.prev_page_url) {
        this.params.page--;
        this.fetchData();
      }
    },
    sortChange(column) {
      if (column === this.params.column) {
        if (this.params.direction === 'desc') {
          this.params.direction = 'asc';
        } else {
          this.params.direction = 'desc';
        }
      } else {
        this.params.column = column;
        this.params.direction = 'asc';
      }
      this.fetchData();
    },
  },
};
</script>
