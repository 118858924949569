<template>
  <div class="view-container">
    <el-form
        :model="form"
        class="el-table--customStyle"
        ref="form"
    >
      <div class="row">
        <div class="col-8">
          <div class="view">
            <div class="view-heading">
              <div class="view-title">
                {{ $t('users.information') }}
              </div>
            </div>
            <div class="view-body">
              <el-form-item
                  :label="$t('users.username')"
                  required
              >
                <el-input
                    :class="checkinputRequired('username') || backend_errors.username ? 'validate-error' : ''"
                    name="username"
                    v-model="form.username"
                />
                <small
                    class="help-block"
                    v-if="backend_errors.username"
                >
                  <span v-html="validationTranslate(backend_errors.username[0])" />
                </small>
              </el-form-item>
              <el-form-item
                  :label="$t('users.first_name')"
                  required
              >
                <el-input
                    :class="checkinputRequired('first_name') || backend_errors.first_name ? 'validate-error' : ''"
                    name="first_name"
                    v-model="form.first_name"
                />
                <small
                    class="help-block"
                    v-if="backend_errors.first_name"
                >
                  <span v-html="validationTranslate(backend_errors.first_name[0])" />
                </small>
              </el-form-item>
              <el-form-item
                  :label="$t('users.last_name')"
                  required
              >
                <el-input
                    :class="checkinputRequired('last_name') || backend_errors.last_name ? 'validate-error' : ''"
                    name="last_name"
                    v-model="form.last_name"
                />
                <small
                    class="help-block"
                    v-if="backend_errors.last_name"
                >
                  <span v-html="validationTranslate(backend_errors.last_name[0])" />
                </small>
              </el-form-item>
              <el-form-item
                  :label="$t('users.email')"
                  required
              >
                <el-input
                    :class="checkinputRequired('email') || backend_errors.email ? 'validate-error' : ''"
                    name="email"
                    v-model="form.email"
                />
                <small
                    class="help-block"
                    v-if="backend_errors.email"
                >
                  <span v-html="validationTranslate(backend_errors.email[0])" />
                </small>
              </el-form-item>
              <el-form-item
                  :label="$t('users.phone')"
                  required
              >
                <el-input
                    :class="checkinputRequired('phone') || backend_errors.phone ? 'validate-error' : ''"
                    name="phone"
                    v-model="form.phone"
                />
                <small
                    class="help-block"
                    v-if="backend_errors.phone"
                >
                  <span v-html="validationTranslate(backend_errors.phone[0])" />
                </small>
              </el-form-item>
              <el-form-item
                  :label="$t('users.gender')"
                  required
              >
                <el-select
                    :class="checkinputRequired('gender') || backend_errors.gender ? 'validate-error' : ''"
                    filterable
                    name="gender"
                    v-model="form.gender"
                >
                  <el-option
                      :label="$t('system.male')"
                      value="male"
                  />
                  <el-option
                      :label="$t('system.female')"
                      value="female"
                  />
                </el-select>
                <small
                    class="help-block"
                    v-if="backend_errors.gender"
                >
                  <span v-html="validationTranslate(backend_errors.gender[0])" />
                </small>
              </el-form-item>
              <el-form-item
                  :label="$t('users.full_time')"
                  required
              >
                <el-switch
                    :active-text="$t('system.yes')"
                    :inactive-text="$t('system.no')"
                    :label="$t('users.full_time')"
                    active-color="#13ce66"
                    class="ml-2 switch--color-bool-text"
                    inactive-color="#ff4949"
                    name="full_time"
                    v-model="form.full_time"
                />
              </el-form-item>
              <el-form-item
                  :label="$t('users.department')"
                  required
              >
                <el-input
                    :class="checkinputRequired('department') || backend_errors.department ? 'validate-error' : ''"
                    name="department"
                    v-model="form.department"
                />
                <small
                    class="help-block"
                    v-if="backend_errors.department"
                >
                  <span v-html="validationTranslate(backend_errors.department[0])" />
                </small>
              </el-form-item>
              <el-form-item
                  :label="$t('system.admin')"
                  required
              >
                <el-switch
                    :active-text="$t('system.yes')"
                    :inactive-text="$t('system.no')"
                    :label="$t('users.admin')"
                    active-color="#13ce66"
                    class="ml-2 switch--color-bool-text"
                    inactive-color="#ff4949"
                    name="admin"
                    v-model="form.admin"
                />
              </el-form-item>
              <el-form-item
                  :label="$t('system.subcontractor')"
                  required
              >
                <el-switch
                    :active-text="$t('system.yes')"
                    :inactive-text="$t('system.no')"
                    :label="$t('users.subcontractor')"
                    active-color="#13ce66"
                    class="ml-2 switch--color-bool-text"
                    inactive-color="#ff4949"
                    name="subcontractor"
                    v-model="form.subcontractor"
                />
              </el-form-item>
              <!--              <el-form-item>-->
              <!--                <el-checkbox v-model="form.client" :label="$t('users.client')" name="client"/>-->
              <!--              </el-form-item>-->
            </div>
          </div>
        </div>
        <div class="col-4">
          <div class="view">
            <div class="view-heading">
              <div class="view-title">
                {{ $t('users.autorization') }}
              </div>
            </div>
            <div class="view-body">
              <el-form-item
                  :label="$t('users.password')"
                  :required="!isEditMode"
              >
                <el-input
                    :class="checkinputRequired('password') || backend_errors.password ? 'validate-error' : ''"
                    autocomplete="off"
                    name="password"
                    type="password"
                    v-model="form.password"
                />
                <small
                    class="help-block"
                    v-if="backend_errors.password"
                >
                  <span v-html="validationTranslate(backend_errors.password[0])" />
                </small>
              </el-form-item>
              <el-form-item
                  :label="$t('users.password_confirmation')"
                  :required="!isEditMode"
              >
                <el-input
                    :class="checkinputRequired('password_confirmation') || backend_errors.password_confirmation ? 'validate-error' : ''"
                    autocomplete="off"
                    name="password_confirmation"
                    type="password"
                    v-model="form.password_confirmation"
                />
                <small
                    class="help-block"
                    v-if="backend_errors.password_confirmation"
                >
                  <span v-html="validationTranslate(backend_errors.password_confirmation[0])" />
                </small>
              </el-form-item>
              <el-form-item
                  :label="$t('system.role')"
                  required
              >
                <role-select
                    :class="checkinputRequired('role_id') || (backend_errors.role_id && backend_errors.role_id[0]) ? 'validate-error' : ''"
                    :role="form.role_id"
                    v-on:selectedUpdated="rolesSelectUpdated"
                />
                <small
                    class="help-block"
                    v-if="backend_errors.role_id"
                >
                  <span v-html="validationTranslate(backend_errors.role_id[0])" />
                </small>
              </el-form-item>
              <el-form-item
                  :label="$t('system.contractor')"
              >
                <el-select
                    filterable
                    clearable
                    v-model="form.account_id"
                >
                  <el-option
                      :label="contractor.name"
                      :value="contractor.id"
                      v-bind:key="`contractor_${index}`"
                      v-for="(contractor, index) in contractors"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="view">
            <div class="view-body">
              <el-form-item>
                <div class="box-button p-0">
                  <el-button
                      @click.prevent.stop="save"
                      type="primary"
                  >
                    {{ $t('system.save') }}
                  </el-button>
                  <el-button @click.prevent.stop="backTo">
                    {{ $t('system.cancel') }}
                  </el-button>
                </div>
              </el-form-item>
            </div>
          </div>
        </div>
      </div>
    </el-form>
  </div>
</template>

<script>
import _ from 'lodash';
import axios from 'axios';
import RoleSelect from '../Role/Select.vue';
import notification from '../../notification/notify';

export default {
  name: 'UserForm',
  components: {
    'role-select': RoleSelect,
  },
  computed: {
    first_name: {
      get: function () {
        return this.form.first_name;
      },
      set: function (newValue) {
        this.form.first_name = newValue;
      },
    },
    last_name: {
      get: function () {
        return this.form.last_name;
      },
      set: function (newValue) {
        this.form.last_name = newValue;
      },
    },
    isEditMode: function () {
      return this.$route.meta.mode === 'edit';
    },
  },
  data() {
    return {
      form: {
        role_id: null,
      },
      inputRequiredEmpty: [],
      user_detail: {},
      address: {},
      backend_errors: {},
      option: {},
      title: 'Create',
      initialize: 'user/create',
      redirect: '/administration/user',
      store: 'user',
      method: 'post',
      contractors: [],
    };
  },
  beforeMount() {
    if (this.$route.meta.mode === 'edit') {
      this.title = 'Edit';
      this.initialize = 'user/' + this.$route.params.id + '/edit';
      this.store = 'user/' + this.$route.params.id;
      this.method = 'put';
    }
    this.fetchData();
    this.fetchContractors();
  },
  watch: {
    '$route': 'fetchData',
    'form.phone'(newVal, oldVal) {
      if (newVal && !/^[0-9., +\-\\)\\(]*$/.test(newVal)) {
        this.form.phone = oldVal;
      }
    },
  },
  methods: {
    backTo: function () {
      window.history.back();
    },

    fetchData() {
      axios.get(this.initialize)
          .then((response) => {
            if (response.data.form == null) {
              notification.notify(
                  this.$t('notify.error'),
                  this.$t(response.data.message),
                  'error');
              this.$router.push(this.redirect);
            } else {
              // Vue.set(this.$data, 'form', response.data.form);
              response.data.form.admin = !!response.data.form.admin;
              response.data.form.subcontractor = !!response.data.form.subcontractor;
              response.data.form.user_detail.full_time = !!response.data.form.user_detail.full_time;
              response.data.form.account_id = this.assignAccountId(response.data.form);
              this.form = {...response.data.form, ...response.data.form.user_detail};
              if (typeof response.data.form.roles !== 'undefined' && response.data.form.roles.length) {
                this.form.role_id = _.first(response.data.form.roles).id;
              }
              // Vue.set(vm.$data, 'user_detail', response.data.form.user_detail);
              this.address = response.data.form.address;
              this.option = response.data.option;
            }
          })
          .catch(function (error) {
            notification.notify(
                this.$t('notify.error'),
                this.$t(error.message),
                'error');
          });
    },

    assignAccountId(form) {
      if (form.account && form.account.length && form.account[0].id) {
        return form.account[0].id;
      } else {
        return null;
      }
    },

    async fetchContractors() {
      try {
        const {data} = await axios.get(`aberit_module_generator/get_dictionary_data?dictionary_name=account`);
        this.contractors = data;
      } catch (e) {
        notification
            .notify(
                this.$t('notify.error'),
                this.$t(e.message),
                'error',
            );
      }
    },

    scrollTop() {
      let top = document.body.getElementsByClassName('app-wrapper')[0];
      let scrollStep = -top.scrollTop / (500 / 15);
      let scrollInterval = setInterval(function () {
        if (top.scrollTop !== 0) {
          top.scrollBy(0, scrollStep);
        } else {
          clearInterval(scrollInterval);
        }
      }, 15);
    },

    save() {
      var vm = this;

      vm.checkValidation().then(function () {
        const password = document.querySelector('input[name=password]').value;
        const password_confirmation = document.querySelector('input[name=password_confirmation]').value;
        if (!(_.isEqual(password, password_confirmation))) {
          vm.scrollTop();
          vm.backend_errors = {
            password_confirmation: [vm.$t('validation.pass_diff')],
          };
        } else {
          var form = vm.form;
          form.user_detail = vm.user_detail;
          form.full_time = !!form.full_time;
          form.address = vm.address;
          if (!form.account_id) {
            form.account_id = null;
          }
          axios[vm.method](vm.store, form)
              .then(function (response) {
                if (response.data.saved) {
                  notification.notify(
                      vm.$t('notify.success'),
                      vm.$t(response.data.message),
                      'success');
                  vm.$router.push(vm.redirect);
                } else {
                  vm.scrollTop();
                  notification.notify(
                      vm.$t('notify.error'),
                      vm.$t(response.data.message),
                      'error');
                }
              })
              .catch(function (error) {
                vm.scrollTop();
                vm.backend_errors = error.response.data.errors;
              });
        }
      }).catch(function () {
        console.error('Validation error');
      });
    },

    rolesSelectUpdated(roleId) {
      this.form.role_id = roleId;
    },
  },
};
</script>
