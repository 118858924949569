export function isObjectsSame(a, b) {
  // Create arrays of property names
  const aProps = Object.getOwnPropertyNames(a);
  const bProps = Object.getOwnPropertyNames(b);

  // If number of properties is different,
  // objects are not equivalent
  if (aProps.length !== bProps.length) {
    return false;
  }

  for (let i = 0; i < aProps.length; i++) {
    const propName = aProps[i];

    // If values of same property are not equal,
    // objects are not equivalent
    if (a[propName] !== b[propName]) {
      return false;
    }
  }

  // If we made it this far, objects
  // are considered equivalent
  return true;
}

export function getObjectValueByPath(path, obj, separator = '.') {
  if (obj) {
    return path.split(separator).reduce((o, i) => o[i], obj);
  }
  return null;
}

export function camelCaseToUnderscoreCase(str) {
  return str.replace(/(?:^|\.?)([A-Z])/g, (x, y) => '_' + y.toLowerCase()).replace(/^_/, '');
}

export function underscoreCaseToCamelCase(str) {
  return str.replace(/([-_][a-z])/g, (group) => group.toUpperCase()
    .replace('-', '')
    .replace('_', ''),
  );
}

export function abbreviation(str) {
  if (str) {
    const [first, second] = str.trim().split(' ');
    return `${first.charAt(0)}${second ? second.charAt(0) : ''}`.toUpperCase();
  }
  return '';
}

export function saveFile(response, filename) {
  const blob = new Blob([response.data], {type: response.headers['content-type']});
  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  link.download = filename;
  link.click();
}

export function clone(val) {
  return JSON.parse(JSON.stringify(val));
}

export function getDaysInMonth(month, year) {
  return new Date(year, month, 0).getDate();
}

export function mapObjToObj(targetObj, sourceObj) {
  const target = clone(targetObj);
  const source = clone(sourceObj);
  Object.keys(target).forEach(key => {
    if (key in source) {
      target[key] = source[key];
    }
  });
  if (sourceObj.custom.state) {
    target.state = sourceObj.custom.state;
  }
  return target;
}

export const capitalize = (s) => {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
};
