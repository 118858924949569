import VueI18n from '../config/i18n';
import {orGuard} from '../guards/guards';
// import GlobalSearch from '../views/GlobalSearch/Index.vue'

export default [
  {
    path: '/search',
    component: () => import('../views/GlobalSearch/Index.vue'),
    name: 'Search',
    beforeEnter: orGuard,
    meta: {
      permissions: ['project-list', 'issue-list', 'payroll-all', 'payroll-own'],
      description: VueI18n.t('system.search'),
      breadcrumb: [{name: VueI18n.t('system.search'), title: ''}],
    },
  },
];
