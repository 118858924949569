import axios from 'axios';
import moment from 'moment';

const report = {
  namespaced: true,
  state: {
    model: {},
    checked: [],
    mode: {
      type: '',
      mode: '',
    },
    thead: [],
    extension: '',
    checkedShow: true,
  },
  getters: {
    report(state) {
      return state.model;
    },
    checked(state) {
      return state.checked;
    },
    mode(state) {
      return state.mode;
    },
    get_column_name(state) {
      return state.thead;
    },
    getExtension(state) {
      return state.extension;
    },
    checkedShow(state) {
      return state.checkedShow;
    },
  },
  mutations: {
    ADD_CHECKED: (state, checked) => {
      state.checked = checked;
    },
    CLEAR_CHECKED: (state) => {
      state.checked = [];
    },
    CHECKED_SHOW: (state, value) => {
      state.checkedShow = value;
    },
    MODE: (state, mode) => {
      state.mode = mode;
    },
    SAVE_COLUMN_NAME: (state, thead) => {
      state.thead = thead;
    },
    SET_EXTENSION: (state, extension) => {
      state.extension = extension;
    },
  },
  actions: {
    downloadReportPost: function ({commit, getters}, data) {
      return new Promise(resolve => {
        let projectName = data.projectName.replace('.', '_');
        const a = document.createElement('a');
        document.body.appendChild(a);
        a.style = 'display: none';
        let blob = new Blob([data.response.data], {type: data.response.headers['content-type']});
        let extension = getters.getExtension;
        let filename = projectName + '-' + moment().format('DD/MM/YYYY-HH/mm/ss') + '.' + extension;
        let url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = filename;
        a.click();
        window.URL.revokeObjectURL(url);
        resolve();
      });
    },
    generatePost: function ({dispatch, commit, getters}, {projectId, params, extension}) {
      return new Promise((resolve, reject) => {
        let config = {
          responseType: 'blob',
        };
        axios.post(`report/${projectId}/generate`, {
          ...params,
        }, config)
          .then(function (response) {
            resolve(response, extension);
          }).catch(function (error) {
          reject(error);
        });
      });
    },
    clearChecked: function ({dispatch, commit, getters}, checked) {
      return new Promise(resolve => {
        commit('ADD_CHECKED');
        resolve();
      });
    },
    addChecked: function ({dispatch, commit, getters}, checked) {
      return new Promise(resolve => {
        commit('ADD_CHECKED', checked);
        if (getters.mode.mode.type === 'internal') {
          if (getters.mode.mode.mode === 'not_settled') {
            commit('internal/ADD_NOT_SETTLED', checked);
          } else if (getters.mode.mode.mode === 'settled') {
            commit('internal/ADD_SETTLED', checked);
          }
        } else if (getters.mode.mode.type === 'client') {
          if (getters.mode.mode.mode === 'not_settled') {
            commit('client/ADD_NOT_SETTLED', checked);
          } else if (getters.mode.mode.mode === 'settled') {
            commit('client/ADD_SETTLED', checked);
          } else if (getters.mode.mode.mode === 'invoiced') {
            commit('client/ADD_INVOICED', checked);
          }
        }

        resolve();
      });
    },
    mode: function ({dispatch, commit, getters}, mode) {
      return new Promise(resolve => {
        commit('MODE', mode);
        resolve();
      });
    },
    getColumn({commit}) {
      return new Promise((resolve, reject) => {
        axios.get(`get_column/reports`)
          .then(function (response) {
            commit('SAVE_COLUMN_NAME', response.data.model);
            resolve(response);
          })
          .catch(function (e) {
            reject(e);
          });
      });
    },
  },

  modules: {
    internal: {
      namespaced: true,
      state: {
        settled: [],
        not_settled: [],
      },
      getters: {
        settled(state) {
          return state.settled;
        },
        not_settled(state) {
          return state.not_settled;
        },
      },
      mutations: {
        ADD_SETTLED: (state, array) => {
          state.settled = array;
        },
        ADD_NOT_SETTLED: (state, array) => {
          state.not_settled = array;
        },
      },
      actions: {
        addSettled: function ({dispatch, commit, getters}, array) {
          return new Promise(resolve => {
            commit('ADD_SETTLED', array);
            resolve();
          });
        },
        addNotSettled: function ({dispatch, commit, getters}, array) {
          return new Promise(resolve => {
            commit('ADD_NOT_SETTLED', array);
            resolve();
          });
        },
      },
    },
    client: {
      namespaced: true,
      state: {
        settled: [],
        not_settled: [],
        invoiced: [],
      },
      getters: {
        settled(state) {
          return state.settled;
        },
        not_settled(state) {
          return state.not_settled;
        },
        invoiced(state) {
          return state.invoiced;
        },
      },
      mutations: {
        ADD_SETTLED: (state, array) => {
          state.settled = array;
        },
        ADD_NOT_SETTLED: (state, array) => {
          state.not_settled = array;
        },
        ADD_INVOICED: (state, array) => {
          state.invoiced = array;
        },
      },
      actions: {
        addSettled: function ({dispatch, commit, getters}, array) {
          return new Promise(resolve => {
            commit('ADD_SETTLED', array);
            resolve();
          });
        },
        addNotSettled: function ({dispatch, commit, getters}, array) {
          return new Promise(resolve => {
            commit('ADD_NOT_SETTLED', array);
            resolve();
          });
        },
        addInvoiced: function ({dispatch, commit, getters}, array) {
          return new Promise(resolve => {
            commit('ADD_INVOICED', array);
            resolve();
          });
        },
      },
    },
  },
};

export default report;
