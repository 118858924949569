import moment from 'moment';
// import state from "./state";

export default {
  currentProjectId: state => {
    if (state.route && state.route.params.projectId) {
      return state.route.params.projectId;
    }
    return state.currentProjectId;
  },
  currentIssueId: state => {
    return state.currentIssueId;
  },
  groupId: state => {
    if (state.route && state.route.params.groupId) {
      return state.route.params.groupId;
    } else {
      return null;
    }
  },

  active: state => {
    return state.active;
  },

  routeBreadcrumbs: state => {
    if (state.route && state.route.meta.breadcrumb) {
      return JSON.parse(JSON.stringify(state.route.meta.breadcrumb));
    }
    return null;
  },
  issueFilter: state => {
    return state.issueFilter;
  },
  getOperatorStatus: state => state.issueFilter.selectedValues.statusOperator,
  ganttIssueFilter: state => {
    return state.ganttIssueFilter;
  },
  stickyArray: state => {
    return state.stickyArray;
  },

  getIssueFiltersDisability: state => {
    return state.issueFilter.filters;
  },

  getIssueFilterValue: state => filter => {
    return state.issueFilter.selectedValues[filter];
  },
  getIssueFilterIsActive: state => filter => {
    return state.issueFilter.checkedFilters[filter];
  },

  getAllIssueFilters: state => {
    let result = {};
    if (state.issueFilter.checkedFilters.status === true) {
      if (state.issueFilter.selectedValues.status) {
        result.status = state.issueFilter.selectedValues.status;
      }
      result.statusOperator = state.issueFilter.selectedValues.statusOperator;
    }
    if (state.issueFilter.checkedFilters.priority === true) {
      if (state.issueFilter.selectedValues.priority) {
        result.priority = state.issueFilter.selectedValues.priority;
      }
    }
    if (state.issueFilter.checkedFilters.assign_to === true) {
      if (state.issueFilter.selectedValues.assign_to) {
        result.assign_to = state.issueFilter.selectedValues.assign_to;
      }
    }
    if (state.issueFilter.checkedFilters.author === true) {
      if (state.issueFilter.selectedValues.author) {
        result.author = state.issueFilter.selectedValues.author;
      }
    }
    if (state.issueFilter.checkedFilters.dates === true) {
      if (state.issueFilter.selectedValues.dates) {
        result.dates = moment(state.issueFilter.selectedValues.dates).format('YYYY-MM-DD');
      }
      if (state.issueFilter.selectedValues.dateRange) {
        result.dateRange1 = moment(state.issueFilter.selectedValues.dateRange[0]).format('YYYY-MM-DD');
        result.dateRange2 = moment(state.issueFilter.selectedValues.dateRange[1]).format('YYYY-MM-DD');
      }
      result.dateRange = state.issueFilter.selectedValues.dateRange;
      result.dateType = state.issueFilter.selectedValues.dateType;
      result.dateOperator = state.issueFilter.selectedValues.dateOperator;
    }
    if (state.issueFilter.checkedFilters.issue_type === true) {
      if (state.issueFilter.selectedValues.issue_type) {
        result.issue_type = state.issueFilter.selectedValues.issue_type;
      }
    }
    if (state.issueFilter.checkedFilters.project_ids === true) {
      if (state.issueFilter.selectedValues.project_ids) {
        result.project_ids = state.issueFilter.selectedValues.project_ids;
      }
    }
    if (state.issueFilter.checkedFilters.subject === true) {
      if (state.issueFilter.selectedValues.subject) {
        result.subject = state.issueFilter.selectedValues.subject;
      }
    }
    return result;
  },
  systemSetting: state => {
    return state.systemSetting;
  },
  issueId: state => {
    return state.issueId;
  },
  getTime: state => {
    return state.getTime;
  },
  getRefreshActivity: state => {
    return state.getRefreshActivity;
  },
  modules: state => {
    return state.modules;
  },
  checkModule: (state) => (module) => {
    if (state.modules && state.modules[module]) {
      return state.modules[module];
    }
  },
  getSelectedStatuses: state => state.issueFilter.selectedValues.status,
  invoice_items: state => {
    return state.invoice_items;
  },
  getTimeLogger: state => {
    return state.getTimeLogger;
  },
  user: state => {
    return state.user;
  },
  authenticated: state => {
    return state.authenticated;
  },
  group_projects: state => {
    return state.group_projects;
  },

  language: state => state.language,
  locales: state => state.locales,
  getGanttUsersWithIssues: state => {
    return state.ganttIssues;
  },
  getGanttUserIssues: state => id => {
    return state.ganttIssues[id].issues;
  },
  getGanttIssueDetails_id: state => {
    return state.ganttIssueDetailsId;
  },
  getGanttIssueDetailsShow: state => {
    return state.ganttIssueDetailsShow;
  },
  getGanttIssueEditShow: state => {
    return state.ganttIssueEditShow;
  },
  getGanttIssueDetails_projectId: state => {
    return state.ganttIssueDetailsProjectId;
  },
  getGanttCellWidth: state => {
    return state.ganttCellWidth;
  },
  getGanttIsDataChanged: state => {
    return state.ganttIsDataChanged;
  },
  arrayMessage: state => state.arrayMessage,
  watchers: state => state.watchers,
  uConfig: state => state.uConfig,
  getGanttCalendarStartDate: state => {
    return state.getGanttCalendarStartDate;
  },
  getGanttCalendarDueDate: state => {
    return state.getGanttCalendarDueDate;
  },
  getGanttShowTimeLines: state => {
    return state.ganttShowTimeLines;
  },
  getGanttUserEstimatedData: state => id => {
    return state.ganttEstimatedData.filter(item => {
      return item.userId === id;
    });
  },
  getGanttIssueEstimatedData: state => id => {
    return state.ganttEstimatedData.find(item => {
      return item.issueId === id;
    });
  },
  sidebar: state => state.app.sidebar,
  device: state => state.app.device,

  version: state => {
    return state.version;
  },
  logo: state => state.logo,
  // getFilters: state => {
  //   return state.filters;
  // },
  getSelectedColumns: state => {
    return state.selectedColumns;
  },
  savedColumns: state => {
    if (state.uConfig.data) {
      return state.uConfig.data.issue.selectThead;
    }
  },
};
