import VueI18n from '../config/i18n';
// import WorkflowIndex from '../views/Workflow/Index.vue'
// import personalData from '../views/PersonalData/Index.vue'
// import TranslateIndex from '../views/Translate/Index.vue'
// import LanguagesIndex from '../views/Translate/Languages.vue'
// import TranslateLabelIndex from '../views/Translate/LabelTranslate.vue'
// import TranslationsIndex from '../views/Translate/Translations.vue'
// import GroupIndex from '../views/Group/Index.vue'
// import GroupShow from '../views/Group/Show.vue'
// import GroupForm from '../views/Group/Form.vue'
// import EnumerationIndex from '../views/Enumeration/Index.vue'
// import AdministrationMenu from '../views/Overview/AdministrationMenu.vue'
// import PmRoleIndex from '../views/Overview/PmRolesAndPermissions/Index.vue'
import PmRoleForm from '../views/Overview/PmRolesAndPermissions/Form.vue';
import PmRoleIndex from '../views/Overview/PmRolesAndPermissions/Index.vue';
import UserIndex from '../views/User/Index.vue';
import UserShow from '../views/User/Show.vue';
import UserForm from '../views/User/Form.vue';
import UserActivity from '../views/User/MyActivity.vue';

import RoleIndex from '../views/Role/Index.vue';
// import RoleShow from '../views/Role/Show.vue'
import RoleForm from '../views/Role/Form.vue';

import {andGuard} from '../guards/guards';

export default [
  {
    path: '/administration',
    component: () => import('../views/Overview/AdministrationMenu.vue'),
    name: 'Administration Menu',
    beforeEnter: andGuard,
    meta: {
      description: VueI18n.t('system.administration'),
      breadcrumb: [{name: VueI18n.t('system.administration'), title: 'breadcrumb.administration'}],
      permissions: ['admin'],
    },
    children: [
      {
        path: 'configuration',
        component: () => import('../views/SystemSettings/Index.vue'),
        beforeEnter: andGuard,
        name: 'Configuration',
        meta: {
          description: VueI18n.t('system.configuration'),
          permissions: ['admin'],
          module: 'spain',
        },
      },
      /*
       Enumeration BEGIN
       */
      {
        path: 'enumeration',
        component: () => import('../views/Enumeration/Index.vue'),
        name: 'enumeration Index',
        beforeEnter: andGuard,
        meta: {
          description: VueI18n.t('system.enumerations'),
          breadcrumb: [{name: VueI18n.t('system.enumerations'), title: 'breadcrumb.enumeration'}],
          permissions: ['admin'],
          module: 'spain',
        },
      },
      {
        path: 'work-orders',
        component: () => import('../views/SystemSettings/WorkOrders/Index.vue'),
        name: 'Work Orders',
        beforeEnter: andGuard,
        meta: {
          description: VueI18n.t('system.workOrders'),
          breadcrumb: [{name: VueI18n.t('system.workOrders'), title: 'breadcrumb.workOrders'}],
          permissions: ['admin'],
          module: 'spain',
        },
      },
      /*
       Enumeration END
       */
      /*
       Import clients BEGIN
       */
      /*
       Import clients END
       */
      /*
       Json BEGIN
       */
      {
        path: 'json',
        alias: '',
        component: () => import('../views/JSON/Index.vue'),
        name: 'JsonIndex',
        meta: {
          description: VueI18n.t('system.json'),
          breadcrumb: [{name: 'JsonIndex', title: 'breadcrumb.json'}],
          module: 'spain',
        },
      },
      {
        path: 'json/:jsonId',
        component: () => import('../views/JSON/Show.vue'),
        name: 'Json show',
        meta: {
          description: VueI18n.t('system.show_json'),
          mode: 'show',
          module: 'spain',
        },
      },
      /*
       Json END
       */
      {
        path: '/administration/workflow',
        component: () => import('../views/Workflow/Index.vue'),
        name: 'Workflow',
        meta: {
          description: VueI18n.t('system.workflow'),
          breadcrumb: [{name: VueI18n.t('system.workflow'), title: 'breadcrumb.workflow'}],
          module: 'spain',
        },
      },
      {
        path: '/administration/personal_data',
        component: () => import('../views/PersonalData/Index.vue'),
        name: 'Personal data',
        meta: {
          description: VueI18n.t('system.personal_data'),
          breadcrumb: [{name: VueI18n.t('system.personal_data'), title: 'breadcrumb.personal_data'}],
          module: 'spain',
        },
      },
      /*
       Group BEGIN
       */
      {
        path: 'group',
        component: () => import('../views/Group/Index.vue'),
        name: 'Group',
        meta: {
          description: VueI18n.t('system.groups'),
          breadcrumb: [{name: 'Group', title: 'breadcrumb.group'}],
        },
      },
      {
        path: 'group/create',
        component: () => import('../views/Group/Form.vue'),
        name: 'Create group',
        meta: {
          description: VueI18n.t('system.create_group'),
          mode: 'create',
          breadcrumb: [{name: VueI18n.t('system.groups'), title: 'breadcrumb.group'}, {
            name: VueI18n.t('system.create_group'),
            title: 'breadcrumb.create_group',
          }],
        },
      },
      {
        path: 'group/:groupId',
        component: () => import('../views/Group/Show.vue'),
        name: 'Show group',
        meta: {
          description: VueI18n.t('system.group'),
          mode: 'show',
          breadcrumb: [{name: VueI18n.t('system.groups'), title: 'breadcrumb.group'}, {
            name: 'Show Group',
            title: 'breadcrumb.show_group',
          }],
        },
      },
      /*
       Group END
       */
      /*
       translate BEGIN
       */
      {
        path: 'translate',
        component: () => import('../views/Translate/Index.vue'),
        name: 'Translate',
        meta: {
          description: VueI18n.t('system.list_translates'),
          breadcrumb: [{name: VueI18n.t('user.language'), title: 'breadcrumb.translate'}],
          module: 'spain',
        },
      },
      {
        path: 'translate-label',
        component: () => import('../views/Translate/LabelTranslate.vue'),
        name: 'LabelTranslate',
        meta: {
          description: VueI18n.t('system.translate_label'),
          breadcrumb: [{name: VueI18n.t('user.translate_label'), title: 'breadcrumb.translate_label'}],
          module: 'spain',
        },
      },
      {
        path: 'languages',
        component: () => import('../views/Translate/Languages.vue'),
        name: 'LanguagesIndex',
        meta: {
          description: VueI18n.t('system.languages'),
          breadcrumb: [{name: VueI18n.t('user.languages'), title: 'breadcrumb.languages'}],
          module: 'spain',
        },
      },
      {
        path: 'languages/:id',
        component: () => import('../views/Translate/Translations.vue'),
        name: 'TranslationsIndex',
        meta: {
          description: VueI18n.t('system.translations_index'),
          breadcrumb: [{name: VueI18n.t('system.translations_index'), title: 'breadcrumb.translations_index'}],
          module: 'spain',
        },
      },
      /*
       translate END
       */

      /*
       Role Project BEGIN
       */
      {
        path: 'role_and_permission',
        component: PmRoleIndex,
        name: 'Index PmRole',
        beforeEnter: andGuard,
        meta: {
          description: VueI18n.t('system.role_for_projects'),
          breadcrumb: [{name: VueI18n.t('system.role_for_projects'), title: 'breadcrumb.role_for_projects'}],
          permissions: ['admin'],
          module: 'spain',
        },
      },
      {
        path: 'pm_role/create',
        component: PmRoleForm,
        name: 'Create PmRole',
        beforeEnter: andGuard,
        meta: {
          description: VueI18n.t('system.create_pmrole'),
          mode: 'create',
          breadcrumb: [{name: VueI18n.t('system.index_pmrole'), title: 'breadcrumb.pm_role'}, {
            name: VueI18n.t('system.create_pmrole'),
            title: 'breadcrumb.create_pmrole',
          }],
          permissions: ['admin'],
          module: 'spain',
        },
      },
      {
        path: 'pm_role/:id',
        component: PmRoleForm,
        name: 'Show PmRole',
        beforeEnter: andGuard,
        meta: {
          mode: 'edit',
          description: VueI18n.t('system.show_pmrole'),
          breadcrumb: [{name: VueI18n.t('system.index_pmrole'), title: 'breadcrumb.pm_role'}, {
            name: VueI18n.t('system.show_pmrole'),
            title: 'breadcrumb.show_pmrole',
          }],
          permissions: ['admin'],
          module: 'spain',
        },
      },
      /*
       Role Project END
       */
      /*
       User BEGIN
       */
      {
        path: 'user',
        component: UserIndex,
        name: 'User',
        beforeEnter: andGuard,
        meta: {
          description: VueI18n.t('system.users'),
          breadcrumb: [{name: VueI18n.t('system.users'), title: 'breadcrumb.user'}],
          permissions: ['view-user'],
        },
      },
      {
        path: 'user/create',
        component: UserForm,
        name: 'Create User',
        beforeEnter: andGuard,
        meta: {
          description: VueI18n.t('system.create_user'),
          breadcrumb: [{name: VueI18n.t('system.users'), title: 'breadcrumb.user'}, {
            name: VueI18n.t('system.create_user'),
            title: 'breadcrumb.create_user',
          }],
          mode: 'create',
          permissions: ['admin'],
        },
      },
      {
        path: 'user/:id/edit',
        component: UserForm,
        name: 'Edit User',
        beforeEnter: andGuard,
        meta: {
          description: VueI18n.t('system.edit_user'),
          breadcrumb: [{name: VueI18n.t('system.users'), title: 'breadcrumb.user'}, {
            name: VueI18n.t('system.show_user'),
            title: 'breadcrumb.show_user',
          }, {name: VueI18n.t('system.edit_user'), title: 'breadcrumb.edit_user'}],
          mode: 'edit',
          permissions: ['admin'],
        },
      },
      {
        path: 'user/:id',
        component: UserShow,
        name: 'Show User',
        beforeEnter: andGuard,
        props: true,
        meta: {
          description: VueI18n.t('system.show_user'),
          breadcrumb: [{name: VueI18n.t('system.users'), title: 'breadcrumb.user'}, {
            name: VueI18n.t('system.show_user'),
            title: 'breadcrumb.show_user',
          }],
          permissions: ['view-user'],
        },
      },
      {
        path: 'user/:id/activity',
        component: UserActivity,
        //TODO: change translation
        name: 'User Activity',
        beforeEnter: andGuard,
        meta: {
          description: VueI18n.t('system.activity'),
          breadcrumb: [{name: VueI18n.t('system.users'), title: 'breadcrumb.user'}],
          permissions: ['admin'],
          take: 'all',
        },
      },
      /*
       User END
       */
      /*
       Role system BEGIN
       */
      {
        path: 'role',
        component: RoleIndex,
        name: 'Role',
        beforeEnter: andGuard,
        meta: {
          description: VueI18n.t('system.role_for_system'),
          breadcrumb: [{name: VueI18n.t('system.role_for_system'), title: 'breadcrumb.role_for_system'}],
          permissions: ['admin'],
          module: 'spain',
        },
      },
      {
        path: 'role/create',
        component: RoleForm,
        name: 'Create role',
        beforeEnter: andGuard,
        meta: {
          description: VueI18n.t('system.create_role'),
          mode: 'create',
          breadcrumb: [{name: VueI18n.t('system.roles'), title: 'breadcrumb.role'}, {
            name: 'Create Role',
            title: 'breadcrumb.create_role',
          }],
          permissions: ['admin'],
          module: 'spain',
        },
      },
      {
        path: 'role/:id',
        component: RoleForm,
        name: 'Edit Role',
        beforeEnter: andGuard,
        meta: {
          description: VueI18n.t('system.edit_role'),
          mode: 'edit',
          breadcrumb: [{name: VueI18n.t('system.roles'), title: 'breadcrumb.role'}, {
            name: VueI18n.t('system.edit_role'),
            title: 'breadcrumb.edit_role',
          }],
          permissions: ['admin'],
          module: 'spain',
        },
      },
      /*
       Role system END
       */
    ],
  },
];
