<template>
  <!--ENUMERATIONS /administration/enumeration -->
  <span v-if="item.key === 'form'">
    <el-select
        :placeholder="$t('system.selectoption')"
        clearable
        filterable
        v-model="selForm"
    >
      <el-option
          :disabled="item.disabled"
          :key="item.id"
          :label="item.name"
          :value="item.id"
          v-for="item in getForms"
      >
        <span style="float: left">{{ item.name }}</span>
        <span style="float: right; color: #8492a6; font-size: 13px">{{ item.type }}</span>
      </el-option>
    </el-select>
  </span>
  <!--INVOICE /invoice -->
  <span v-else-if="item.key === 'payment_status'">
    <el-select
        class="w-100 h-100 d-flex justify-content-center align-items-center payment_status"
        filterable
        v-model="selVal"
        v-on:change="changeStatus($event, scope.id)"
    >
      <el-option
          :label="$t('system.paid')"
          value="true"
      >
        {{ $t('system.paid') }}
      </el-option>
      <el-option
          :label="$t('system.not_paid')"
          value="false"
      >
        {{ $t('system.not_paid') }}
      </el-option>
    </el-select>
  </span>
</template>
<script>
import axios from 'axios';
import notification from '../../notification/notify';

export default {
  props: ['scope', 'item', 'options', 'selected'],
  computed: {
    selVal: {
      get: function () {
        if (typeof this.scope.Dpl !== 'undefined') {
          return this.scope.Dpl ? 'true' : 'false';
        }
        return false;
      },
      set: function (value) {
        return value;
      },
    },
    selForm: {
      get: function () {
        let vm = this;
        if (vm.scope.form) {
          if (vm.selected.indexOf(vm.scope.form) === -1) {
            vm.selected.push(vm.scope.form);
          }

          vm.options.forms.forEach((e) => {
            if (e.id === vm.scope.form) {
              e.disabled = true;
            }
          });
        }
        return vm.scope.form;
      },
      set: function (value) {
        let vm = this;
        vm.selected.splice(vm.selected.indexOf(vm.scope.form), 1);
        vm.options.forms.forEach((e) => {
          if (e.id === vm.scope.form) {
            e.disabled = false;
          }
        });

        if (value) {
          if (vm.selected.indexOf(value) === -1) {
            vm.selected.push(value);
            vm.options.forms.forEach((e) => {
              if (e.id === value) {
                e.disabled = true;
              }
            });
          } else {
            vm.options.forms.forEach((e) => {
              if (e.id === value) {
                e.disabled = false;
              }
            });
          }
        }
        vm.scope.form = value;
        return value;
      },
    },
    getForms: {
      get: function () {
        return this.options.forms;
      },
      set: function (value) {
        return value;
      },
    },
  },
  methods: {
    changeStatus(event, id) {
      let data = {
        status: event,
      };
      var vm = this;
      const config = {headers: {'Content-Type': `application/x-www-form-urlencoded`}};
      axios.put('invoice/change_status/' + id, data, config)
          .then(function (response) {
            if (response.data.saved) {
              notification.notify(
                  vm.$t('notify.success'),
                  response.data.message,
                  'success');
              vm.scope.Dpl = response.data.Dpl;
            } else {
              notification.notify(
                  vm.$t('notify.error'),
                  response.data.message,
                  'error');
            }
          })
          .catch(function (error) {
            notification.notify(
                vm.$t('notify.error'),
                error.response.data.error,
                'error');
          });
    },
  },
};
</script>
<style
    lang="scss"
    rel="stylesheet/scss"
>
.payment_status {
  .el-input--suffix .el-input__inner {
    background-color: transparent;
    padding-right: 30px;
    border: none;
  }
}
</style>
<style
    lang="scss"
    rel="stylesheet/scss"
    scoped
>
.el-select-dropdown .el-select-dropdown__item {
  max-width: 100%;
}
</style>
