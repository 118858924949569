<template>
  <div>
    <div class="d-flex justify-content-end nav-filter">
      <div>
        <DebounceInput
            :placeholder="$t('system.search')"
            :size="size"
            @change="searchStringChangeHandler"
            class="mr-2"
            clearable
            style="max-width: 180px"
            v-if="!disableSearch"
            v-model="searchPhrase"
        />
      </div>
      <div>
        <div class="dropdown-configurator--buttons">
          <el-badge
              :hidden="countOfActiveFilters === 0"
              :value="countOfActiveFilters"
          >
            <el-button-group>
              <el-button
                  :size="size"
                  :style="showConfigurations ? 'background: #74767c':'background: #909399'"
                  @click="toggleConfigurations"
                  class="outline-none"
                  id="dropdown--config-switch-button"
                  type="info"
                  v-if="!disableColumns"
              >
                <font-awesome-icon
                    icon="cogs"
                    id="dropdown--config-switch-icon"
                />
              </el-button>
              <el-button
                  :size="size"
                  :style="showFilters ? 'background: #74767c':'background: #909399'"
                  @click="toggleFilters"
                  class="outline-none"
                  id="dropdown--filter-switch-button"
                  type="info"
                  v-if="!disableFilters"
              >
                <font-awesome-icon
                    icon="filter"
                    id="dropdown--filter-switch-icon"
                />
              </el-button>
            </el-button-group>
          </el-badge>
        </div>
        <div
            :id="'collapseDropdown_' + componentId"
            class="view-container"
            style="margin-bottom: 0"
        >
          <transition name="el-fade-in">
            <div
                :style="{[position]: '2%', width: width + 'px'}"
                class="issueFilter issueFilter-shadow"
                v-show="showFilters && !showConfigurations"
            >
              <div class="view">
                <div class="view-body">
                  <div class="row">
                    <div
                        class="col-3 issue--customFilrers"
                        style="border-right: 1px solid #afb1b6;"
                        v-if="showFiltersConfigurations"
                    >
                      <template v-if="filtersConfigs">
                        <el-button-group
                            :key="config.id"
                            class="d-flex"
                            v-for="config in filtersConfigs"
                        >
                          <el-tooltip
                              :content="config.value.title"
                              :open-delay=1000
                              placement="top"
                          >
                            <el-button
                                :class="currentFiltersConfig === config.id ? 'issueFilter--button-active' : ''"
                                :type="currentFiltersConfig === config.id ? 'info' : ''"
                                @click="setCurrentFiltersConfig(config)"
                                class="w-100 issueFilter--button-wrap"
                            >
                              {{ config.value.title }}
                            </el-button>
                          </el-tooltip>
                          <el-button
                              :size="size"
                              @click="updateFilter(config.id, config.value.title)"
                              type="primary"
                              v-if="currentFiltersConfig === config.id"
                          >
                            <font-awesome-icon icon="save" />
                          </el-button>
                          <el-button
                              :loading="deleteFilterLoading === config.id"
                              :size="size"
                              @click="deleteFilter(config.id)"
                              icon="el-icon-delete"
                              type="danger"
                          ></el-button>
                        </el-button-group>
                      </template>
                      <span
                          class="no-date-title"
                          v-else
                      >{{ $t('system.no_data') }}</span>
                    </div>
                    <div :class="showFiltersConfigurations ? 'col-9' : 'col-12'">
                      <!--                      <template v-for="(item, index) in filters">-->
                      <!--                        <dynamic-filters :data="item" :key="index"></dynamic-filters>-->
                      <FiltersGenerator :filters="localFilters" />

                      <div class="filters-button">
                        <template v-if="showFiltersConfigurations">
                          <el-button
                              :size="size"
                              type="info"
                              v-on:click.native="showNewFilterTitleInput"
                              v-show="showSaveFilterButton"
                          >
                            {{ $t('system.save') }}
                          </el-button>
                          <el-input
                              :class="['issue--newFilterTitle', checkFilterTitle && !newFilterTitle ? 'validate-error' : '']"
                              @keyup.enter.native="saveNewFilter"
                              ref="newFilterTitle"
                              v-model="newFilterTitle"
                              v-observe-visibility="newFilterTitleVisibilityChanged"
                              v-show="!showSaveFilterButton"
                          >
                            <i
                                @click="closeNewFilterTitleInput"
                                class="el-icon-circle-close el-input__icon"
                                slot="suffix"
                            >
                            </i>
                          </el-input>
                          <el-button
                              :size="size"
                              @click="saveNewFilter"
                              type="primary"
                              v-show="!showSaveFilterButton"
                          >
                            {{ $t('system.save') }}
                          </el-button>
                        </template>
                        <el-button
                            :size="size"
                            type="info"
                            v-on:click="clearFilters"
                        >{{ $t('system.clear') }}
                        </el-button>
                        <el-button
                            :size="size"
                            type="primary"
                            v-on:click="submitSearch"
                        >{{ $t('system.search') }}
                        </el-button>
                      </div>
                    </div>
                    <!--                      </template>-->
                    <!--<div class="filters-left-task">-->
                    <!--<vue-form-generator :schema="_self['issueFilter']"></vue-form-generator>-->
                    <!--</div>-->
                  </div>
                </div>
              </div>
            </div>
          </transition>
          <transition name="el-fade-in">
            <div
                :style="{[position]: '2%', width: width + 'px'}"
                class="issueFilter issueFilter-shadow"
                v-show="showConfigurations && !showFilters"
            >
              <div class="issue--transfer">
                <div class="view">
                  <div class="view-body">
                    <div class="row">
                      <template v-if="tableViews.length">
                        <div>
                          <el-button
                              :key="tableView.id"
                              :type="activeTableView === tableView.id ? 'primary' : ''"
                              @click="setActiveTableView(tableView.id)"
                              v-for="tableView in tableViews"
                          >
                            {{ $t(tableView.name) }}
                          </el-button>
                        </div>
                      </template>
                      <template v-else>
                        <div
                            class="col-3 issue--customFilrers"
                            style="border-right: 1px solid #afb1b6;"
                            v-if="showColumnsConfigurations"
                        >
                          <!--<el-button type="info" @click.prevent.stop="defaultConfig"-->
                          <!--class="issue&#45;&#45;customColumnsConfig-defaultConfig">-->
                          <!--{{$t('system.default_system')}}-->
                          <!--</el-button>-->
                          <template v-if="columnsConfigs">
                            <el-button-group
                                :key="config.id"
                                class="d-flex"
                                v-for="config in columnsConfigs"
                            >
                              <el-tooltip
                                  :content="config.value.title"
                                  :open-delay=1000
                                  placement="top"
                              >
                                <el-button
                                    :class="[currentColumnsConfig === config.id && checkDiffActive ? 'issueFilter--button-active' : '']"
                                    :size="size"
                                    :type="currentColumnsConfig === config.id ? 'info' : ''"
                                    @click="setCurrentColumnsConfig(config)"
                                    class="w-100 issueFilter--button-wrap"
                                >
                                  {{ config.value.title }}
                                </el-button>
                              </el-tooltip>
                              <el-button
                                  :size="size"
                                  @click="updateColumn(config.id, config.value.title)"
                                  type="primary"
                                  v-if="currentColumnsConfig === config.id && checkDiff(config)"
                              >
                                <font-awesome-icon icon="save" />
                              </el-button>
                              <el-button
                                  :loading="deleteColumnLoading === config.id"
                                  :size="size"
                                  @click="deleteColumn(config.id)"
                                  icon="el-icon-delete"
                                  type="danger"
                              ></el-button>
                            </el-button-group>
                          </template>
                          <span
                              class="no-date-title"
                              v-else
                          >{{ $t('system.no_data') }}</span>
                        </div>
                        <div
                            :class="showColumnsConfigurations ? 'col-9' : 'col-12'"
                            class="issue--customFilrers"
                        >
                          <el-transfer
                              :data="columns"
                              :filter-placeholder="$t('system.enter_the_name_of_the_search')"
                              :style="!showColumnsConfigurations ? 'height: 100% !important' : ''"
                              :titles="[$t('issue.no-checked'), $t('issue.checked')]"
                              filterable
                              target-order="push"
                              v-if="columns"
                              v-model="localSelectedColumns"
                          >
                          </el-transfer>
                          <div
                              class="filters-button"
                              v-if="showColumnsConfigurations"
                          >
                            <!--<el-button type="info" @click.prevent.stop="clearConfig">{{$t('system.clear_filters')}}</el-button>-->
                            <el-button
                                :size="size"
                                @click="showNewColumnsConfigTitleInput"
                                type="primary"
                                v-show="showSaveColumnsConfigButton"
                            >
                              {{ $t('system.save_configs') }}
                            </el-button>
                            <el-input
                                :class="['issue--newFilterTitle', checkColumnTitle && !newColumnsConfigTitle ? 'validate-error' : '']"
                                @keyup.enter.native="saveNewColumnsConfig"
                                ref="newColumnsConfigTitle"
                                v-model="newColumnsConfigTitle"
                                v-observe-visibility="newColumnsConfigTitleVisibilityChanged"
                                v-show="!showSaveColumnsConfigButton"
                            >
                              <i
                                  @click="closeNewColumnsConfigTitleInput"
                                  class="el-icon-circle-close el-input__icon"
                                  slot="suffix"
                              >
                              </i>
                            </el-input>
                            <el-button
                                :size="size"
                                @click="saveNewColumnsConfig"
                                type="primary"
                                v-show="!showSaveColumnsConfigButton"
                            >
                              {{ $t('system.save') }}
                            </el-button>
                          </div>
                        </div>
                      </template>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </transition>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import dynamicFilters from '../Filters/dynamicFilters.vue'
import FiltersGenerator from '../crm-form-generator/FiltersGenerator.vue';
import DebounceInput from '../DebounceInput.vue';

export default {
  name: 'DropdownConfigurator',
  components: {
    DebounceInput,
    FiltersGenerator,
    // dynamicFilters
  },
  props: {
    size: {
      type: String,
      default: 'small',
    },
    searchString: {
      type: String,
      default: '',
    },
    position: {
      type: String,
      default: 'right',
      validator: (prop) => [
        'left',
        'right',
      ].includes(prop),
    },
    disableFilters: {
      type: Boolean,
    },
    disableColumns: {
      type: Boolean,
    },
    disableSearch: {
      type: Boolean,
    },
    showColumnsConfigurations: {
      type: Boolean,
      default: false,
    },
    showFiltersConfigurations: {
      type: Boolean,
      default: false,
    },
    filters: {
      default: () => [],
    },
    tableViews: {
      type: Array,
      default: () => [],
    },
    activeTableView: {
      type: Number,
    },
    columns: {
      type: Array,
      default: null,
    },
    selectedColumns: {
      type: Array,
      default: null,
    },
    filtersConfigs: {
      type: Array,
      default: null,
    },
    columnsConfigs: {
      type: Array,
      default: null,
    },
    width: {
      type: Number,
      default: 750,
    },
    currentFiltersConfig: {
      type: Number,
      default: null,
    },
    currentColumnsConfig: {
      type: Number,
      default: null,
    },
    onSelectFilter: Function,
  },
  data() {
    return {
      localFilters: [],
      searchPhrase: '',
      componentId: Math.random(),
      showFilters: false,
      showConfigurations: false,
      checkFilterTitle: false,
      checkColumnTitle: false,
      dropdownSelectors: ['collapseDropdown_' + this.componentId,
        'dropdown--filter-switch-button',
        'dropdown--config-switch-button',
        'dropdown--filter-switch-icon',
        'dropdown--config-switch-icon',
      ],
      newFilterTitle: '',
      newColumnsConfigTitle: '',
      showSaveFilterButton: true,
      showSaveColumnsConfigButton: true,
    };
  },
  computed: {
    countOfActiveFilters() {
      return this.filters.reduce((sum, item) => {
        if (Array.isArray(item.value)) {
          return item.value.length ? sum + 1 : sum;
        } else {
          return item.value ? sum + 1 : sum;
        }
      }, 0);
    },
    localSelectedColumns: {
      get() {
        return this.selectedColumns;
      },
      set(columns) {
        this.$emit('update:selectedColumns', columns);
      },
    },
  },
  watch: {
    filters: {
      // immediate: true,
      handler(newValue) {
        if (newValue) {
          this.localFilters = JSON.parse(JSON.stringify(newValue));
          // console.log('compare', this.compare())
        }
      },
    },
  },
  mounted() {
    this.closeQuickFilterDropdownIfClickedOutside();
    this.localFilters = JSON.parse(JSON.stringify(this.filters));
  },
  methods: {
    setActiveTableView(tableViewId) {
      this.$emit('setActiveTableView', tableViewId);
    },
    searchStringChangeHandler() {
      this.$emit('update:searchString', this.searchPhrase);
      this.submitSearch();
    },
    // Visual methods
    newFilterTitleVisibilityChanged() {
      this.$refs.newFilterTitle.focus();
    },
    newColumnsConfigTitleVisibilityChanged() {
      this.$refs.newColumnsConfigTitle.focus();
    },
    showNewFilterTitleInput() {
      this.showSaveFilterButton = false;
    },
    closeNewFilterTitleInput() {
      this.showSaveFilterButton = true;
      this.newFilterTitle = '';
    },
    closeNewColumnsConfigTitleInput() {
      this.showSaveColumnsConfigButton = true;
      this.newColumnsConfigTitle = '';
    },
    showNewColumnsConfigTitleInput() {
      this.showSaveColumnsConfigButton = false;
    },
    closeQuickFilterDropdownIfClickedOutside() {
      document.addEventListener('click', (ev) => {
        if (this.checkIfClickedOutside(ev)) {
          return 0;
        } else {
          this.showFilters = false;
          this.showConfigurations = false;
          this.$emit('closeDropdowmConfigurator');
        }
      });
    },
    checkIfClickedOutside(ev) {
      let dropdown = document.getElementById('collapseDropdown_' + this.componentId);
      return this.dropdownSelectors.find(selector => {
        return (dropdown && dropdown.contains(ev.target)) || selector === ev.target.id || selector === ev.target.parentElement.id || (ev.target.closest(selector) && ev.target.closest(selector).id === selector);
      });
    },
    toggleFilters() {
      if (this.showConfigurations) {
        // this.close('showConfigurations').then(() => { this.open('showFilters') })
        this.showFilters = true;
        this.showConfigurations = false;
      } else {
        if (this.showFilters) {
          this.showFilters = false;
        } else {
          this.showFilters = true;
          // this.open('showFilters')
        }
      }
    },
    toggleConfigurations() {
      if (this.showFilters) {
        this.showFilters = false;
        this.showConfigurations = true;
        // this.close('showFilters').then(() => { this.open('showConfigurations') })
      } else {
        if (this.showConfigurations) {
          this.showConfigurations = false;
        } else {
          this.showConfigurations = true;
          // this.open('showConfigurations')
        }
      }
    },
    // Logic methods
    clearFilters() {
      // window.history.pushState({}, '', this.$route.path);
      // this.$store.commit('filters/CLEAR_CURRENT_FILTER', {idTracker: this.$route.params.idTracker});
      this.$emit('clearFilters');
    },
    updateFilter(id, title) {
      // TODO: complete
      this.$emit('updateFilter', id, title);
    },
    deleteFilter(id) {
      // TODO: complete
      this.$emit('deleteFilter', id);
    },
    saveNewFilter() {
      // TODO: complete
      this.$emit('saveNewFilter');
    },
    saveNewColumnsConfig() {
      // TODO: complete
      this.$emit('saveNewColumnsConfig');
    },
    setCurrentColumnsConfig(config) {
      // TODO: complete
      this.$emit('setCurrentColumnsConfig', config);
    },
    setCurrentFiltersConfig(filter) {
      this.$emit('setCurrentFiltersConfig', filter);
      // TODO: complete
    },
    submitSearch() {
      this.$emit('submitSearch', this.localFilters);
      this.showFilters = false;
    },
  },
};
</script>

<style
    lang="scss"
    rel="stylesheet/scss"
>
.dropdown-configurator--buttons {
  .el-badge__content, .el-badge__content--undefined {
    z-index: 1;
  }
}

.el-select-dropdown, .el-picker-panel {
  z-index: 99999 !important;
}

.issueFilter--button-active {
  max-width: 118px;
  min-width: 118px;
}

.issueColumn--button-active {
  max-width: 119px;
  min-width: 119px;
}

.issueFilter--button-wrap, .issueColumn--button-wrap {
  max-width: 160px;

  span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
  }
}

.issueFilter {
  margin-top: 10px;
  position: absolute;
  background-color: #fff;
  right: 2%;
  z-index: 99999;
  overflow: hidden;
  border-radius: 5px;

  .issue--customFilrers {
    height: 330px;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 0.60em !important;
      height: 5px;
    }

    &::-webkit-scrollbar-track {
      border-radius: 2.5px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 2.5px;
      background: rgba(144, 147, 153, .3);
      /*border-right: 0.15em solid white;*/
    }

    .el-button-group {
      width: 100%;
      margin: 5px 0;

      .issue--filterTitle {
        width: 72%;

        span {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          display: block;
          max-width: 100px;
        }
      }

      .issue--filterDeleteButton {
        width: 28%;
      }
    }
  }

  .issue--transfer {
    width: 100%;
    /*height: 440px;*/
  }

  .slide-fade-enter-active {
    transition: all .3s ease;
  }

  .slide-fade-leave-active {
    transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
  }

  .slide-fade-enter, .slide-fade-leave-to {
    transform: translateX(10px);
    opacity: 0;
  }

  .filters-button {
    float: right;
    margin-top: 20px;

    .issue--newFilterTitle {
      width: 200px;
      margin-right: 10px;

      .el-input__icon:hover {
        cursor: pointer;
        color: #909399;
      }
    }
  }

  .el-transfer {
    display: flex !important;
    max-height: 340px;
    /*padding-bottom: 20px;*/
    align-items: center;
    height: calc(100% - 60px) !important;

    .el-transfer-panel {
      width: 100%;
      height: 100%;

      .el-transfer-panel__body {
        height: calc(100% - 60px);

        .el-transfer-panel__filter {
          margin: 15px 15px 7.5px 15px;
        }

        .el-transfer-panel__list.is-filterable {
          height: calc(100% - 35px);
        }
      }
    }
  }
}

.issueFilter-shadow {
  box-shadow: 0 0 14px rgba(0, 0, 0, .1);
}
</style>
<style
    lang="scss"
    rel="stylesheet/scss"
    scoped
>
.filterItem {
  padding: 10px 0 0 0;

  &:first-child {
    padding: 0
  }
}
</style>
