import VueI18n from '../config/i18n';
import {andGuard} from '@/guards/guards';

export default [
  {
    path: '/orders',
    name: 'Orders',
    component: () => import('../views/Orders/Index.vue'),
    props: true,
    meta: {
      description: VueI18n.t('system.orders'),
      permissions: ['orders-read'],
    },
    children: [
      {
        path: ':id',
        component: () => import('../views/Orders/Index.vue'),
        name: 'ViewOrder',
        props: true,
        beforeEnter: andGuard,
        meta: {
          description: VueI18n.t('system.view_order'),
          permissions: ['orders-read'],
        },
      },
    ],
  },
];
