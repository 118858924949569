import axios from 'axios';

const project = {
  namespaced: true,
  state: {
    model: {},
    changedStatus: '',
    refreshStatus: false,
    thead: [],
    refreshProject: false,
    mode: '',
    parentId: null,
  },
  getters: {
    project(state) {
      return state.model;
    },
    getChangedStatus(state) {
      return state.changedStatus;
    },
    getRefreshChangedStatus: state => {
      return state.refreshStatus;
    },
    get_column_name(state) {
      return state.thead;
    },
    refreshProject(state) {
      return state.refreshProject;
    },
    mode(state) {
      return state.mode;
    },
    parentId(state) {
      return state.parentId;
    },
  },
  mutations: {
    SET_PARENT_ID: (state, parentId) => {
      state.parentId = parentId;
    },
    ADD_PROJECT: (state, project) => {
      state.model = project;
    },
    DEL_PROJECT: (state, project) => {
      state.model = {};
    },
    CHANGE_VALUE: (state, obj) => {
      state.model[obj.param] = obj.new_val;
    },
    SET_CHANGED_STATUS(state, payload) {
      state.changedStatus = payload;
    },
    SET_REFRESH_CHANGED_STATUS(state, payload) {
      state.refreshStatus = payload;
    },
    SAVE_COLUMN_NAME: (state, thead) => {
      state.thead = thead;
    },
    REFRESH_PROJECT: (state) => {
      state.refreshProject = !state.refreshProject;
    },
    SET_MODE: (state, mode) => {
      state.mode = mode;
    },
  },
  actions: {
    refreshProject(context) {
      return new Promise(resolve => {
        context.commit('REFRESH_PROJECT');
        resolve();
      });
    },
    setParentId(coontext, parentId) {
      return new Promise(resolve => {
        coontext.commit('SET_PARENT_ID', parentId);
        resolve();
      });
    },
    setMode(context, mode) {
      return new Promise(resolve => {
        context.commit('SET_MODE', mode);
        resolve();
      });
    },
    addProject: function ({dispatch, commit, getters}, project) {
      return new Promise(resolve => {
        dispatch('mapProject', project);
        commit('ADD_PROJECT', project);
        resolve();
      });
    },
    changeVal: function ({dispatch, commit, getters}, obj) {
      return new Promise(resolve => {
        commit('CHANGE_VALUE', obj);
        resolve();
      });
    },
    delProject: function ({commit}, project) {
      return new Promise(resolve => {
        commit('DEL_PROJECT', project);
        resolve();
      });
    },
    mapProject: function ({dispatch, commit, getters}, project) {
      return new Promise(resolve => {
        resolve(project);
      });
    },
    getColumn({commit}) {
      return new Promise((resolve, reject) => {
        axios.get(`get_column/projects`)
          .then(function (response) {
            commit('SAVE_COLUMN_NAME', response.data.model);
            resolve(response);
          })
          .catch(function (e) {
            reject(e);
          });
      });
    },
  },
};

export default project;
