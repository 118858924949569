import {andGuard} from '../guards/guards';
import VueI18n from '../config/i18n';

export default [
  {
    path: 'module/:moduleName/:subModuleName',
    name: 'ModuleIndex',
    component: () => import('../views/CRM/Index.vue'),
    beforeEnter: andGuard,
    props: true,
    meta: {
      description: VueI18n.t('system.crm'),
      breadcrumb: [{name: 'crm', title: 'breadcrumb.crm'}],
      // permissions: ['crm']
    },
  },
  {
    path: 'module/:moduleName/:subModuleName/:formId/:entityId',
    name: 'ModuleEntity',
    component: () => import('../views/CRM/Entity.vue'),
    beforeEnter: andGuard,
    props: true,
    meta: {
      description: VueI18n.t('system.crm'),
      breadcrumb: [{name: 'crm', title: 'breadcrumb.crm'}],
      // permissions: ['crm']
    },
  },
];
