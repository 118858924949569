// import LoginView from '../views/Auth/Login.vue'
// import RegisterView from '../views/Auth/Register.vue'
// import ForgotPassword from '../views/Auth/ForgotPassword.vue'
// import ResetPassword from '../views/Auth/ResetPassword.vue'
import VueI18n from '../config/i18n';
// import store from '../store';
// import store from '../store/index'

export default [
  // {
  // path: '/',
  // component: () => import('../views/Auth/LoginContainer.vue'),
  // beforeEnter: (to, from, next) => {
  //   window.removeEventListener('focus', () => {
  //     store.dispatch('permission/getUserPermission').then((data) => {
  //       store.commit('permission/SET_READY');
  //       // this.$store.commit('auth/saveAuthInLocalStorage', data)
  //       store.commit('auth/SET_USER', data.user)
  //     });
  //   })
  //   next()
  // },
  // children: [
  {
    path: '/login',
    component: () => import('../views/Auth/Login.vue'),
    name: 'Login',
    meta: {
      description: 'system.login',
      breadcrumb: [{name: 'Login', title: 'system.login'}],
    },
    beforeEnter: (to, from, next) => { //new tab
      // console.log('isAuthenticated', store.getters['auth/isAuthenticated'])
      if (window.localStorage.getItem('token')) {
        next('/dashboard');
      }
      document.title = VueI18n.t('system.login');
      next();
    },
  },
  // {
  //   path: '/register',
  //   component: () => import('../views/Auth/Register.vue'),
  //   name: 'Register',
  //   meta: {
  //     description: 'system.register'
  //   },
  //   beforeEnter: (to, from, next) => { //new tab
  //     document.title = VueI18n.t('system.register')
  //     next()
  //   }
  // },
  {
    path: '/forgot-password',
    component: () => import('../views/Auth/ForgotPassword.vue'),
    name: 'ForgotPassword',
    meta: {
      description: 'system.forgot_password',
    },
    beforeEnter: (to, from, next) => { //new tab
      document.title = VueI18n.t('system.forgot_password');
      next();
    },
  },
  {
    path: '/reset-password/:token/:email',
    component: () => import('../views/Auth/ResetPassword.vue'),
    name: 'ResetPassword',
    meta: {
      description: 'system.reset_password',
    },
    beforeEnter: (to, from, next) => { //new tab
      document.title = VueI18n.t('system.reset_password');
      next();
    },
  },
];
