import axios from 'axios';

const trackers = {
  namespaced: true,
  state: {
    trackers: [],
  },
  mutations: {
    SET_TRACKERS(state, {trackers}) {
      state.trackers = trackers;
    },
  },
  actions: {
    fetchTrackers({commit}) {
      return axios.get('trackers', {
        params: {
          submodule: 'Issue',
        },
      }).then((response) => {
        commit('SET_TRACKERS', {trackers: response.data.data});
      });
    },
  },
};

export default trackers;
