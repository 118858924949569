import moment from 'moment';

const padTime = (time, length = 2) => {
  return String(time).padStart(length, '0');
};
const hours = (sec) => {
  return padTime(Math.floor(sec / 3600));
};
const minutes = (sec) => {
  return padTime(Math.floor(sec / 60) - hours(sec) * 60); //60
};
const seconds = (sec) => {
  return padTime(sec - (minutes(sec) * 60) - (hours(sec) * 3600));
};
const trimTime = (time) => {
  let newTime = time;
  while (newTime.startsWith('0')) {
    newTime = newTime.substr(1);
  }
  return newTime;
};

//-----------------------EXPORTS----------------------------------------

export const diff = (dateTime) => {
  if (dateTime) {
    return moment().unix() - moment.utc(dateTime).unix();
  }
  return 0;
};

export const timeDiff = (startTime, endTime, inputFormat, outputFormat = null) => {
  const start = moment.utc(startTime, inputFormat);
  const end = moment.utc(endTime, inputFormat);

  const diff = moment.duration(end.diff(start));

  return moment.utc(+diff).format(outputFormat || inputFormat);
};

export const timeToDecimal = (time) => {
  return moment.duration(time).asHours();
};

export const secondsToHHmmss = (sec) => {
  return `${hours(sec)}:${minutes(sec)}:${seconds(sec)}`;
};

export const HHHmmToHHmmss = (HHHmm) => {
  const ss = '00';
  if (HHHmm.length < 4) {
    const mm = '00';
    return `${padTime(trimTime(HHHmm))}:${mm}:${ss}`;
  } else {
    let [HHH, mm] = HHHmm.split(':');
    return `${padTime(trimTime(HHH))}:${padTime(mm)}:${ss}`;
  }
};
export const HHmmssToHHHmm = (HHmmss) => {
  if (HHmmss) {
    const [hh, mm] = HHmmss.split(':');
    return `${padTime(hh, 3)}:${mm}`;
  }
  return '';
};
export const HHmmssToNormalTime = (HHmmss) => {
  if (HHmmss) {
    let [hh, mm] = HHmmss.split(':');
    if (hh[0] === '0') {
      hh = hh.substring(1);
    }
    if (mm[0] === '0') {
      mm = mm.substring(1);
    }
    return `${hh}h ${mm}m`;
  }
  return '';
};
