import VueI18n from '../config/i18n';

export default [
  {
    path: '/gifts',
    name: 'Gifts',
    component: () => import('../views/Gifts/Index.vue'),
    props: true,
    meta: {
      description: VueI18n.t('system.gifts'),
      permissions: ['gifts-read'],
    },
  },
];
