import _ from 'lodash';
import axios from 'axios';
import moment from 'moment';
import {getLocalStorage, setLocalStorage} from '../../helpers/localStorage';

const unsettled_reports_stash = {
  namespaced: true,
  state: {
    timeEntriesIdColumn: 'time_entries.id',
    timeEntries: [],
    allTimeEntriesIds: [],
    timeEntriesListParams: {
      column: null,
      direction: 'desc',
      page: 1,
      per_page: 10,
    },
    timeEtriesFilters: [
      {
        dictionary: {
          name: '',
          isRemote: false,
        },
        label: 'system.issue_status',
        name: 'issueStatuses',
        type: 'multiSelect',
        value: [],
        values: [],
      },
      {
        dictionary: {
          name: '',
          isRemote: false,
        },
        label: 'system.date_range',
        name: 'dateRange',
        type: 'dateRangePicker',
        value: [],
        values: [],
      },
    ],
    // {
    //   issueStatuses: {
    //     checked: false,
    //     value: [],
    //     options: []
    //   },
    //   dateRange: {
    //     checked: false,
    //     value: []
    //   }
    // },
    timeEntriesIds: [],
    timeEntriesListSearchString: '',
    totalTimeEntriesCount: 0,
    selectedTimeEntriesIds: [],
    timeEntriesThead: [],
    selectedTimeEntriesThead: [
      'time_entries.id',
      'time_entries.comments',
    ],
    timeSumOfAllTimeEntries: 0,
    timeSumOfSelectedTimeEntries: 0,
  },
  getters: {
    timeEntries: (state) => state.timeEntries,
    allTimeEntriesIds: (state) => state.allTimeEntriesIds,
    timeEntriesIds: (state) => state.timeEntriesIds,
    timeEntriesListParams: (state) => {
      return {
        ...state.timeEntriesListParams,
        column: state.selectedTimeEntriesThead.length ? state.selectedTimeEntriesThead[0] : 'time_entries.id',
      };
    },
    timeEtriesFilters: (state) => state.timeEtriesFilters,
    timeEntriesListSearchString: (state) => state.timeEntriesListSearchString,
    totalTimeEntriesCount: (state) => state.totalTimeEntriesCount,
    selectedTimeEntriesIds: (state) => state.selectedTimeEntriesIds,
    countOfSelectedTimeEntries: (state) => state.selectedTimeEntriesIds.length,
    timeEntriesThead: (state) => state.timeEntriesThead,
    selectedTimeEntriesThead: (state) => state.selectedTimeEntriesThead,
    isCheckedAllTimeEntries: (state) => {
      if (state.timeEntries.length === 0) {
        return false;
      } else {
        const diffArray = _.difference(state.allTimeEntriesIds, state.selectedTimeEntriesIds);
        return !(diffArray.length > 0);
      }
    },
    timeSumOfSelectedTimeEntries: (state) => state.timeSumOfSelectedTimeEntries,
  },
  mutations: {
    SET_TIME_ENTRIES: (state, timeEntries) => {
      state.timeEntries = timeEntries;
    },
    SET_ALL_TIME_ENTRIES_IDS: (state, ids) => {
      state.allTimeEntriesIds = ids;
    },
    SET_TIME_ENTRIES_IDS: (state, timeEntriesIds) => {
      state.timeEntriesIds = timeEntriesIds;
    },
    SET_NEW_TIME_ENTRIES_IDS: (state, ids) => {
      state.timeEntriesIds = [...state.timeEntriesIds, ...ids];
    },
    // REMOVE_TIME_ENTRIES_IDS: (state, ids) => {
    //   console.log('state.timeEntriesIds', state.timeEntriesIds)
    //   console.log('_.difference(state.timeEntriesIds, ids)', _.difference(state.timeEntriesIds, ids))
    //   // state.timeEntriesIds = _.difference(state.timeEntriesIds, ids)
    // },
    SET_NEW_SELECTED_TIME_ENTRY_ID: (state, id) => {
      state.selectedTimeEntriesIds.push(id);
    },
    REMOVE_SELECTED_TIME_ENTRY_ID: (state, id) => {
      const index = state.selectedTimeEntriesIds.indexOf(id);
      state.selectedTimeEntriesIds.splice(index, 1);
    },
    SUBTRACT_TIME_FROM_TOTAL_TIME_SUM(state, id) {
      const time = state.timeEntries.find(item => item[state.timeEntriesIdColumn] === id);
      if (time['time_entries.hours']) {
        state.timeSumOfSelectedTimeEntries -= moment.duration(time['time_entries.hours']).asSeconds();
      }
    },
    ADD_TIME_TO_TOTAL_TIME_SUM(state, id) {
      const time = state.timeEntries.find(item => item[state.timeEntriesIdColumn] === id);
      if (time['time_entries.hours']) {
        state.timeSumOfSelectedTimeEntries += moment.duration(time['time_entries.hours']).asSeconds();
      }
    },
    SET_TIME_ENTRIES_THEAD: (state, thead) => {
      state.timeEntriesThead = thead;
    },
    SET_TIME_SUM_OF_SELECTED_TIME_ENTRIES(state, seconds) {
      state.timeSumOfSelectedTimeEntries = seconds;
    },
    SET_TIME_SUM_OF_ALL_TIME_ENTRIES(state, seconds) {
      state.timeSumOfAllTimeEntries = seconds;
    },
    SET_ISSUE_STATUSES_OPTIONS_IN_TIME_ENTRIES_FILTERS: (state, data) => {
      const candidateIdx = state.timeEtriesFilters.findIndex(item => item.name === 'issueStatuses');
      if (candidateIdx >= 0) {
        state.timeEtriesFilters.splice(
          candidateIdx,
          1,
          {...state.timeEtriesFilters[candidateIdx], values: data},
        );
      }
    },
    SET_CHECKED_ALL_TIME_ENTRIES: (state, bool) => {
      if (bool) {
        state.selectedTimeEntriesIds = [...state.allTimeEntriesIds];
      } else {
        state.selectedTimeEntriesIds = [];
      }
    },
    SET_SELECTED_TIME_ENTRIES_THEAD: (state, columns) => {
      state.selectedTimeEntriesThead = columns;
    },
    SET_TOTAL_TIME_ENTRIES_COUNT: (state, count) => {
      state.totalTimeEntriesCount = count;
    },
    SET_TIME_ENTRIES_LIST_PARAMS: (state, params) => {
      state.timeEntriesListParams = params;
    },
    SET_TIME_ENTRIES_LIST_SEARCH_STRING: (state, str) => {
      state.timeEntriesListSearchString = str;
    },
    CLEAR_ALL_TIME_ENTRIES_SELECTIONS: (state) => {
      state.selectedTimeEntriesIds = [];
    },
    CLEAR_TIME_ENTRIES_FILTERS: (state) => {
      state.timeEtriesFilters = state.timeEtriesFilters.map(item => ({...item, value: null}));
    },
    SET_FILTERS(state, filters) {
      state.timeEtriesFilters = filters;
    },
  },
  actions: {
    clearTimeEntriesFilters(context) {
      context.commit('CLEAR_TIME_ENTRIES_FILTERS');
    },
    setTimeEntriesListSearchString(context, str) {
      context.commit('SET_TIME_ENTRIES_LIST_SEARCH_STRING', str);
    },
    // setTimeEntriesIds(context, timeEntriesIds) {
    //   context.commit('SET_TIME_ENTRIES_IDS', timeEntriesIds)
    // },
    setTimeEntriesListParams(context, params) {
      context.commit('SET_TIME_ENTRIES_LIST_PARAMS', params);
    },
    async removeTimeEntriesIds(context, ids) {
      context.commit('REMOVE_TIME_ENTRIES_IDS', ids);
    },
    async setTimeEntrySelectionChange(context, timeEntryId) {
      if (context.getters.selectedTimeEntriesIds.includes(timeEntryId)) {
        context.commit('REMOVE_SELECTED_TIME_ENTRY_ID', timeEntryId);
        context.commit('SUBTRACT_TIME_FROM_TOTAL_TIME_SUM', timeEntryId);
      } else {
        context.commit('SET_NEW_SELECTED_TIME_ENTRY_ID', timeEntryId);
        context.commit('ADD_TIME_TO_TOTAL_TIME_SUM', timeEntryId);
      }
    },
    selectAllTimeEntries(context, bool) {
      context.commit('SET_CHECKED_ALL_TIME_ENTRIES', bool);
      context.commit('SET_TIME_SUM_OF_SELECTED_TIME_ENTRIES', bool ? context.state.timeSumOfAllTimeEntries : 0);
    },
    clearAllTimeEntriesSelections(context) {
      context.commit('CLEAR_ALL_TIME_ENTRIES_SELECTIONS');
    },
    async fetchIssueStatuses(context) {
      return axios.get('issue_statuses')
        .then((response) => {
          context.commit('SET_ISSUE_STATUSES_OPTIONS_IN_TIME_ENTRIES_FILTERS', response.data.map(item => ({
            ...item,
            name: item.label,
          })));
        });
    },
    setTimeSumOfSelectedTimeEntries(context, seconds) {
      context.commit('SET_TIME_SUM_OF_SELECTED_TIME_ENTRIES', seconds);
    },
    settleTimeEntries(context, data) {
      return axios.post('settlement', {
        name: data.name,
        timeEntries: data.ids,
      });
    },
    fetchTimeEntriesIds(context) {
      return axios.get('stash_time_entries_ids', {
        params: {
          project_id: context.rootGetters.currentProjectId,
        },
      }).then((response) => {
        context.commit('SET_TIME_ENTRIES_IDS', response.data);
      });
    },
    setFilters({commit}, {filters}) {
      commit('SET_FILTERS', filters);
    },
    fetchTimeEntries(context, {timeEntriesIds}) {
      const requestFilters = {};
      context.state.timeEtriesFilters.forEach(item => {
        console.log('item', item);
        if (item.type === 'checkbox' || item.type === 'switch') {
          requestFilters[item.name] = !!item.value;
        } else {
          requestFilters[item.name] = item.value;
        }
      });
      return axios.post('time_entries', {
        ...context.getters.timeEntriesListParams,
        timeEntriesIds,
        columns: context.getters.selectedTimeEntriesThead,
        search: context.getters.timeEntriesListSearchString,
        filters: requestFilters,
      }).then((response) => {
        context.commit('SET_TIME_ENTRIES', response.data.data);
        context.commit('SET_ALL_TIME_ENTRIES_IDS', response.data.allIds);
        context.commit('SET_TIME_SUM_OF_ALL_TIME_ENTRIES', response.data.allTimesSum);
        context.commit('SET_TOTAL_TIME_ENTRIES_COUNT', response.data.total);
      });
    },
    setSelectedTimeEntriesThead(context, columns) {
      context.commit('SET_SELECTED_TIME_ENTRIES_THEAD', columns);
      const reportColumns = getLocalStorage('reportColumns');
      reportColumns.stash = columns;
      setLocalStorage('reportColumns', reportColumns);
    },
  },
};
export default unsettled_reports_stash;
