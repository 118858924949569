// import ProjectIndex from '../views/Project/Index.vue'
// import ProjectMenu from '../views/layout/components/Sidebar/ProjectMenu.vue'
// import ProjectForm from '../views/Project/Form.vue'
// import CreateProject from '../views/Project/CreateProject.vue'
// import ActivityIndex from '../views/Project/Activity.vue'
// import ProjectOverview from '../views/Project/Overview.vue'
// import MoneyIndex from '../views/Money/Index.vue'
// import MemberIndex from '../views/Member/Index.vue'
// import ReportIndex from '../views/Report/Index.vue'
// import ReportIndex_v2 from '../views/Report_v2/Index.vue'
// import ClientInvoices from '../views/Invoice/ClientInvoices.vue'
// import IssueModal from '../components/Table/Issue.vue'
// import ListTracker from '../views/ListTracker/index.vue'
import VueI18n from '../config/i18n';
import {andGuard} from '../guards/guards';

export default [
  {
    path: '/project',
    component: () => import('../views/Project/Index.vue'),
    name: 'Project',
    beforeEnter: andGuard,
    meta: {
      description: VueI18n.t('system.projects'),
      breadcrumb: [{name: 'Project', title: 'breadcrumb.client_list'}],
      permissions: ['project-list'],
    },
  },
  {
    path: '/project/create',
    component: () => import('../views/Project/CreateProject.vue'),
    name: 'Create Project',
    beforeEnter: andGuard,
    meta: {
      description: VueI18n.t('system.create_project'),
      breadcrumb: [{name: 'Create Project', title: 'breadcrumb.client_list'}],
      mode: 'create',
      permissions: ['project-create'],
    },
  },
  {
    path: '/project/:projectId',
    component: () => import('../views/layout/components/Sidebar/ProjectMenu.vue'),
    name: 'Project Menu',
    props(route) {
      const props = {...route.params};
      props.projectId = +props.projectId;
      return props;
    },
    beforeEnter: andGuard,
    meta: {
      description: VueI18n.t('system.Project'),
      permissions: ['pm-member'],
    },
    children: [
      {
        path: '',
        component: () => import('../views/Project/Overview.vue'),
        name: 'Overview',
        beforeEnter: andGuard,
        meta: {
          description: VueI18n.t('system.overview'),
          breadcrumb: [{name: 'Project', title: 'breadcrumb.client_list'}],
          permissions: ['pm-member'],
        },
      },
      {
        path: 'list-tracker/:idTracker',
        component: () => import('../views/ListTracker/index.vue'),
        name: 'List Tracker in Project',
        props: true,
        meta: {
          description: VueI18n.t('system.list-tracker'),
          breadcrumb: [{name: VueI18n.t('system.list-tracker'), title: 'breadcrumb.list-tracker'}],
        },
      },
      {
        path: 'create',
        component: () => import('../views/Project/CreateProject.vue'),
        //TODO: change translation
        name: 'Create SubProject',
        beforeEnter: andGuard,
        meta: {
          description: VueI18n.t('system.create_subproject'),
          breadcrumb: [{name: 'Project', title: 'breadcrumb.client_list'}, {
            name: 'Create SubProject',
            title: 'breadcrumb.create_subproject',
          }],
          mode: 'create',
          permissions: ['pm-create-subproject'],
        },
      },
      {
        path: 'activity',
        component: () => import('../views/Project/Activity.vue'),
        //TODO: change translation
        name: 'Project Activity',
        beforeEnter: andGuard,
        meta: {
          description: VueI18n.t('system.activity'),
          breadcrumb: [{name: 'Project', title: 'breadcrumb.client_list'}, {
            name: 'Project Activity',
            title: 'breadcrumb.activity',
          }],
          permissions: ['pm-view-activities'],
        },
      },
      {
        path: 'member',
        component: () => import('../views/Member/Index.vue'),
        name: 'Members',
        beforeEnter: andGuard,
        meta: {
          description: VueI18n.t('system.members'),
          breadcrumb: [{name: 'Project', title: 'breadcrumb.client_list'}, {
            name: 'Members',
            title: 'breadcrumb.members',
          }],
          permissions: ['pm-member-read'],
        },
      },
      {
        path: 'money',
        component: () => import('../views/Money/Index.vue'),
        name: 'Money',
        meta: {
          description: VueI18n.t('system.money'),
          breadcrumb: [{name: 'Project', title: 'breadcrumb.client_list'}],
        },
      },
      {
        path: 'report_v2',
        component: () => import('../views/Report_v2/Index.vue'),
        name: 'Report_v2',
        beforeEnter: andGuard,
        meta: {
          description: VueI18n.t('system.report'),
          breadcrumb: [{name: 'Project', title: 'breadcrumb.client_list'},
            {
              name: 'Report',
              title: 'breadcrumb.reports',
            }],
          permissions: ['pm-generate-reports'],
        },
      },
      {
        path: 'invoice',
        component: () => import('../views/Invoice/ClientInvoices.vue'),
        name: 'ClientInvoices',
        meta: {
          description: VueI18n.t('system.invoice'),
          breadcrumb: [{name: 'Project', title: 'breadcrumb.client_list'}, {
            name: 'ClientInvoices', title: VueI18n.t('system.invoice'),
          }],
        },
      },
    ],
  },
];
