export default [
  {
    path: '/time_logger',
    component: () => import('../views/Timelogger/Index.vue'),
    name: 'TimeLogger Index',
    meta: {
      description: 'List Time',
      breadcrumb: [{name: 'TimeLogger Index', title: 'breadcrumb.time_logger'}],
      permissions: ['admin'],
    },
  },
];
