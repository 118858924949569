import {mapObjToObj} from '@/helpers/functions';
import {timeDiff, timeToDecimal} from '@/helpers/time';

const emptyDriveAndLaborTime = {
  technician_contractor_id: null,
  labor_date: null,
  drive_from_hours: null,
  drive_to_hours: null,
  labor_from_hours: null,
  labor_to_hours: null,
  drive_rate: 0,
  labor_rate: 0,
  drive_billable: false,
  labor_billable: false,
  totalDrive: 0,
  totalLabor: 0,
};

const emptyExpense = {
  id: null,
  expense_date: null,
  expense_category_id: null,
  purchased_from: '',
  amount: 0,
  billable: false,
  reimburse_to: null,
  note: '',
  files: [],
  filesCount: 0,
  isSaved: false,
};

const workOrderPayments = {
  namespaced: true,
  state: {
    workOrders: {
      // 8: {
      //   id: 8,
      //   note: '',
      //   services: [],
      //   driveAndLaborTimes: [],
      //   expenses: []
      // }
    },
  },
  getters: {
    totalPayment: state => workOrderId => {
      const workOrder = state.workOrders[workOrderId];
      const total = {
        services: 0,
        driveAndLaborTimes: 0,
        expenses: 0,
      };
      if (workOrder) {
        total.services = workOrder.services.reduce((sum, currentService) => sum + currentService.total, 0);
        total.driveAndLaborTimes = workOrder.driveAndLaborTimes.reduce((sum, currentService) =>
          sum + (currentService.drive_billable ? currentService.totalDrive : 0) + (currentService.labor_billable ? currentService.totalLabor : 0), 0);
        total.expenses = workOrder.expenses.reduce((sum, currentExp) =>
          currentExp.billable ? sum + currentExp.amount : sum, 0);
      }
      return total;
    },
    workOrder: state => workOrderId => {
      return state.workOrders[workOrderId];
    },
    workOrderServices: state => workOrderId => {
      return state.workOrders[workOrderId]?.services || [];
    },
    paymentNote: state => workOrderId => {
      return state.workOrders[workOrderId]?.note || '';
    },
    workOrderDriveAndLaborTimes: state => workOrderId => {
      return state.workOrders[workOrderId]?.driveAndLaborTimes || [];
    },
    workOrderExpenses: state => workOrderId => {
      return state.workOrders[workOrderId]?.expenses || [];
    },
  },
  mutations: {
    ADD_WORK_ORDER(state, {workOrderId}) {
      state.workOrders = {
        ...state.workOrders,
        [workOrderId]: {
          services: [],
          driveAndLaborTimes: [],
          expenses: [],
        },
      };
    },
    REMOVE_ITEM(state, {id, workOrderId, type}) {
      const workOrder = state.workOrders[workOrderId];
      if (workOrder) {
        workOrder[type] = workOrder[type].filter(item => item.id !== id);
      }
    },
    ADD_ITEM(state, {item, workOrderId, type}) {
      const workOrder = state.workOrders[workOrderId];
      if (!item.id) {
        let id = 1;
        if (workOrder[type].length) {
          const lastItem = workOrder[type][workOrder[type].length - 1];
          id = lastItem.id + 1;
        }
        workOrder[type] = [...workOrder[type], {...item, id}];
      } else {
        workOrder[type] = [...workOrder[type], {...item}];
      }
    },
    SET_PAYMENT_NOTE(state, {workOrderId, text}) {
      state.workOrders[workOrderId] = {...state.workOrders[workOrderId], note: text};
    },
  },
  actions: {
    addServiceItem({commit, state}, {service, workOrderId}) {
      if (!state.workOrders[workOrderId]) {
        commit('ADD_WORK_ORDER', {workOrderId});
      }
      const preparedService = {
        name: service.name || '',
        description: service.description || '',
        category_id: service.category_id || null,
        status: service.status || null,
        hours: service.hours || 0,
        rate: service.rate || 0,
        rate_description: service.rate_description || '',
        total: Math.floor(service.hours * service.rate),
      };
      commit('ADD_ITEM', {
        item: preparedService,
        workOrderId,
        type: 'services',
      });
    },
    addExpense({commit, state}, {item, workOrderId}) {
      if (!state.workOrders[workOrderId]) {
        commit('ADD_WORK_ORDER', {workOrderId});
      }
      commit('ADD_ITEM', {
        item: item
              ? {
            ...mapObjToObj(emptyExpense, {
              ...item,
              amount: Number(item.amount),
              isSaved: true,
              billable: !!item.billable,
            }),
          }
              : {...emptyExpense},
        workOrderId,
        type: 'expenses',
      });
    },
    addDriveAndLaborTime({commit, state}, {item, workOrderId}) {
      if (!state.workOrders[workOrderId]) {
        commit('ADD_WORK_ORDER', {workOrderId});
      }

      const preparedItem = item ? {...mapObjToObj(emptyDriveAndLaborTime, item)} : {...emptyDriveAndLaborTime};

      if (item) {
        const driveTimeDiff = timeDiff(item.drive_from_hours, item.drive_to_hours, 'HH:mm', 'HH:mm');
        preparedItem.totalDrive = Math.round(timeToDecimal(driveTimeDiff) * item.drive_rate * 100) / 100;

        const laborTimeDiff = timeDiff(item.labor_from_hours, item.labor_to_hours, 'HH:mm', 'HH:mm');
        preparedItem.totalLabor = Math.floor(timeToDecimal(laborTimeDiff) * item.labor_rate * 100) / 100;
      }

      commit('ADD_ITEM', {
        item: preparedItem,
        workOrderId,
        type: 'driveAndLaborTimes',
      });
    },

    removeServiceItem({commit}, {serviceId, workOrderId}) {
      commit('REMOVE_ITEM', {id: serviceId, workOrderId, type: 'services'});
    },
    removeDriveAndLaborTime({commit}, {driveAndLaborId, workOrderId}) {
      commit('REMOVE_ITEM', {id: driveAndLaborId, workOrderId, type: 'driveAndLaborTimes'});
    },
    removeExpense({commit}, {expenseId, workOrderId}) {
      commit('REMOVE_ITEM', {id: expenseId, workOrderId, type: 'expenses'});
    },

    setPaymentNote({commit, state}, {workOrderId, text}) {
      if (!state.workOrders[workOrderId]) {
        commit('ADD_WORK_ORDER', {workOrderId});
      }
      commit('SET_PAYMENT_NOTE', {workOrderId, text});
    },
  },
};

export default workOrderPayments;
