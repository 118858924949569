import VueI18n from '../config/i18n';
import {andGuard} from '../guards/guards';

export default [
  {
    path: '/settlements',
    component: () => import('../views/Settlements/index.vue'),
    name: 'SettlementsIndex',
    beforeEnter: andGuard,
    meta: {
      description: VueI18n.t('system.settlements'),
      breadcrumb: [{name: VueI18n.t('system.settlements'), title: 'breadcrumb.settlements'}],
      permissions: ['pm-generate-reports'],
    },
  },
];
