import Vue from 'vue';
import module from './module';

const install = function (Vue) {
  Vue.directive('module', module);
};

// if (window.Vue) {
window['module'] = module;
Vue.use(install); // eslint-disable-line
// }

module.install = install;
export default module;
