<template>
  <div>
    <div class="d-flex justify-content-between">
      <el-button
          @click="openCreateContractOrInsurance"
          size="small"
          type="primary"
      >
        <font-awesome-icon
            class="mr-1"
            icon="plus"
        />
        {{ $t('system.contract_or_insurance') }}
      </el-button>
      <DebounceInput
          :placeholder="$t('system.search')"
          @change="fetchData"
          clearable
          size="small"
          style="max-width: 220px"
          v-model="params.search"
      />
    </div>
    <el-divider />
    <DataTable
        :headers="headers"
        :idPath="tableIdentifierColumn"
        :loading="loading"
        :model="data"
        :params.sync="params"
        @clickRow="onClickRow"
        @updateData="fetchData"
    />
    <ContractAndInsuranceForm
        :backend-errors="contractOrInsuranceBackedErrors"
        :data="currentContractOrInsurance"
        :is-visible="isVisibleForm"
        :save-loading="saveLoading"
        @close="closeContractOrInsurance"
        @save="saveContractOrInsurance"
    />
    <ContractAndInsuranceShow
        :data="currentContractOrInsurance"
        :is-visible="isVisibleShow"
        @close="closeContractOrInsurance"
        @edit="openEditForm"
        @remove="removeContractOrInsurance"
    />
  </div>
</template>

<script>
import axios from 'axios';
// import moment from 'moment';
import DebounceInput from '../DebounceInput.vue';
import DataTable from '../Table/DataTable.vue';
import notification from '../../notification/notify';
import ContractAndInsuranceForm from './ContractAndInsuranceForm';
import ContractAndInsuranceShow from './ContractAndInsuranceShow';

export default {
  name: 'ContractsAndInsurancesList',
  props: {
    userId: {
      type: [Number, String],
    },
  },
  components: {
    ContractAndInsuranceShow,
    ContractAndInsuranceForm,
    DataTable,
    DebounceInput,
  },
  data() {
    return {
      headers: [
        {
          key: 'name',
          label: 'system.name',
        },
        {
          key: 'type', // contract or insurance
          label: 'system.type',
        },
        {
          key: 'from',
          label: 'system.start_at',
        },
        {
          key: 'to',
          label: 'system.end_at',
        },
      ],
      data: [],
      params: {
        search: '',
        column: 'id',
        direction: 'desc',
        per_page: 10,
        page: 1,
        total: 0,
        per_page_sizes: [10, 30, 50, 100],
      },
      loading: false,
      tableIdentifierColumn: 'id',
      isVisibleForm: false,
      isVisibleShow: false,
      currentContractOrInsurance: {},
      saveLoading: false,
      contractOrInsuranceBackedErrors: {},
    };
  },
  beforeMount() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      this.loading = true;
      try {
        const {data} = await axios.get(`workorders/${this.userId}/contracts`, {
          params: this.params,
        });
        this.data = data.data.map(item => ({
          ...item,
          to: item.to ? item.to : 'system.timeless_agreement',
        }));
        this.params.total = data.total;
      } catch (e) {
        this.loading = false;
        notification.notify(
            this.$t('notify.error'),
            this.$t('system.can_not_load'),
            'error');
      } finally {
        this.loading = false;
      }
    },
    async openEditForm(id) {
      this.isVisibleShow = false;
      await this.fetchContractOrInsurance(id);
      // setTimeout(async () => {
      this.isVisibleForm = true;
      // }, 300)
    },
    async fetchContractOrInsurance(id) {
      const {data} = await axios.get(`workorders/contract/${id}`);
      this.currentContractOrInsurance = data.data;
    },
    async openShowModal(id) {
      this.isVisibleShow = true;
      try {
        await this.fetchContractOrInsurance(id);
      } catch (e) {
        this.isVisibleShow = false;
        notification.notify(
            this.$t('notify.error'),
            this.$t('system.can_not_load'),
            'error');
      }
    },
    openCreateContractOrInsurance() {
      this.isVisibleForm = true;
    },
    async saveContractOrInsurance(form) {
      this.saveLoading = true;
      try {
        let method = 'post';
        let url = 'workorders/contract';
        if (this.currentContractOrInsurance && this.currentContractOrInsurance.id) {
          method = 'put';
          url = `workorders/contract/${this.currentContractOrInsurance.id}`;
        }
        const formClone = {...form, user_id: this.userId};
        const {data} = await axios[method](url, formClone);
        this.closeContractOrInsurance();
        setTimeout(async () => {
          await this.openShowModal(data.id);
        }, 300);
        await this.fetchData();
      } catch (error) {
        this.contractOrInsuranceBackedErrors = error.response.data;
      } finally {
        this.saveLoading = false;
      }
    },
    closeContractOrInsurance() {
      this.isVisibleForm = false;
      this.isVisibleShow = false;
      this.currentContractOrInsurance = {};
      this.contractOrInsuranceBackedErrors = {};
    },
    onClickRow(id) {
      this.openShowModal(id);
    },
    async removeContractOrInsurance(id) {
      try {
        await axios.delete(`workorders/contract/${id}`);
        this.closeContractOrInsurance();
        await this.fetchData();
      } catch (e) {
        notification.notify(
            this.$t('notify.error'),
            this.$t('system.can_not_remove'),
            'error');
      }
    },
  },
};
</script>

<style scoped>

</style>
