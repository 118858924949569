<template>
  <div>
    <div class="view-container">
      <div class="view">
        <div
            class="view-heading d-flex align-items-center"
            style="padding-bottom: 20px;"
        >
          <div class="view-title">
            {{ model.first_name }} {{ model.last_name }}
          </div>
          <div class="ml-auto">
            <span v-if="model.deleted_at == null">
              <el-button
                  @click.prevent.stop="edit(model.id)"
                  type="info"
                  v-if="ifHasPermission(['edit-user'])"
              >
                  {{ $t('system.edit') }}
              </el-button>
              <el-button
                  @click.prevent.stop="remove"
                  type="danger"
                  v-if="ifHasPermission(['delete-user'])"
              >
                  {{ $t('system.unactivate') }}
                </el-button>
            </span>
            <el-button
                @click.prevent.stop="activate"
                type="success"
                v-else-if="ifHasPermission(['delete-user'])"
            >
              {{ $t('system.activate') }}
            </el-button>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-6">
        <div class="view-container">
          <div class="view">
            <div class="view-heading">
              <div class="view-title">
                {{ $t('users.information') }}
              </div>
            </div>
            <div class="view-body">
              <table class="table">
                <tbody>
                <tr class="row m-0">
                  <td
                      class="d-inline-block col-4 pl-0"
                      style="border: none;"
                  ><strong>{{ $t('users.last_login') }}:</strong></td>
                  <td
                      class="d-inline-block col-8"
                      style="border: none;"
                  >{{ model.last_login }}
                  </td>
                </tr>
                <tr class="row m-0">
                  <td class="d-inline-block col-4 pl-0"><strong>{{ $t('users.username') }}:</strong></td>
                  <td class="d-inline-block col-8">{{ model.username }}</td>
                </tr>
                <tr class="row m-0">
                  <td class="d-inline-block col-4 pl-0"><strong>{{ $t('users.name') }}:</strong></td>
                  <td class="d-inline-block col-8">{{ model.name }}</td>
                </tr>
                <tr class="row m-0">
                  <td class="d-inline-block col-4 pl-0"><strong>{{ $t('users.first_name') }}:</strong></td>
                  <td class="d-inline-block col-8">{{ model.first_name }}</td>
                </tr>
                <tr class="row m-0">
                  <td class="d-inline-block col-4 pl-0"><strong>{{ $t('users.last_name') }}:</strong></td>
                  <td class="d-inline-block col-8">{{ model.last_name }}</td>
                </tr>
                <tr class="row m-0">
                  <td class="d-inline-block col-4 pl-0"><strong>{{ $t('users.email') }}:</strong></td>
                  <td class="d-inline-block col-8">{{ model.email }}</td>
                </tr>
                <tr class="row m-0">
                  <td class="d-inline-block col-4 pl-0"><strong>{{ $t('users.gender') }}:</strong></td>
                  <td class="d-inline-block col-8">{{ $t(`system.${model.user_detail.gender}`) }}</td>
                </tr>
                <tr class="row m-0">
                  <td class="d-inline-block col-4 pl-0"><strong>{{ $t('users.phone') }}:</strong></td>
                  <td class="d-inline-block col-8">
                    <a
                        :href="`tel:${item}`"
                        :key="index"
                        v-for="(item, index) in phoneNumbers"
                    >
                      {{ index === phoneNumbers.length - 1 ? item : `${item}, ` }}
                    </a>
                  </td>
                </tr>
                <tr
                    class="row m-0"
                    v-if="model.roles"
                >
                  <td
                      class="d-inline-block col-4 pl-0"
                  ><strong>{{ $t('users.role') }}:</strong>
                  </td>
                  <td
                      class="d-inline-block col-8"
                  >
                    <p
                        :key="role.id"
                        class="m-0"
                        v-for="role in model.roles"
                    >{{ role ? role.name : '' }}</p>
                  </td>
                </tr>
                <tr class="row m-0">
                  <td class="d-inline-block col-4 pl-0"><strong>{{ $t('users.contractor') }}:</strong></td>
                  <td class="d-inline-block col-8">
                    {{
                      model.account && model.account.length && model.account[0].name ? model.account[0].name : $t('system.no_data')
                    }}
                  </td>
                </tr>
                <tr class="row m-0">
                  <td class="d-inline-block col-4 pl-0"><strong>{{ $t('users.admin') }}:</strong></td>
                  <td class="d-inline-block col-8">{{
                      model.admin ? $t('system.yes') : typeof model.admin === 'undefined' ? $t('system.no_data') : $t('system.no')
                                                   }}
                  </td>
                </tr>
                <tr class="row m-0">
                  <td class="d-inline-block col-4 pl-0"><strong>{{ $t('users.subcontractor') }}:</strong></td>
                  <td class="d-inline-block col-8">{{
                      model.subcontractor ? $t('system.yes') : typeof model.subcontractor === 'undefined' ? $t('system.no_data') : $t('system.no')
                                                   }}
                  </td>
                </tr>
                <!--                <tr class="row m-0">-->
                <!--                  <td class="d-inline-block col-4 pl-0"><strong>{{ $t('users.client') }}:</strong></td>-->
                <!--                  <td class="d-inline-block col-8">{{model.client? $t('system.yes') : $t('system.no') }}</td>-->
                <!--                </tr>-->
                <tr class="row m-0">
                  <td class="d-inline-block col-4 pl-0"><strong>{{ $t('users.full_time') }}:</strong></td>
                  <td class="d-inline-block col-8">{{
                      model.user_detail.full_time ? $t('system.yes') : typeof model.user_detail.full_time === 'undefined' ? $t('system.no_data') : $t('system.no')
                                                   }}
                  </td>
                </tr>
                <tr class="row m-0">
                  <td class="d-inline-block col-4 pl-0"><strong>{{ $t('users.department') }}:</strong></td>
                  <td class="d-inline-block col-8">{{ model.user_detail.department }}</td>
                </tr>
                <!--                                    <tr class="row m-0" v-if="model.title">-->
                <!--                                        <td class="d-inline-block col-4 pl-0"><strong>{{ $t('users.title') }}:</strong></td>-->
                <!--                                        <td class="d-inline-block col-8">{{model.title}}</td>-->
                <!--                                    </tr>-->
                <!--                                    <tr class="row m-0">-->
                <!--                                        <td class="d-inline-block col-4 pl-0"><strong>{{ $t('users.admin') }}:</strong></td>-->
                <!--                                        <td class="d-inline-block col-8">{{model.admin? $t('system.yes') : $t('system.no') }}</td>-->
                <!--                                    </tr>-->
                <!--                                    <tr class="row m-0">-->
                <!--                                      <td class="d-inline-block col-4 pl-0"><strong>{{ $t('users.subcontractor') }}:</strong></td>-->
                <!--                                      <td class="d-inline-block col-8">{{model.subcontractor? $t('system.yes') : $t('system.no') }}</td>-->
                <!--                                    </tr>-->
                <!--                                    <tr class="row m-0">-->
                <!--                                        <td class="d-inline-block col-4 pl-0"><strong>{{ $t('users.client') }}:</strong></td>-->
                <!--                                        <td class="d-inline-block col-8">{{model.client? $t('system.yes') : $t('system.no') }}</td>-->
                <!--                                    </tr>-->
                <!--                                    <tr class="row m-0" v-if="model.language">-->
                <!--                                        <td class="d-inline-block col-4 pl-0"><strong>{{ $t('users.language') }}:</strong></td>-->
                <!--                                        <td class="d-inline-block col-8">{{model.language ? model.language.name : ''}}</td>-->
                <!--                                    </tr>-->
                <!--                                    <tr class="row m-0" v-if="model.ibiznes_alias">-->
                <!--                                        <td class="d-inline-block col-4 pl-0"><strong>{{ $t('users.ibiznes_alias') }}:</strong></td>-->
                <!--                                        <td class="d-inline-block col-8">{{model.ibiznes_alias}}</td>-->
                <!--                                    </tr>-->
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <ContentWrapper>
          <h5 class="mb-3">{{ $t('system.contracts_and_insurances') }}</h5>
          <ContractsAndInsurancesList :user-id="id" />
        </ContentWrapper>
      </div>
      <div class="col-sm-6">
        <ContentWrapper>
          <UserVacationRequestsList :user-id="id" />
        </ContentWrapper>
      </div>
      <div class="col-sm-12">
        <ContentWrapper>
          <h5 class="mb-3">{{ $t('system.user_logs') }}</h5>
          <MapActivities :user-id="id" />
        </ContentWrapper>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from 'vue';
import axios from 'axios';
import swal from 'sweetalert2';
// import MyIssue from './MyIssue.vue'
// import MyActivity from './MyActivity.vue'
import notification from '../../notification/notify';
import ContentWrapper from '../../components/HOCs/ContentWrapper';
import UserVacationRequestsList from '../../components/UserVacationRequests/UserVacationRequestsList';
import ContractsAndInsurancesList from '../../components/ContractsAndInsurances/ContractsAndInsurancesList';
import MapActivities from '../../components/MapActivities/MapActivities';

export default {
  name: 'UserShow',
  components: {
    MapActivities,
    ContractsAndInsurancesList,
    UserVacationRequestsList,
    ContentWrapper,
    // MyIssue,
    // MyActivity
  },
  props: ['id'],
  data() {
    return {
      model: {
        user_detail: {},
      },
      resource: 'user',
      redirect: '/administration/user',
      role: {
        name: '',
      },
    };
  },
  computed: {
    phoneNumbers() {
      if (this.model.user_detail.phone) {
        return this.model.user_detail.phone.split(',').map(item => {
          return item.trim();
        });
      }
      return [];
    },
  },
  beforeMount() {
    this.fetchData();
  },
  watch: {
    '$route': 'fetchData',
  },
  methods: {
    edit(id) {
      this.$router.push('/administration/user/' + id + '/edit');
    },
    remove() {
      var vm = this;
      swal.fire({
        title: vm.$t('system.do_not _activate'),
        text: vm.$t('system.if_you_sure'),
        type: 'info',
        showCancelButton: true,
        focusCancel: true,
        confirmButtonText: vm.$t('system.yes'),
        cancelButtonText: vm.$t('system.no'),
      }).then((result) => {
        if (result.value) {
          axios.delete(`${vm.resource}/${vm.$route.params.id}`)
              .then(function (response) {
                notification.notify(
                    vm.$t('notify.success'),
                    vm.$t('system.unactivated'),
                    'success');
                vm.$router.push(vm.redirect);
              })
              .catch(function (error) {
                notification.notify(
                    vm.$t('notify.error'),
                    vm.$t(error.response.data),
                    'error');
              });
        }
      }).catch();
    },
    activate() {
      var vm = this;
      swal.fire({
        title: vm.$t('system.activate'),
        text: vm.$t('system.if_you_sure'),
        type: 'info',
        showCancelButton: true,
        focusCancel: true,
        confirmButtonText: vm.$t('system.yes'),
        cancelButtonText: vm.$t('system.no'),
      }).then((result) => {
        if (result.value) {
          axios.post(`${vm.resource}/${vm.$route.params.id}/activate`)
              .then(function (response) {
                notification.notify(
                    vm.$t('notify.success'),
                    vm.$t('system.activated'),
                    'success');
                vm.$router.push(vm.redirect);
              });
        }
      }).catch();
    },
    fetchData() {
      var vm = this;
      axios.get(`${vm.resource}/${vm.$route.params.id}`)
          .then(function (response) {
            Vue.set(vm.$data, 'model', response.data.model);
          })
          .catch(function (error) {
            notification.notify(
                vm.$t('notify.error'),
                vm.$t(error.response.data),
                'error');
          });
    },
    displayCalendarName() {
      if (this.model.user_detail) {
        if (this.model.user_detail.calendar) {
          return this.model.user_detail.calendar.name;
        }
      }
      return false;
    },
  },
};
</script>
<style
    lang="scss"
    rel="stylesheet/scss"
    scoped
>
table {
  tr:first-child {
    td {
      border: none;
    }
  }
}
</style>
