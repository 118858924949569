import axios from 'axios';

const stopwatches = {
  namespaced: true,
  state: {
    currentStopwatch: {},
    stopwatches: [],
    lastUpdate: false,
  },
  getters: {
    allStopwatchesEntitiesIds(state) {
      let ids = [];
      ids = state.stopwatches.map(item => item.entityId);
      if (state.currentStopwatch && state.currentStopwatch.entityId) {
        ids.push(state.currentStopwatch.entityId);
      }
      return ids;
    },
  },
  mutations: {
    SET_STOPWATCHES: (state, data) => {
      state.stopwatches = data;
    },
    SET_CURRENT_STOPWATCH: (state, data) => {
      state.currentStopwatch = data;
    },
    SET_LAST_UPDATE: (state, data) => {
      state.lastUpdate = data;
    },
  },
  actions: {
    setStopwatches({commit}, stopwatches) {
      commit('SET_STOPWATCHES', stopwatches);
    },
    setCurrentStopwatch({commit}, stopwatch) {
      commit('SET_CURRENT_STOPWATCH', stopwatch);
    },
    startStopwatch({dispatch}, entityId) {
      return axios.post('time-logger/start', {
        entityId,
        entityFormId: 0,
      }).then((response) => {
        dispatch('resolveResponse', response);
      });
    },
    addStopwatch({dispatch}, entityId) {
      return axios.post('time-logger/add', {
        entityId,
        entityFormId: 0,
      }).then((response) => {
        dispatch('resolveResponse', response);
      });
    },

    resolveResponse({state, dispatch, commit}, response) {
      if (state.lastUpdate !== response.data.lastUpdate) {
        const data = [...response.data.data];
        let currentStopwatchIdx = data.findIndex(item => item.currentState === 'start');
        if (currentStopwatchIdx === -1) {
          currentStopwatchIdx = data.findIndex(item =>
            state.currentStopwatch && state.currentStopwatch.entityId &&
            state.currentStopwatch.entityId === item.entityId);
        }
        if (currentStopwatchIdx !== -1) {
          dispatch('setCurrentStopwatch', {
            ...data[currentStopwatchIdx],
            isStopwatchActive: data[currentStopwatchIdx].currentState === 'start',
            totalSeconds: state.currentStopwatch.totalSeconds,
          });
          data.splice(currentStopwatchIdx, 1);
        } else if (data.length) {
          dispatch('setCurrentStopwatch', {
            isStopwatchActive: false,
            ...data[0],
            totalSeconds: this.currentStopwatch.totalSeconds,
          });
          data.splice(0, 1);
        } else {
          dispatch('setCurrentStopwatch', {});
        }
        // this.setCurrentStopwatch(this.currentStopwatch)
        dispatch('setStopwatches',
          data.map(item => {
            return {
              ...item,
              isStopwatchActive: false,
              totalSeconds: item.timeSpent,
            };
          }),
        );
        // this.setStopwatches(this.stopwatches)
        commit('SET_LAST_UPDATE', response.data.lastUpdate);
      }
    },
  },
};

export default stopwatches;
