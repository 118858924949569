import { render, staticRenderFns } from "./StaticTable.vue?vue&type=template&id=6265a6d3&scoped=true&"
import script from "./StaticTable.vue?vue&type=script&lang=js&"
export * from "./StaticTable.vue?vue&type=script&lang=js&"
import style0 from "./StaticTable.vue?vue&type=style&index=0&id=6265a6d3&lang=scss&rel=stylesheet%2Fscss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6265a6d3",
  null
  
)

export default component.exports