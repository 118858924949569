import axios from 'axios';
import _ from 'lodash';

const state = {
  ready: false,
  permissions: [],
  pmPermissions: [],
  projectId: null,
};

const getters = {
  isReady: (state) => {
    return state.ready;
  },
  checkPermissions: (state, getters, rootState) => (permissions, projectId) => {
    if ((rootState.auth.user && rootState.auth.user.admin) || getters.hasPermission('pm-admin')) {
      return true;
    }
    if (permissions) {
      for (const permission of permissions) {
        if (permission === 'is-watcher') {
          return true;
        }
        if (permission.startsWith('pm-') && !getters.hasPmPermission(permission, projectId)) {
          return false;
        } else {
          if (!permission.startsWith('pm-') && !getters.hasPermission(permission)) {
            return false;
          }
        }
      }
      return true;
    }
    return false; //permissions are empty
  },

  hasPermission: (state, getters) => permission => {
    let permissionsList = state.permissions;
    if (permissionsList.indexOf(permission) === -1) {
      return false;
    }
    return true;
  },
  getProjectId: (state) => {
    return state.projectId;
  },
  hasPmPermission: (state, getters, rootState, rootGetters) => (permission, projectId) => {
    let currentProjectId = projectId ? parseInt(projectId) : getters.getProjectId;
    if (!_.isFinite(currentProjectId)) {
      return false;
    }
    // if (permission === 'pm-member') {
    //   return true;
    // }
    if (_.isEmpty(state.pmPermissions)) { //when request with permission not ready
      return false;
    }
    if (!state.pmPermissions[currentProjectId]) {
      return false;
    }
    if (!_.includes(state.pmPermissions[currentProjectId], permission)) {
      return false;
    }
    return true;
  },

  canOr: (state, getters) => (permissions, projectId) => {
    let result = false;
    for (const permission of permissions) {
      result = getters.checkPermissions([permission], projectId);
      if (result === true) {
        return true;
      }
    }
    return false;
  },
};

const mutations = {
  SET_READY(state) {
    state.ready = true;
  },
  SET_PERMISSIONS(state, data) {
    if (data) {
      state.permissions = data.permissions;
      state.pmPermissions = data.pmPermissions;
    }
  },
  SET_PROJECT_ID(state, id) {
    state.projectId = id;
  },
  // SET_PM_PERMISSIONS(state, permissions) {
  //   if (permissions) {
  //     state.pmPermissions = permissions;
  //   }
  // }
};

const actions = {
  setProjectId({commit}, id) {
    commit('SET_PROJECT_ID', id);
  },
  getUserPermission({commit}) {
    return new Promise((resolve, reject) => {
      axios.get(`user_permission`)
        .then(function (response) {
          commit('SET_PERMISSIONS', response.data);
          resolve(response.data);
        }).catch(function (error) {
        reject(error);
      });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
