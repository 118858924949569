import axios from 'axios';
import _ from 'lodash';

const filters = {
  namespaced: true,
  state: {
    issueFilter: [],
    listFilter: [],
    selectedColumns: [],
    defaultColumns: [],
    projectId: null,
  },
  getters: {
    getProjectId(state) {
      return state.projectId;
    },
    getFilters(state) {
      return state.issueFilter;
    },
    getFiltersList(state) {
      return state.listFilter;
    },
    getIssueFilterValue: state => filter => {
      let currentFilter = _.find(state.issueFilter, {idTracker: filter.idTracker});
      return currentFilter && currentFilter.selectedValues[filter.name] ? currentFilter.selectedValues[filter.name] : '';
    },
    getIssueFilterValueMulti: state => filter => {
      let currentFilter = _.find(state.issueFilter, {idTracker: filter.idTracker});
      if (currentFilter && currentFilter.selectedValues[filter.name]) {
        if (typeof currentFilter.selectedValues[filter.name] === 'number') {
          let arr = [currentFilter.selectedValues[filter.name]];
          return arr;
        } else {
          return currentFilter.selectedValues[filter.name];
        }
      } else {
        return [];
      }
    },
    getIssueFilterIsActive: state => filter => {
      let currentFilter = state.issueFilter.find(item => item.idTracker === filter.idTracker);
      return currentFilter && currentFilter.checkedFilters[filter.name] ? currentFilter.checkedFilters[filter.name] : '';
    },
    getSelectedColumns: (state) => (id) => {
      let currentTracker = state.selectedColumns.find(item => item.idTracker === id);
      return currentTracker && currentTracker.val ? currentTracker.val : [];
    },
    getDefaultColumns(state) {
      return state.defaultColumns;
    },
  },
  mutations: {
    SET_ISSUE_FILTER_VALUE(state, payload) {
      if (state.issueFilter && state.issueFilter.length > 0) {
        if (_.find(state.issueFilter, {idTracker: payload.idTracker})) {
          let updateFilter = _.cloneDeep(state.issueFilter);
          updateFilter = _.map(state.issueFilter, function (item) {
            if (item.idTracker === payload.idTracker) {
              item.selectedValues[payload.filter] = payload.value;
            }
            return item;
          });
          state.issueFilter = [].concat(updateFilter);
        } else {
          let itemFilter = {
            idTracker: payload.idTracker,
            checkedFilters: {},
            selectedValues: {},
          };
          itemFilter.selectedValues[payload.filter] = payload.value;
          state.issueFilter = state.issueFilter.concat(itemFilter);
        }
      } else {
        let itemFilter = {
          idTracker: payload.idTracker,
          checkedFilters: {},
          selectedValues: {},
        };
        itemFilter.selectedValues[payload.filter] = payload.value;
        state.issueFilter = state.issueFilter.concat(itemFilter);
      }
    },
    SET_ISSUE_FILTER_TOOGLE(state, payload) {
      if (state.issueFilter && state.issueFilter.length > 0) {
        if (_.find(state.issueFilter, {idTracker: payload.idTracker})) {
          let updateFilter = _.cloneDeep(state.issueFilter);
          updateFilter = _.map(state.issueFilter, function (item) {
            if (item.idTracker === payload.idTracker) {
              item.checkedFilters[payload.filter] = payload.value;
            }
            return item;
          });
          state.issueFilter = [].concat(updateFilter);
        } else {
          let itemFilter = {
            idTracker: payload.idTracker,
            checkedFilters: {},
            selectedValues: {},
          };
          itemFilter.checkedFilters[payload.filter] = payload.value;
          state.issueFilter = state.issueFilter.concat(itemFilter);
        }
      } else {
        let itemFilter = {
          idTracker: payload.idTracker,
          checkedFilters: {},
          selectedValues: {},
        };
        itemFilter.checkedFilters[payload.filter] = payload.value;
        state.issueFilter = state.issueFilter.concat(itemFilter);
      }
    },
    ADD_LIST_FILTERS(state, list) {
      state.listFilter = list;
    },
    CLEAR_CURRENT_FILTER(state, id) {
      state.issueFilter = _.remove(state.issueFilter, {
        idTracker: id,
      });
    },
    ADD_COLUMNS(state, data) {
      if (state.selectedColumns && state.selectedColumns.length > 0) {
        if (_.find(state.selectedColumns, {idTracker: data.idTracker})) {
          let updateItem = _.cloneDeep(state.selectedColumns);
          updateItem = _.map(state.selectedColumns, function (item) {
            if (item.idTracker === data.idTracker) {
              item.val = data.val;
            }
            return item;
          });
          state.selectedColumns = [].concat(updateItem);
        } else {
          let item = {
            idTracker: data.idTracker,
            val: data.val,
          };
          state.selectedColumns = state.selectedColumns.concat(item);
        }
      } else {
        let item = {
          idTracker: data.idTracker,
          val: data.val,
        };
        state.selectedColumns = state.selectedColumns.concat(item);
      }
    },
    SET_DEFAULT_COLUMN(state, columns) {
      state.defaultColumns = columns;
    },
  },
  actions: {
    getFiltersApi({commit}, trackerId) {
      return new Promise((resolve, reject) => {
        axios.get(`/form/issue-search/${trackerId}`)
          .then((response) => {
            resolve(response.data.data);
            commit('ADD_LIST_FILTERS', response.data.data);
          })
          .catch(function (e) {
            reject(e);
          });
      });
    },
    createFilterConfig({commit}, data) {
      return new Promise((resolve, reject) => {
        axios.post('/user-configurations', {key: data.key, value: data.value})
          .then((response) => {
            resolve(response);
          })
          .catch(function (e) {
            reject(e);
          });
      });
    },
    getFiltersOrColumnsConfigApi({commit}, {idTracker, key}) {
      return new Promise((resolve, reject) => {
        let searchObj = {
          idTracker: idTracker,
        };
        searchObj = JSON.stringify(searchObj);
        axios.get(`/user-configurations?key=${key}&search=${searchObj}`)
          .then((response) => {
            resolve(response.data);
          })
          .catch(function (e) {
            reject(e);
          });
      });
    },
    deleteFilterOrColumnConfig({commit}, userConfigurationId) {
      return new Promise((resolve, reject) => {
        axios.delete(`/user-configurations/${userConfigurationId}`)
          .then((response) => {
            resolve(response);
          })
          .catch(function (e) {
            reject(e);
          });
      });
    },
    updateFilterConfig({commit}, data) {
      return new Promise((resolve, reject) => {
        axios.put(`/user-configurations/${data.idUserConfig}`, {value: data.currentFilter})
          .then((response) => {
            resolve(response);
          })
          .catch(function (e) {
            reject(e);
          });
      });
    },
    updateColumnConfig({commit}, data) {
      return new Promise((resolve, reject) => {
        axios.put(`/user-configurations/${data.idUserConfig}`, {value: data.columnsConfig})
          .then((response) => {
            resolve(response);
          })
          .catch(function (e) {
            reject(e);
          });
      });
    },
    setColumnsConfigAsDefault(context, {columnsConfigId, trackerId}) {
      return axios.put(`/user-configurations/${columnsConfigId}/setDefault`, {
        key: 'columnDefault_' + trackerId,
      });
    },
    selectColumns({state, commit}, data) {
      return new Promise((resolve, reject) => {
        if (state.selectedColumns && state.selectedColumns.length > 0) {
          if (_.find(state.selectedColumns, {idTracker: data.idTracker})) {
            let updateItem = _.cloneDeep(state.selectedColumns);
            updateItem = _.map(state.selectedColumns, function (item) {
              if (item.idTracker === data.idTracker) {
                item.val = data.value;
              }
              return item;
            });
            state.selectedColumns = [].concat(updateItem);
          } else {
            let item = {
              idTracker: data.idTracker,
              val: data.val,
            };
            state.selectedColumns = state.selectedColumns.concat(item);
          }
        } else {
          let item = {
            idTracker: data.idTracker,
            val: data.val,
          };
          state.selectedColumns = state.selectedColumns.concat(item);
        }
      });
    },
  },
};

export default filters;
