import axios from 'axios';

const modules = {
  namespaced: true,
  state: {
    filters: {},
    modules: [],
    layoutSchemas: {},
  },
  getters: {
    getPathToForm: state => formId => {
      let submodule = null;
      let form = null;
      const module = state.modules.find(module => {
        if (module.submodules) {
          const moduleCandidate = module.submodules.find(submodule => {
            // console.log('submodule.forms', submodule.forms)
            if (submodule.forms) {
              const formCandidate = submodule.forms.find(form => form.id === formId);
              if (formCandidate) {
                form = formCandidate;
                return true;
              }
              return false;
            }
          });
          if (moduleCandidate) {
            submodule = moduleCandidate;
            return true;
          }
          return false;
        }
      });
      if (module && submodule && form) {
        return {
          module,
          submodule,
          form,
        };
      }
      return null;
    },
    findSubmodule: state => (moduleName, submoduleName) => {
      const module = state.modules.find(module =>
        module.name.toLowerCase().trim() === moduleName.toLowerCase().trim(),
      );
      if (module && module.submodules) {
        const submodule = module.submodules.find(submodule =>
          submodule.name.toLowerCase().trim() === submoduleName.toLowerCase().trim(),
        );
        if (submodule) {
          return submodule;
        }
      }
      return null;
    },
  },
  mutations: {
    SET_MODULES: (state, modules) => {
      state.modules = modules;
    },
    SET_FILTERS: (state, filters) => {
      state.filters = filters;
    },
  },
  actions: {
    setFormFilters({
                     commit,
                     state,
                   }, {
                     filters,
                     moduleName,
                     submoduleName,
                     formIndex,
                   }) {
      let currentFilters = {...state.filters};
      if (!currentFilters[moduleName]) {
        currentFilters = {
          ...currentFilters,
          [moduleName]: {
            [submoduleName]: {
              [formIndex]: filters,
            },
          },
        };
      } else if (!currentFilters[moduleName][submoduleName]) {
        currentFilters[moduleName] = {
          ...currentFilters[moduleName],
          [submoduleName]: {
            [formIndex]: filters,
          },
        };
      } else if (!currentFilters[moduleName][submoduleName][formIndex]) {
        currentFilters[moduleName][submoduleName] = {
          ...currentFilters[moduleName][submoduleName],
          [formIndex]: filters,
        };
      } else {
        currentFilters[moduleName][submoduleName][formIndex] = filters;
      }
      commit('SET_FILTERS', currentFilters);
    },
    async fetchModules({commit}) {
      const {data} = await axios.get('aberit_module_generator/aberit_modules');
      commit('SET_MODULES', data);
    },
    // simulate fetching schema from server
    getSchemaByFormId({state}, formId) {
      return state.layoutSchemas[formId];
    },
    async fetchLayoutSchemas({state}, moduleName) {
      try {
        if (!Object.keys(state.layoutSchemas).length) {
          let {data} = await axios.get(`${moduleName || 'crm'}/layout-json`);
          // TODO: backend must fix (only object is applied)
          if (typeof data === 'string') {
            data = JSON.parse(data.slice(1));
          }
          state.layoutSchemas = data;
        }
      } catch (e) {
        console.log('fetchLayoutSchemas_error', e);
      }
    },
  },
};

export default modules;
