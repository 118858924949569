import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store/index';
import VueI18n from '../config/i18n';
import authRoutes from './authRoutes';
import dashboardRoutes from './dashboardRoutes';
import projectRoutes from './projectRoutes';
import issueRoutes from './issueRoutes';
import crmRoutes from './crmRoutes';
import settlementsRoutes from './settlementsRoutes';
import payrollRoutes from './payrollRoutes';
import activeClientsRoutes from './activeClientsRoutes';
import administrationRoutes from './administrationRoutes';
import timeLoggerRoutes from './timeLoggerRoutes';
import profileRoutes from './profileRoutes';
import searchRoutes from './searchRoutes';
import calendarRoutes from './calendarRoutes';
import visitsRoutes from './VisitsRoutes';
import ordersRoutes from './OrdersRoutes';
import giftsRoutes from './GiftsRoutes';
import targets from './Targets';
import workOrdersRoutes from './workOrdersRoutes';
import vacationRequests from './vacationRequests';

Vue.use(VueRouter);

const routes = [
  ...authRoutes,
  {
    path: '/',
    component: () => import('../views/layout/Layout.vue'),
    redirect: {name: 'Dashboard'},
    name: 'Home',
    meta: {
      breadcrumb: [{
        name: 'Home',
        title: 'breadcrumb.home',
      }],
    },
    beforeEnter: (to, from, next) => { //new tab
      store.commit('SET_MAIN_LOADING', false);
      if (window.localStorage.getItem('token')) {
        // store.commit('auth/SET_ENTRY_URL', to.path);
        if (!store.getters['permission/isReady']) {
          store.dispatch('permission/getUserPermission').then((data) => {
            store.commit('permission/SET_READY');
            // this.$store.commit('auth/saveAuthInLocalStorage', data)
            store.commit('auth/SET_USER', data.user);
            next();
          }).catch(function (e) {
            console.error('qweq', e);
            store.dispatch('auth/logout');
            next('/login');
          });
        } else { //permissons are rdy
          //   console.log('2')
          next();
        }
      } else {
        document.title = VueI18n.t('system.login');
        store.commit('auth/SET_ENTRY_URL', to.path);
        next('/login');
      }
    },
    children: [
      ...dashboardRoutes,
      ...projectRoutes,
      ...issueRoutes,
      ...crmRoutes,
      // ...knowledgeBaseRoutes,
      ...settlementsRoutes,
      ...payrollRoutes,
      ...activeClientsRoutes,
      // ...invoiceRoutes,
      ...administrationRoutes,
      ...timeLoggerRoutes,
      ...profileRoutes,
      ...searchRoutes,
      ...calendarRoutes,
      ...visitsRoutes,
      ...ordersRoutes,
      ...giftsRoutes,
      ...targets,
      ...workOrdersRoutes,
      ...vacationRequests,
    ],
  },
  {
    path: '*',
    redirect: {name: ''},
  },
  // {
  //   path: '/',
  //   name: 'Home',
  //   component: Home
  // },
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
