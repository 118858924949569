<template xmlns:v-bind="http://www.w3.org/1999/xhtml">
  <div class="view-container">
    <div class="view">
      <div class="view-heading">
        <div class="view-title">
          <router-link
              :to="'/user/'+ id +'/activity'"
          >
            {{ $t('system.activity') }}
          </router-link>
        </div>
      </div>
      <div class="view-body">
        <ul class="timeline">
          <template v-for='(item, date) in model'>
            <!-- timeline item -->
            <li
                class="time-label"
                v-bind:key="item.id"
            >
                  <span
                      :style="[{'background-color' : themeColor}]"
                      style="color: #fff"
                  >
                    {{ date }}
                    <!--<i v-bind:class="'fa fa-pencil'"></i>-->
                  </span>
            </li>
            <li
                v-bind:key="line.id"
                v-for="line in item"
            >
              <!-- timeline icon -->
              <i
                  v-bind:class="'fa'"
                  v-if="line.prop_key !== 'notes'"
              >
                <svg-icon icon-class="people" />
              </i>
              <i
                  v-bind:class="'fa'"
                  v-if="line.prop_key === 'notes'"
              >
                <svg-icon icon-class="commenting-o" />
              </i>

              <div class="timeline-item">
                <div>
                  <div class="d-flex justify-content-between">
                    <div class="timeline-header">
                      <span>{{ line.projectName ? line.projectName : '' }}</span>
                      <router-link
                          :to="'/project/' + line.projectID + '/issue/' + line.issue + '#change-'+ line.id "
                      >
                        {{ line.tracker }} #{{ line.issueID }} ({{ line.status }})
                        {{ line.subject ? line.subject : '' }}
                      </router-link>
                    </div>
                    <span class="time">
                          <svg-icon
                              class="time-icons"
                              icon-class="time"
                          /> {{ line.created_at }}
                        </span>
                  </div>
                  <hr class="m-0">
                  <div
                      class="p-2"
                      v-if="line.description"
                  >
                    <pre v-if="take != 'all'">{{ line.description | substr(0, 50) }}</pre>
                    <pre v-if="take == 'all'">{{ line.description }}</pre>
                  </div>
                </div>
              </div>
            </li>
          </template>
          <!-- END timeline item -->
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from 'vue';
import axios from 'axios';
import notification from '../../notification/notify';

export default {
  name: 'UserActivity',
  data() {
    return {
      model: {},
      id: this.$route.params.id,
      error: null,
      resource: 'user',
      action: 'activity',
      redirect: '/',
      take: 10,
    };
  },
  beforeMount() {
    if (this.$route.meta.take === 'all') {
      this.take = 'all';
    }
    this.getActivity();
  },
  computed: {
    themeColor() {
      return this.$store.state.app.color;
    },
  },
  methods: {
    getActivity() {
      let vm = this;
      let string = `${vm.resource}/${vm.$route.params.id}/${vm.action}?take=${vm.take}`;
      if (this.$route.meta.take === 'all') {
        string = `${vm.resource}/${vm.$route.params.id}/${vm.action}`;
      }
      axios.get(string)
          .then(function (response) {
            Vue.set(vm.$data, 'model', response.data.model);
          })
          .catch(function (error) {
            notification.notify(
                vm.$t('notify.error'),
                error.response.data.error,
                'error');
          });
    },
  },
};
</script>

<style
    lang="scss"
    rel="stylesheet/scss"
    scoped
>
.timeline-footer a.btn {
  margin-right: 10px;
}

.timeline {
  position: relative;
  margin: 0;
  padding: 0;
  list-style: none;
  width: 100%;

  &:before {
    content: '';
    position: absolute;
    top: 20px;
    bottom: 0px;
    left: 31px;
    width: 4px;
    background: #909399;
    margin: 0;
    border-radius: 2px;
  }

  li {
    position: relative;
    margin-bottom: 15px;

    &:before, &:after {
      content: ' ';
      display: table;
    }

    &:after {
      clear: both;
    }

    span {
      font-weight: 600;
      padding: 5px 10px;
      display: inline-block;
      background-color: #fff;
      border-radius: 4px;
    }

    .bg-green {
      background-color: #67C23A;
      color: #fff;
      font-size: 14px;
    }

    .fa, .icon {
      width: 30px;
      height: 30px;
      font-size: 15px;
      line-height: 30px;
      position: absolute;
      color: #606266;
      background: #fff;
      border-radius: 50%;
      text-align: center;
      left: 18px;
      top: 0;
      border: 1px solid #E4E7ED;
    }

    .bg-black {
      background-color: #111 !important;
    }

    .timeline-item {
      margin-top: 0;
      background: #fff;
      color: #444;
      margin-left: 60px;
      margin-right: 0px;
      padding: 0;
      box-shadow: 0px 6px 14px rgba(0, 0, 0, .1);
      position: relative;

      .time {
        color: #999;
        font-weight: normal;
        padding: 3px 10px;
        font-size: 12px;
        display: flex;
        align-items: center;

        .time-icons {
          margin-right: 5px;
          /*cursor: pointer;*/
        }
      }

      .timeline-header {
        margin: 0;
        color: #555;
        font-size: 13px;
        font-weight: 700;
        line-height: 1.1;

        a {
          font-weight: 600;
          color: #3c8dbc !important;
          text-decoration: none;
        }
      }

      pre {
        margin: 0;
      }

      h5 {
        margin: 0px;
        font-size: 12px;
        color: #909399;

        .text-success {
          color: #303133;
        }

        .text-info {
          color: #909399;
        }
      }
    }
  }

  li:not(.time-label) {
    div:first-child {
      .timeline-item {
        border-radius: 3px 3px 0 0;
      }
    }

    div:last-child {
      .timeline-item {
        border-radius: 0 0 3px 3px;
      }
    }
  }
}
</style>
