<template>
  <el-select
      :deselectLabel="$t('system.deselectLabel')"
      :loading="isLoading"
      :placeholder="$t('system.selectoption')"
      :selectLabel="$t('system.selectLabel')"
      :selectedLabel="$t('system.selectedlabel')"
      filterable
      label="name"
      name="role_id"
      remote
      reserve-keyword
      track-by="name"
      v-model="role"
  >
    <el-option
        :key="value.id"
        :label="value.name"
        :value="value.id"
        v-for="value in options"
    >
    </el-option>
  </el-select>
</template>

<script>
import axios from 'axios';
import notification from '../../notification/notify';

export default {
  components: {},
  props: ['role'],
  data() {
    return {
      selected: null,
      options: [],
      isLoading: false,
    };
  },
  watch: {
    selected: function () {
      this.$emit('selectedUpdated', this.selected);
    },
    role: function (newVal) {
      this.selected = newVal;
      // this.selected = _.first(newVal).id;
    },
  },
  methods: {
    asyncFind(query) {
      let that = this;
      this.isLoading = true;
      axios.get('role', {
        params: {
          'column': 'id',
          'direction': 'asc',
          'per_page': 9999,
          'search_operator': 'equal_to',
          'search_column': 'name',
        },
      })
          .then(function (response) {
            that.options = response.data.data;
            that.isLoading = false;
          })
          .catch(function (error) {
            notification.notify(
                that.$t('notify.error'),
                that.$t(error.message),
                'error');
            that.isLoading = false;
          });
    },
  },
  mounted() {
    this.asyncFind();
  },
};
</script>
