<template>
  <el-dialog
      :close-on-click-modal="false"
      :title="data ? $t('system.edit_contract_or_insurance') : $t('system.create_contract_or_insurance')"
      :visible="isVisible"
      @close="close"
      class="contract-and-insurance-form"
      destroy-on-close
      width="60%"
  >
    <el-form
        :size="fieldsSize"
        label-position="top"
    >
      <div class="row">
        <div :class="form.type === 'insurance' ? 'col-lg-3' : 'col-lg-4'">
          <el-form-item
              :label="$t('system.type')"
              required
          >
            <el-radio-group
                @change="onTypeChanged"
                class="w-100"
                v-model="form.type"
            >
              <el-radio-button label="contract">
                {{ $t('system.contract') }}
              </el-radio-button>
              <el-radio-button label="insurance">
                {{ $t('system.insurance') }}
              </el-radio-button>
            </el-radio-group>
          </el-form-item>
        </div>
        <div
            class="col-lg-3"
            v-if="form.type === 'insurance'"
        >
          <el-form-item
              :label="$t('system.insurance_type')"
              required
          >
            <el-select
                :class="{'validate-error': !!getBackendErrors('insurance_type')}"
                class="w-100"
                filterable
                v-model="form.insurance_type"
            >
              <el-option
                  :key="insuranceType.id"
                  :label="insuranceType.name"
                  :value="insuranceType.id"
                  v-for="insuranceType in insuranceTypes"
              />
            </el-select>
            <div
                class="el-form-item__error"
                v-if="getBackendErrors('insurance_type')"
            >
              {{ $t(getBackendErrors('insurance_type')[0]) }}
            </div>
          </el-form-item>
        </div>
        <div :class="form.type === 'insurance' ? 'col-lg-3' : 'col-lg-4'">
          <el-form-item
              :label="$t('system.date_from')"
              required
          >
            <el-date-picker
                :class="{'validate-error': !!getBackendErrors('from')}"
                :picker-options="startAtPickerOptions"
                :placeholder="$t('system.pick_a_day')"
                class="w-100"
                v-model="form.from"
                value-format="yyyy-MM-dd"
            />
            <div
                class="el-form-item__error"
                v-if="getBackendErrors('from')"
            >
              {{ $t(getBackendErrors('from')[0]) }}
            </div>
          </el-form-item>
        </div>
        <div :class="form.type === 'insurance' ? 'col-lg-3' : 'col-lg-4'">
          <el-form-item :label="$t('system.date_to')">
            <el-date-picker
                :class="{'validate-error': !!getBackendErrors('to')}"
                :picker-options="endAtPickerOptions"
                :placeholder="$t('system.pick_a_day')"
                class="w-100"
                v-model="form.to"
                value-format="yyyy-MM-dd"
            />
            <div
                class="el-form-item__error"
                v-if="getBackendErrors('to')"
            >
              {{ $t(getBackendErrors('to')[0]) }}
            </div>
          </el-form-item>
        </div>
        <div class="col-12">
          <el-form-item
              :label="$t('system.name')"
              required
          >
            <el-input
                :class="{'validate-error': !!getBackendErrors('name')}"
                v-model="form.name"
            />
            <div
                class="el-form-item__error"
                v-if="getBackendErrors('name')"
            >
              {{ $t(getBackendErrors('name')[0]) }}
            </div>
          </el-form-item>
        </div>
      </div>
    </el-form>
    <span
        class="dialog-footer"
        slot="footer"
    >
      <el-button
          :loading="saveLoading"
          :size="fieldsSize"
          @click="save"
          type="primary"
      >
        {{ $t('system.save') }}
      </el-button>
      <el-button
          :disabled="saveLoading"
          :size="fieldsSize"
          @click="close"
      >
        {{ $t('system.cancel') }}
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
import axios from 'axios';
import moment from 'moment';
// import notification from "../../notification/notify";

export default {
  name: 'ContractAndInsuranceForm',
  props: {
    data: {
      type: Object,
      default: null,
    },
    isVisible: {
      type: Boolean,
      default: true,
    },
    saveLoading: {
      type: Boolean,
      default: false,
    },
    backendErrors: {
      type: Object,
    },
  },
  data() {
    return {
      isFirstOpen: true,
      fieldsSize: 'small',
      insuranceTypes: [],
      form: {
        type: 'contract',
        name: '',
        from: null,
        to: null,
        insurance_type: null,
      },
      startAtPickerOptions: {
        disabledDate: (date) => {
          return date.getTime() > moment(this.form.to);
        },
      },
      endAtPickerOptions: {
        disabledDate: (date) => {
          return date.getTime() < moment(this.form.from);
        },
      },
    };
  },
  watch: {
    isVisible(value) {
      if (value && this.isFirstOpen) {
        this.fetchInsuranceTypes();
        this.isFirstOpen = false;
      }
      if (value && Object.keys(this.data).length) {
        this.form = {
          type: this.data.type,
          name: this.data.name,
          from: this.data.from,
          to: this.data.to,
          insurance_type: this.data.insurance_type_id,
        };
      }
    },
  },
  methods: {
    onTypeChanged(val) {
      if (val === 'insurance') {
        this.form.insurance_type = null;
      }
    },
    async fetchInsuranceTypes() {
      try {
        const {data} = await axios.get('workorders/contract/insurance_type');
        this.insuranceTypes = data;
      } catch (e) {
        // notification.notify(
        //   this.$t('notify.error'),
        //   this.$t('system.can_not_load_insurance_types'),
        //   'error')
      }
    },
    close() {
      this.form = {
        type: 'contract',
        name: '',
        from: null,
        to: null,
      };
      this.$emit('close');
    },
    save() {
      this.$emit('save', this.form);
    },
    getBackendErrors(field) {
      return this.backendErrors[field];
    },
  },
};
</script>

<style
    lang="scss"
    rel="stylesheet/scss"
>
.contract-and-insurance-form {
  .el-form {
    .el-form-item__label {
      padding: 0;
      margin-bottom: 0;
    }
  }
}
</style>
