import Vue from 'vue';
import {
  Autocomplete,
  Badge,
  Breadcrumb,
  BreadcrumbItem,
  Button,
  ButtonGroup,
  Card,
  Checkbox,
  CheckboxGroup,
  Col,
  Collapse,
  CollapseItem,
  ColorPicker,
  Container,
  DatePicker,
  Dialog,
  Divider,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  Form,
  FormItem,
  Header,
  Icon,
  InfiniteScroll,
  Input,
  InputNumber,
  Loading,
  Main,
  Menu,
  MenuItem,
  Option,
  OptionGroup,
  Pagination,
  Popconfirm,
  Popover,
  Progress,
  Radio,
  RadioButton,
  RadioGroup,
  Row,
  Select,
  Slider,
  Submenu,
  Switch,
  Table,
  TableColumn,
  TabPane,
  Tabs,
  Tag,
  Timeline,
  TimelineItem,
  TimePicker,
  Tooltip,
  Transfer,
  Tree,
  Upload,
} from 'element-ui';
import lang from 'element-ui/lib/locale/lang/en';
import locale from 'element-ui/lib/locale';

locale.use(lang);

Vue.use(OptionGroup);
Vue.use(Autocomplete);
Vue.use(Popconfirm);
Vue.use(TimePicker);
Vue.use(Popover);
Vue.use(InfiniteScroll);
Vue.use(Timeline);
Vue.use(TimelineItem);
Vue.use(RadioGroup);
Vue.use(RadioButton);
Vue.use(Button);
Vue.use(Divider);
Vue.use(InputNumber);
Vue.use(Switch);
Vue.use(CollapseItem);
Vue.use(Collapse);
Vue.use(Slider);
Vue.use(Upload);
Vue.use(Radio);
Vue.use(Col);
Vue.use(Row);
Vue.use(DatePicker);
Vue.use(Progress);
Vue.use(Checkbox);
Vue.use(CheckboxGroup);
Vue.use(ButtonGroup);
Vue.use(Badge);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Input);
Vue.use(Dropdown);
Vue.use(Header);
Vue.use(Tooltip);
Vue.use(DropdownMenu);
Vue.use(Container);
Vue.use(Menu);
Vue.use(MenuItem);
Vue.use(Main);
Vue.use(ColorPicker);
Vue.use(Tabs);
Vue.use(TabPane);
Vue.use(Submenu);
Vue.use(Dialog);
Vue.use(Select);
Vue.use(Option);
Vue.use(DropdownItem);
Vue.use(Loading);
Vue.use(Card);
Vue.use(Pagination);
Vue.use(Icon);
Vue.use(Breadcrumb);
Vue.use(BreadcrumbItem);
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Tree);
Vue.use(Transfer);
Vue.use(Tag);
