import axios from 'axios';
import ElementUiLocale from 'element-ui/lib/locale';
import langEN from 'element-ui/lib/locale/lang/en';
// import _ from 'lodash'

const translate = {
  namespaced: true,
  state: {
    language: 'pl',
  },
  getters: {},
  mutations: {},
  actions: {
    getLabels({commit}, data) {
      return new Promise((resolve, reject) => {
        axios.get(`labels?search=${data.valueSearch}&column=${data.params.column}&direction=${data.params.direction}&per_page=${data.params.per_page}&page=${data.params.page}`).then(function (response) {
          resolve(response.data);
        })
          .catch(function (error) {
            reject(error);
          });
      });
    },
    addToLabels({commit}, item) {
      return new Promise((resolve, reject) => {
        axios.post('labels', item).then(function (response) {
          resolve(response.data.data);
        })
          .catch(function (error) {
            reject(error);
          });
      });
    },
    deletefromLabels({commit}, id) {
      return new Promise((resolve, reject) => {
        axios.delete(`labels/${id}`).then(function (response) {
          resolve(response);
        })
          .catch(function (error) {
            reject(error);
          });
      });
    },
    updateLabel({commit}, item) {
      return new Promise((resolve, reject) => {
        axios.put(`labels/${item.id}`, {type: item.type, name: item.name}).then(function (response) {
          resolve(response);
        })
          .catch(function (error) {
            reject(error);
          });
      });
    },

    getLanguages({commit}, p) {
      return new Promise((resolve, reject) => {
        axios.get(`languages?column=${p.column}&direction=${p.direction}&per_page=${p.per_page}&page=${p.page}`).then(function (response) {
          resolve(response.data);
        })
          .catch(function (error) {
            reject(error);
          });
      });
    },
    addToLanguages({commit}, item) {
      return new Promise((resolve, reject) => {
        axios.post('languages', item).then(function (response) {
          resolve(response.data.data);
        })
          .catch(function (error) {
            reject(error);
          });
      });
    },
    updateLanguage({commit}, item) {
      return new Promise((resolve, reject) => {
        axios.put(`languages/${item.id}`, {shortcut: item.shortcut, name: item.name}).then(function (response) {
          resolve(response);
        })
          .catch(function (error) {
            reject(error);
          });
      });
    },
    deletefromLanguages({commit}, id) {
      return new Promise((resolve, reject) => {
        axios.delete(`languages/${id}`).then(function (response) {
          resolve(response);
        })
          .catch(function (error) {
            reject(error);
          });
      });
    },

    getTranslations({commit}, data) {
      return new Promise((resolve, reject) => {
        axios.get(`translations/${data.id}?name=${data.name}&translation=${data.translation}&column=${data.p.column}&direction=${data.p.direction}&per_page=${data.p.per_page}&page=${data.p.page}`).then(function (response) {
          resolve(response);
        })
          .catch(function (error) {
            reject(error);
          });
      });
    },
    updateItemTranslation({commit}, data) {
      return new Promise((resolve, reject) => {
        axios.put(`translations/${data.id}/${data.row.labelId}`, {translation: data.row.translation}).then(function (response) {
          resolve(response);
        })
          .catch(function (error) {
            reject(error);
          });
      });
    },

    loadLocaleMessage({rootGetters, commit}, langSet) {
      return new Promise((resolve, reject) => {
        let lang = rootGetters['auth/token'] !== null ? localStorage.getItem('langLocal') : localStorage.getItem('langGlobal');
        lang = lang !== null ? lang : 'en';
        let currentLang = langSet !== 'undefined' ? langSet : lang;
        import(`element-ui/lib/locale/lang/${currentLang}`).then(function (response) {
          ElementUiLocale.use(response.default);
        }).catch(function () {
          ElementUiLocale.use(langEN);
        });
        const store = rootGetters['auth/isAuthenticated'] ? `global_translations/${currentLang}/public` : `initial_translations/${currentLang}`;
        const headers = {headers: {Authorization: 'Bearer ' + localStorage.getItem('token')}};
        axios.get(store, rootGetters['auth/token'] !== null ? headers : {}).then(function (response) {
          resolve(rootGetters['auth/isAuthenticated'] ? response.data.data : response.data);
        }).catch(function (error) {
          reject(error);
        });
      });
    },
  },
};

export default translate;
