import VueI18n from '../config/i18n';
import {orGuard} from '../guards/guards';

export default [
  {
    path: '/vacation-requests',
    component: () => import('../views/VacationRequests/Index.vue'),
    name: 'VacationRequestsList',
    props: true,
    beforeEnter: orGuard,
    meta: {
      description: VueI18n.t('system.vacation_requests'),
      permissions: ['wo-leave-view-all', 'wo-leave-view-my'],
    },
    children: [
      {
        path: 'create',
        component: () => import('../views/VacationRequests/Form.vue'),
        name: 'VacationRequestCreate',
        props: true,
        meta: {
          description: VueI18n.t('system.create_vacation_request'),
          // permissions: ['crm-workorder']
        },
      },
      {
        path: ':id',
        component: () => import('../views/VacationRequests/Show.vue'),
        name: 'VacationRequest',
        props: true,
      },
    ],
  },
  // {
  //   path: '/work-orders/create',
  //   component: () => import('../views/WorkOrders/Form.vue'),
  //   name: 'WorkOrderCreate',
  //   props: true,
  //   beforeEnter: andGuard,
  //   meta: {
  //     description: VueI18n.t('system.work_orders'),
  //     permissions: ['crm-workorder']
  //   }
  // },
  // {
  //   path: '/work-orders/:id',
  //   component: () => import('../views/WorkOrders/Show.vue'),
  //   name: 'WorkOrder',
  //   props: true,
  //   beforeEnter: andGuard,
  //   meta: {
  //     description: VueI18n.t('system.work_orders'),
  //     permissions: ['crm-workorder']
  //   }
  // },
  // {
  //   path: '/work-orders/:id/edit',
  //   component: () => import('../views/WorkOrders/Form.vue'),
  //   name: 'WorkOrderEdit',
  //   props: true,
  //   beforeEnter: andGuard,
  //   meta: {
  //     description: VueI18n.t('system.work_orders'),
  //     permissions: ['crm-workorder']
  //   }
  // }
];
