import Vue from 'vue';
import {library} from '@fortawesome/fontawesome-svg-core';
import {
  faBook,
  faBriefcase,
  faCalendarAlt,
  faCaretDown,
  faCaretUp,
  faChalkboardTeacher,
  faChartBar,
  faChartLine,
  faCogs,
  faEye,
  faEyeSlash,
  faFile,
  faFileInvoiceDollar,
  faFileSignature,
  faFlask,
  faGenderless,
  faHiking,
  faImage,
  faPlay,
  faPlusCircle,
  faSave,
  faShoppingCart,
  faTachometerAlt,
  faTasks,
  faTrash,
  faUnlock,
  faUser,
  faUsers,
  faUsersCog,
  faWheelchair,
} from '@fortawesome/free-solid-svg-icons';
import regular from '@fortawesome/fontawesome-free-regular';
import solid from '@fortawesome/fontawesome-free-solid';
// import brands from '@fortawesome/fontawesome-free-brands'
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';

library.add(faEye,
  faEyeSlash,
  faCaretDown,
  faCaretUp,
  faTrash,
  faPlusCircle,
  faFileInvoiceDollar,
  faTasks,
  faCalendarAlt,
  faHiking,
  faTachometerAlt,
  faWheelchair,
  faGenderless,
  faFileSignature,
  faFlask,
  faUser,
  faUsers,
  faUsersCog,
  faCogs,
  faChartBar,
  faBook,
  faBriefcase,
  faChartLine,
  faChalkboardTeacher,
  faShoppingCart,
  faFile,
  faUnlock,
  faPlay,
  faSave,
  faImage);
library.add(regular);
library.add(solid);
// library.add(brands);
Vue.component('font-awesome-icon', FontAwesomeIcon);
