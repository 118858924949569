import VueI18n from '../config/i18n';
import {andGuard} from '../guards/guards';

export default [
  {
    path: '/work-orders',
    component: () => import('../views/WorkOrders/TabsContainer.vue'),
    name: 'WorkOrders',
    redirect: {name: 'WorkOrdersList'},
    props: true,
    beforeEnter: andGuard,
    meta: {
      description: VueI18n.t('system.work_orders'),
      permissions: ['crm-workorder'],
    },
    children: [
      {
        path: 'list',
        component: () => import('../views/WorkOrders/Index.vue'),
        name: 'WorkOrdersList',
        props: true,
        beforeEnter: andGuard,
        meta: {
          description: VueI18n.t('system.work_orders'),
          permissions: ['crm-workorder'],
        },
      },
      {
        path: 'create',
        component: () => import('../views/WorkOrders/Form.vue'),
        name: 'WorkOrderCreate',
        props: true,
        beforeEnter: andGuard,
        meta: {
          description: VueI18n.t('system.work_orders'),
          permissions: ['crm-workorder'],
        },
      },
      {
        path: ':id',
        component: () => import('../views/WorkOrders/Show.vue'),
        name: 'WorkOrder',
        props: true,
        beforeEnter: andGuard,
        meta: {
          description: VueI18n.t('system.work_orders'),
          permissions: ['crm-workorder'],
        },
      },
      {
        path: ':id/edit',
        component: () => import('../views/WorkOrders/Form.vue'),
        name: 'WorkOrderEdit',
        props: true,
        beforeEnter: andGuard,
        meta: {
          description: VueI18n.t('system.work_orders'),
          permissions: ['crm-workorder'],
        },
      },
    ],
  },
];
