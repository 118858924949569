<template>
  <div
      class="nav-filter table-gray"
      transition="fade"
      transition-mode="out-in"
  >
    <div
        class="table-responsive"
        v-if="thead && thead.length > 0"
    >
      <table
          :class="customClass"
          class="table mb-0"
      >
        <thead>
        <tr>
          <th
              :key="item.key"
              :style="item.sort ? 'cursor: pointer' : ''"
              @click="item.sort ? sortChange(item.key) : false"
              class="table--th-view"
              v-for="item in thead"
          >
                      <span class="d-flex align-items-center">
                        {{ $t(item.label) }}
                        <div
                            class="d-flex flex-column table-sort-icons"
                            v-if="item.sort"
                        >
                          <span v-if="item.key == params.column">
                            <font-awesome-icon
                                :class="params.direction == 'asc' ? 'active' : ''"
                                class="caret-sort caret-sort-up"
                                icon="caret-up"
                            />
                            <font-awesome-icon
                                :class="params.direction == 'desc' ? 'active' : ''"
                                class="caret-sort caret-sort-down"
                                icon="caret-down"
                            />
                          </span>
                          <span v-else>
                            <font-awesome-icon
                                class="caret-sort caret-sort-up"
                                icon="caret-up"
                            />
                            <font-awesome-icon
                                class="caret-sort caret-sort-down"
                                icon="caret-down"
                            />
                          </span>
                        </div>
                      </span>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr
            :key="key"
            v-for="(row, key) in data"
        >
          <td
              :key="key + '_' + item.key"
              class="table--tbody"
              v-for="item in thead"
          >
            <div
                :class="[item.deleteWrap ? 'deleteWrap' : '', item.link ? 'table--tdLink' : '']"
                :style="item.style ? item.style : ''"
            >
              <template>
                <!--boolean type BEGIN-->
                <template v-if="item.type === 'boolean'">
                                  <span
                                      class="d-flex justify-content-center align-items-center"
                                      v-if="!item.disable"
                                  >
                                    <el-tooltip
                                        :content="$t('system.change_active')"
                                        effect="dark"
                                        placement="bottom"
                                        v-if="item.key === 'active'"
                                    >
                                      <el-checkbox
                                          class="m-0"
                                          v-model="row[item.key]"
                                      ></el-checkbox>
                                    </el-tooltip>
                                    <el-tooltip
                                        :content="$t('system.change_default')"
                                        effect="dark"
                                        placement="bottom"
                                        v-else-if="item.key === 'is_default'"
                                    >
                                      <el-checkbox
                                          @click.native="changeDefault(row)"
                                          class="m-0"
                                          v-model="row[item.key]"
                                      ></el-checkbox>
                                    </el-tooltip>
                                    <el-tooltip
                                        :content="$t('system.change_issue_close')"
                                        effect="dark"
                                        placement="bottom"
                                        v-else-if="item.key === 'issue_close'"
                                    >
                                      <el-checkbox
                                          class="m-0"
                                          v-model="row[item.key]"
                                      ></el-checkbox>
                                    </el-tooltip>
                                  </span>
                  <span v-else>
                                    <!--boolean type for USER BEGIN-->
                                    <template v-if="item.key == 'admin' || item.key == 'client'">
                                      <span>{{ $t(`system.${row[item.key] ? 'yes' : 'no'}`) }}</span>
                                      <!--                                      <span v-if="row[item.key] && row.deleted_at == null" class="icon-check bg-green"><svg-icon icon-class="check"/></span>-->
                                      <!--                                      <span v-else class="icon-check"><svg-icon icon-class="no-check"/></span>-->
                                    </template>
                                    <template v-else-if="item.key == 'deleted_at'">
                                      <span>{{ $t(`system.${row.deleted_at ? 'no' : 'yes'}`) }}</span>
                                      <!--                                      <span v-if="row.deleted_at" class="icon-check bg-green"><svg-icon icon-class="check"/></span>-->
                                      <!--                                      <span v-else class="icon-check"><svg-icon icon-class="no-check"/></span>-->
                                    </template>
                                    <!--boolean type for USER END-->
                                    <template v-else>
                                      <span>{{ row[item.key] ? row[item.key] : $t('system.no_data') }}</span>
                                    </template>
                                  </span>
                </template>
                <!--boolean type END-->

                <!--action type BEGIN-->
                <template v-else-if="item.type === 'action'">
                                  <span v-if="row.edit">
                                    <el-button
                                        @click="confirmEdit(row)"
                                        size="mini"
                                        type="success"
                                    >
                                      {{ $t('system.save') }}
                                    </el-button>
                                    <el-button
                                        @click="cancelEdit(row)"
                                        class="cancel-btn"
                                        size="mini"
                                    >
                                      {{ $t('system.cancel') }}
                                    </el-button>
                                  </span>
                  <span v-else>
                                    <el-button
                                        @click="editElement(row)"
                                        name="edit"
                                        size="mini"
                                        type="info"
                                        v-if="!item.editDisable"
                                    >
                                      {{ $t('system.edit') }}
                                    </el-button>
                                    <el-button
                                        @click.stop="removeElement(row)"
                                        name="trash"
                                        size="mini"
                                        type="danger"
                                    >
                                      {{ $t('system.delete') }}
                                    </el-button>
                                        <el-button
                                            @click="translateByElement(row)"
                                            name="translate"
                                            size="mini"
                                            v-if="item.buttonTranslate"
                                        >
                                            {{ $t('system.translate') }}
                                        </el-button>
                                  </span>
                </template>
                <!--action type END-->
                <!--actionSave type BEGIN-->
                <template v-else-if="item.type === 'actionSave'">
                  <el-button
                      @click="saveRow(row)"
                      size="mini"
                      type="primary"
                  >
                    {{ $t('system.save') }}
                  </el-button>
                </template>
                <!--actionSave type END-->
                <!--select type BEGIN-->
                <template v-else-if="item.type === 'select' && !item.edit">
                  <ab-select
                      :item="item"
                      :options="selectOptions"
                      :scope="row"
                      :selected="selected"
                  ></ab-select>
                </template>
                <!--select type END-->
                <!--textarea type BEGIN-->
                <template v-else-if="item.type === 'textarea'">
                  <el-input
                      :autosize="{ minRows: item.autosize && item.autosize.minRows ? item.autosize.minRows : 1, maxRows: item.autosize && item.autosize.maxRows ? item.autosize.maxRows : 4}"
                      type="textarea"
                      v-model="row[item.key]"
                  ></el-input>
                </template>
                <!--textarea type END-->
                <template v-else>
                                  <span v-if="item.edit && row.edit">
                                    <span
                                        v-if="item.type === 'hours'"
                                        v-permission="item.permission ? item.permission : []"
                                    >
                                      <el-input
                                          :size="item.size ? item.size : 'small'"
                                          name="hours"
                                          type="text"
                                          v-mask="item.mask"
                                          v-model="row[item.key]"
                                      >
                                      </el-input>
                                    </span>
                                    <span
                                        v-else-if="item.type === 'date'"
                                        v-permission="item.permission ? item.permission : []"
                                    >
                                      <el-date-picker
                                          :pickerOptions="{ firstDayOfWeek: 1 }"
                                          format="yyyy-MM-dd"
                                          placeholder=""
                                          type="date"
                                          v-model="scope.row[item.key]"
                                      >
                                      </el-date-picker>
                                    </span>
                                    <span
                                        v-else-if="item.type === 'datetime'"
                                        v-permission="item.permission ? item.permission : []"
                                    >
                                      <el-date-picker
                                          :clearable="item.clearable"
                                          :pickerOptions="{ firstDayOfWeek: 1 }"
                                          placeholder=""
                                          type="datetime"
                                          v-model="row[item.key]"
                                      >
                                      </el-date-picker>
                                    </span>
                                    <span
                                        v-else-if="item.type === 'list' && item.key === 'activity_id'"
                                        v-permission="item.permission ? item.permission : []"
                                    >
                                      <el-select
                                          filterable
                                          v-model="row[item.key]"
                                      >
                                        <el-option
                                            :label="item.name"
                                            :value="item.id"
                                            v-bind:key="row[item.key]"
                                            v-for="item in time_entry_activity"
                                        >
                                        </el-option>
                                      </el-select>
                                    </span>
                                    <span
                                        v-else-if="item.type === 'list' && item.key === 'user_id'"
                                        v-permission="item.permission ? item.permission : []"
                                    >
                                      <el-select
                                          filterable
                                          v-model="row[item.key]"
                                      >
                                        <el-option
                                            :label="item.name"
                                            :value="item.id"
                                            v-bind:key="row[item.key]"
                                            v-for="item in users"
                                        >
                                        </el-option>
                                      </el-select>
                                    </span>
                                    <span
                                        v-else
                                        v-permission="item.permission ? item.permission : []"
                                    >
                                      <el-input
                                          :class="backend_errors && backend_errors[item.key] && backend_errors.id === row.id ? 'validate-error' : ''"
                                          :size="item.size ? item.size : 'small'"
                                          clearable
                                          v-model="row[item.key]"
                                      />
                                    </span>
                                  </span>
                  <span v-else>
                                    <router-link
                                        :class="item.align ? item.align : ''"
                                        :to="item.url + row.id"
                                        class="abTable--link"
                                        v-if="item.link && row.id"
                                    >
                                      {{ row[item.key] }}
                                    </router-link>
                                    <router-link
                                        :class="item.align ? item.align : ''"
                                        :to="item.url + row.clientId"
                                        class="abTable--link"
                                        v-else-if="item.link && row.clientId"
                                    >
                                      {{ row[item.key] }}
                                    </router-link>
                                    <router-link
                                        :class="item.align ? item.align : ''"
                                        :to="item.url +''+ row['issues.id']"
                                        @click.native="item.showModalTask ? showModalTask(row) : ''"
                                        class="abTable--link"
                                        event=""
                                        v-else-if="item.link && row['issues.id']"
                                    >
                                      {{ row[item.key] }}
                                    </router-link>
                                    <span v-else>
                                      <template v-if="row[item.key] && !isNaN(row[item.key]) && item.type !== 'number'">
                                        {{ row[item.key].toString().replace('.', ',') }}
                                      </template>
                                        <template v-else-if="item.type === 'number'">
                                        <el-input-number
                                            :min="1"
                                            :size="item.size ? item.size : 'small'"
                                            @change="numberChange(row)"
                                            controls-position="right"
                                            style="display: inline-table;"
                                            v-model="row[item.key]"
                                        ></el-input-number>
                                      </template>
                                        <template v-else-if="item.translate">
                                            {{
                                            row[item.key] ? $t(item.translateKey + '.' + row[item.key]) : $t('system.no_data')
                                            }}
                                        </template>
                                      <template v-else>
                                        {{ row[item.key] ? row[item.key] : $t('system.no_data') }}
                                      </template>
                                    </span>
                                  </span>
                </template>
              </template>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <div v-else>
      <span class="no-date-title m-3">{{ $t('system.no_selected_column') }}</span>
    </div>
    <div v-if="data && data.length === 0 && thead && thead.length > 0">
      <span class="no-date-title m-3">{{ $t('system.no_data') }}</span>
    </div>
  </div>
</template>
<script>
import ABselect from './select.vue';

export default {
  name: 'StaticTable',
  props: ['data', 'source', 'thead', 'params', 'options', 'customClass', 'project_id', 'backend_errors'],
  components: {
    'ab-select': ABselect,
  },
  data() {
    return {
      selected: [],
    };
  },
  beforeMount() {
    //
  },
  computed: {
    selectOptions: function () {
      return {'forms': this.$parent.forms};
    },
  },
  methods: {
    sortChange(item) {
      this.$emit('sortChange', item);
    },
    showModalTask(row) {
      this.$emit('showModalTask', row);
    },
    editElement(row) {
      this.$emit('editElement', row);
    },
    confirmEdit(row) {
      this.$emit('confirmEdit', row);
    },
    cancelEdit(row) {
      this.$emit('cancelEdit', row);
    },
    removeElement(row) {
      this.$emit('removeElement', row);
    },
    changeDefault(row) {
      this.$emit('changeDefault', row);
    },
    numberChange(row) {
      this.$emit('numberChange', row);
    },
    translateByElement(row) {
      this.$emit('translateByElement', row);
    },
    saveRow(row) {
      this.$emit('saveRow', row);
    },
  },
};
</script>
<style
    lang="scss"
    rel="stylesheet/scss"
    scoped
>
@import './../../static/css/table';
</style>
