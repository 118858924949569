import axios from 'axios';

const issue = {
  namespaced: true,
  state: {
    model: {},
    create_modal: false,
    update_modal: false,
    read_modal: false,
    thead: [],
    issueShouldRefresh: false,
    refreshListIssue: false,
    dbNameOld: [],
    currentOpenModalwithTracker: null,
  },
  getters: {
    issue(state) {
      return state.model;
    },
    create_modal(state) {
      return state.create_modal;
    },
    update_modal(state) {
      return state.update_modal;
    },
    read_modal(state) {
      return state.read_modal;
    },
    get_column_name(state) {
      return state.thead;
    },
    refreshListIssue(state) {
      return state.refreshListIssue;
    },
    dbNameOld(state) {
      return state.dbNameOld;
    },
  },
  mutations: {
    ADD_ISSUE: (state, issue) => {
      state.model = issue;
    },
    DEL_ISSUE: (state, issue) => {
      state.model = {};
    },
    OPEN_CREATE_MODAL: (state, status) => {
      state.create_modal = status;
    },
    OPEN_UPDATE_MODAL: (state, status) => {
      state.update_modal = status;
    },
    OPEN_READ_MODAL: (state, status) => {
      state.read_modal = status;
    },
    SAVE_COLUMN_NAME: (state, thead) => {
      state.thead = thead;
    },
    UPDATE_LIST_ISSUE: (state, value) => {
      state.refreshListIssue = value;
    },
  },
  actions: {
    addIssue: function ({dispatch, commit, getters}, issue) {
      return new Promise(resolve => {
        dispatch('mapIssue', issue);
        commit('ADD_ISSUE', issue);
        resolve();
      });
    },
    // mapIssue: function ({dispatch, commit, getters}, issue) {
    mapIssue: function ({state, rootState, commit, dispatch, getters, rootGetters}, issue) {
      return new Promise(resolve => {
        if (typeof issue.id === 'undefined') {
          if (typeof issue['issues.id'] !== 'undefined') {
            issue.id = issue['issues.id'];
          }
        }
        if (typeof issue.subject === 'undefined') {
          if (typeof issue['issues.subject'] !== 'undefined') {
            issue.subject = issue['issues.subject'];
          }
        }
        if (typeof issue.project_id === 'undefined') {
          if (typeof issue['projects.id'] !== 'undefined') {
            issue.project_id = issue['projects.id'];
            rootState.currentProjectId = issue.project_id;
          }
        }
        resolve(issue);
      });
    },
    searchIssues({state}, data) {
      return new Promise((resolve, reject) => {
        axios.post(data.url, data.data)
          .then(function (response) {
            resolve(response);
          }).catch(function (error) {
          reject(error);
        });
      });
    },
    getColumn({commit}) {
      return new Promise((resolve, reject) => {
        axios.get(`get_column/issues`)
          .then(function (response) {
            commit('SAVE_COLUMN_NAME', response.data.model);
            resolve(response);
          })
          .catch(function (e) {
            reject(e);
          });
      });
    },
  },
};

export default issue;
