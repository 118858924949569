<template>
  <div class="view-container">
    <el-form
        :model="form"
        class="el-table--customStyle"
    >
      <div class="view">
        <div
            :style="{'background-color': backgroundColor}"
            class="view-heading p-0"
        >
          <span
              class="disabled-nav"
              v-if="$route.meta.mode === 'edit'"
          >{{ $t('system.edit_role') }}</span>
          <span
              class="disabled-nav"
              v-else
          >{{ $t('system.create_role') }}</span>
        </div>
        <div class="view-body">
          <el-form-item required>
            <el-input
                :class="checkinputRequired('name') ? 'validate-error' : ''"
                :placeholder="$t('system.role_title')"
                @keyup.ctrl.enter.native="onSave"
                name="name"
                v-model="form.name"
            >
            </el-input>
            <small
                class="help-block"
                v-if="backend_errors.name && !checkinputRequired('name')"
            >
              <span v-html="validationTranslate(backend_errors.name[0])"></span>
            </small>
          </el-form-item>
          <permissions
              :checkedFields="permissions"
              :initData="allPermissions"
              editable="true"
              v-loading="loading.table"
              v-on:permissionsUpdated="updateCheckedPermissions"
          >
          </permissions>
        </div>
      </div>
      <div class="view d-flex justify-content-between">
        <div class="box-button">
          <el-button
              @click.prevent.stop="remove"
              type="danger"
              v-if="$route.meta.mode !== 'create'"
          >{{ $t('system.delete') }}
          </el-button>
        </div>
        <div class="box-button align-right">
          <el-button
              :loading="loading.save"
              @click.prevent.stop="onSave"
              type="primary"
          >{{ $t('system.save') }}
          </el-button>
          <el-button @click.prevent.stop="backTo">{{ $t('system.cancel') }}</el-button>
        </div>
      </div>
    </el-form>
  </div>
</template>

<script>
import Vue from 'vue';
import axios from 'axios';
import swal from 'sweetalert2';
import PermissionsPanel from './Permissions.vue';
import Global from '../../helpers/global';
import notification from '../../notification/notify';

export default {
  name: 'RolesForm',
  components: {
    permissions: PermissionsPanel,
  },
  data() {
    return {
      form: {},
      inputRequiredEmpty: [],
      backend_errors: {},
      option: {},
      title: 'Create',
      initialize: 'role/create',
      redirect: '/administration/role',
      store: 'role',
      method: 'post',
      checkedPermissions: {},
      permissions: [],
      allPermissions: [],
      loading: {
        save: false,
        table: false,
      },
    };
  },
  computed: {
    backgroundColor() {
      return this.$store.state.app.color;
    },
  },
  created() {
    let that = this;
    axios.get('role/create').then(function (response) {
      that.allPermissions = response.data.allPermissions;
    });
  },
  beforeMount() {
    if (this.$route.meta.mode === 'edit') {
      this.title = 'Edit';
      this.initialize = 'role/' + this.$route.params.id + '/edit';
      this.store = 'role/' + this.$route.params.id;
      this.method = 'put';
    }
    this.fetchData();
  },
  watch: {
    $route: 'fetchData',
  },
  methods: {
    backTo: function () {
      window.history.back();
    },
    updateCheckedPermissions(e) {
      Vue.set(this.checkedPermissions, e.name, e.check);
    },
    remove() {
      let vm = this;
      swal.fire({
        title: vm.$t('system.remove'),
        text: vm.$t('system.if_you_sure'),
        type: 'info',
        showCancelButton: true,
        focusCancel: true,
        confirmButtonText: vm.$t('system.yes'),
        cancelButtonText: vm.$t('system.no'),
      }).then((resp) => {
        if (resp.value) {
          axios.delete(`role/${vm.$route.params.id}`)
              .then((response) => {
                if (response.data.deleted) {
                  notification.notify(
                      this.$t('notify.success'),
                      this.$t('system.deleted'),
                      'success');
                  this.$router.push(this.redirect);
                } else {
                  notification.notify(
                      this.$t(`notify.${response.data.type}`),
                      this.$t(response.data.message),
                      response.data.type);
                }
              })
              .catch(function (error) {
                notification.notify(
                    vm.$t('notify.error'),
                    vm.$t(error.response.data.message),
                    'error');
              });
        }
      }, function (dismiss) {
      });
    },
    fetchData() {
      var vm = this;
      vm.loading.table = true;
      axios
          .get(this.initialize)
          .then(function (response) {
            vm.loading.table = false;
            Vue.set(vm.$data, 'form', response.data.form);
            Vue.set(vm.$data, 'option', response.data.option);
            Vue.set(vm.$data, 'permissions', response.data.permissions);
            for (var key in vm.permissions) {
              if (vm.permissions.hasOwnProperty(key)) {
                Vue.set(vm.checkedPermissions, key, true);
              }
            }
          })
          .catch(function (error) {
            vm.loading.table = false;
            notification.notify(
                vm.$t('notify.error'),
                vm.$t(error.message),
                'error');
          });
    },
    onSave() {
      var vm = this;
      vm.checkValidation().then(function () {
        vm.form.checkPermissions = [];
        for (var key in vm.checkedPermissions) {
          if (vm.checkedPermissions.hasOwnProperty(key)) {
            if (vm.checkedPermissions[key]) {
              vm.form.checkPermissions.push(key);
            }
          }
        }
        vm.loading.save = true;
        axios[vm.method](vm.store, vm.form)
            .then(function (response) {
              vm.loading.save = false;
              if (response.data.saved) {
                notification.notify(
                    vm.$t('notify.success'),
                    vm.$t('system.saved'),
                    'success');
                vm.$router.push(vm.redirect);
              } else {
                notification.notify(
                    vm.$t('notify.error'),
                    vm.$t(response.data.message),
                    'error');
              }
            })
            .catch(function (error) {
              vm.loading.save = false;
              let dataErrors = error.response.data.errors;
              let errors = Global.getErrors(vm, dataErrors, 1);

              vm.$store.commit('SET_STICKY_ARRAY', {
                array: errors,
              });
              vm.backend_errors = error.response.data.errors;
            });
      }).catch(function () {
        //
      });
    },
  },
};
</script>
<style
    lang="scss"
    rel="stylesheet/scss"
>
.disabled-nav {
  color: #fff;
  font-weight: 600;
  border-bottom: 2px solid #fff;
  line-height: 60px;
  padding: 0 20px;
  display: inline-block;
  font-size: 14px;
}
</style>
