import { render, staticRenderFns } from "./MyActivity.vue?vue&type=template&id=2f00f5da&scoped=true&xmlns%3Av-bind=http%3A%2F%2Fwww.w3.org%2F1999%2Fxhtml&"
import script from "./MyActivity.vue?vue&type=script&lang=js&"
export * from "./MyActivity.vue?vue&type=script&lang=js&"
import style0 from "./MyActivity.vue?vue&type=style&index=0&id=2f00f5da&lang=scss&rel=stylesheet%2Fscss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2f00f5da",
  null
  
)

export default component.exports