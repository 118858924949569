<template>
  <div>
    <div
        :key="filter.id"
        class="row filterItem"
        v-for="filter in filters"
    >
      <div class="col-3 d-flex align-items-center">
        {{ $t(filter.label) }}
      </div>
      <div class="col-9 d-flex align-items-center">
        <component
            :filter="filter"
            :is="fields[filter.name]"
            :size="size"
        />
      </div>
    </div>
  </div>
</template>

<script>
// revert commented lines if will not work
export default {
  name: 'FiltersGenerator',
  props: {
    filters: {
      type: Array,
    },
    size: {
      type: String,
      default: 'mini',
    },
  },
  // data() {
  //   return {
  //     fields: {}
  //   }
  // },
  // watch: {
  //   filters() {
  //     this.importFields()
  //   }
  // },
  computed: {
    fields() {
      const imports = {};
      this.filters.forEach(filter => {
        imports[filter.name] = () => import(`./src/filtersFields/field${this.firstCharToUpperCase(filter.type)}.vue`);
      });
      return imports;
    },
  },
  methods: {
    firstCharToUpperCase(string) {
      return string.charAt(0).toUpperCase() +
          string.slice(1);
    },
    // importFields() {
    //   console.log('importFields')
    //   this.filters.forEach(filter => {
    //     this.fields[filter.name] = () => import(`./src/filtersFields/field${this.firstCharToUpperCase(filter.type)}.vue`)
    //   })
    // }
  },
};
</script>

<style
    lang="scss"
    rel="stylesheet/scss"
    scoped
>
.filterItem {
  padding: 10px 0 0 0;

  &:first-child {
    padding: 0
  }
}
</style>
