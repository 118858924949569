import VueI18n from '../config/i18n';
import {andGuard} from '../guards/guards';
// import GlobalSearch from '../views/GlobalSearch/Index.vue'

export default [
  {
    path: '/calendar',
    component: () => import('../views/Calendar/Index.vue'),
    name: 'Calendar',
    beforeEnter: andGuard,
    meta: {
      permissions: ['crm-calendar'],
      description: VueI18n.t('system.calendar'),
      breadcrumb: [{name: VueI18n.t('system.calendar'), title: 'calendar'}],
    },
  },
];
