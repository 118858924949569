const modal = {
  namespaced: true,
  state: {
    idTracker: '', // id - issue
    typeTracker: '', // id - trackera dla issue
    apiName: '', // read, create, edit
    parentId: '', // parent issue id
    projectId: '',
    trackerName: '',
    issuesIds: [],
  },
  getters: {
    getIdTracker: state => state.idTracker,
    getTypeTracker: state => state.typeTracker,
    getApiName: state => state.apiName,
    getParentId: state => state.parentId,
    getProjectId: state => state.projectId,
    trackerName: state => state.trackerName,
    getIssuesIds: state => state.issuesIds,
  },
  mutations: {
    SET_ISSUES_IDS: (state, issuesIds) => {
      state.issuesIds = issuesIds;
    },
    OPEN_CREATE_MODAL: (state, data) => {
      state.idTracker = '';
      state.typeTracker = data.id;
      state.apiName = 'create';
      state.parentId = '';
      state.projectId = '';
      state.trackerName = data.translation;
    },
    OPEN_CREATE_SUB_MODAL: (state, data) => {
      state.projectId = data.project_id;
      state.idTracker = ''; //issue id
      state.typeTracker = data.tracker_id;
      state.apiName = 'create';
      state.parentId = data.id;
    },
    OPEN_UPDATE_MODAL: (state, id) => {
      state.apiName = 'edit';
      state.idTracker = id;
      state.typeTracker = '';
      state.projectId = '';
    },
    OPEN_READ_MODAL: (state, id) => {
      state.idTracker = id;
      state.typeTracker = '';
      state.apiName = 'read';
      state.parentId = '';
      state.projectId = '';
    },
    CLOSE_MODAL: (state) => {
      state.idTracker = '';
      state.typeTracker = '';
      state.apiName = '';
      state.parentId = '';
      state.projectId = '';
    },
  },
  actions: {
    addIssuesIds({commit}, ids) {
      commit('SET_ISSUES_IDS', ids);
    },
    closeModal({commit}) {
      return new Promise((resolve) => {
        commit('SET_ISSUES_IDS', []);
        commit('CLOSE_MODAL');
        resolve();
      });
    },

  },
};

export default modal;
